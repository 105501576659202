import { UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import React from "react";
import jwt_decode from "jwt-decode";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

/**
 * Customized Menu icon.
 */
const MyCustomIcon = () => {
    return (
        <React.Fragment>
            <AccountCircleIcon />
            <span style={{ fontSize: '18px', marginLeft: '10px' }}>{localStorage.getItem('token') ? jwt_decode(localStorage.getItem('token')).username : null}</span>
        </React.Fragment>
    )
};

/**
 * CustomUserMenu component.
 * @param {*} props 
 */
const CustomUserMenu = props => {
    return (
        <UserMenu {...props} icon={<MyCustomIcon />} >
            <MenuItemLink
                to="/profile"
                primaryText="Profile"
                leftIcon={<SettingsIcon />}
            />
        </UserMenu>
    );
}

export default CustomUserMenu;