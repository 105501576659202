import * as React from "react";
import ActionsComponent from "./../components/ActionsComponent"
import { makeStyles } from '@material-ui/core/styles';

import {useRefresh, List, ImageField, Datagrid, TextField, EditButton } from 'react-admin';
import './../App.css';
import AssignmentIcon from '@material-ui/icons/Assignment';

/**
 * Style for edit button
 */
const useStyles = makeStyles({
    button: {
        fontSize: '0',
    },
});

/**
* Edit button custom class
* @param {*} props Props that contain attributes related to addons
*/
const MyAboutEditButton = props => {
    const classes = useStyles();
    return <EditButton className={classes.button} {...props} />;
};

/**
 * Custom field for display restaurant status.
 * @param {*} record data retrieved from api that contains restaurant information.
 */
const StatusField = ({ record = {} }) => <span style={{ whiteSpace: 'nowrap' }}>{record.status === true ? 'Active' : 'Inactive'}</span>;

/**
 * Restaurant list class
 * @param {*} props Props that contain attributes related to restaurants
 */
export const MyRestaurant = (props) => {
    const refresh = useRefresh();
    return(
        <span>
            <List {...props} bulkActionButtons={false} exporter={false} title="My restaurant">
                <Datagrid>
                    <ImageField source="imageUrl" label="Restaurant" className="thumbNailView" sortable={false}/>
                    <TextField source="name" sortable={false}/>
                    <TextField source="address" sortable={false}/>
                    <TextField source="phone" sortable={false}/>
                    <StatusField source="status" sortable={false}/>
                    <ActionsComponent refresh={refresh} myPage={true} {...props} sortable={false}/>
                    <MyAboutEditButton icon={<AssignmentIcon/>}/>
                </Datagrid>
            </List>
        </span>
    )
};
