import { Edit, TabbedForm, FormTab, TextInput, NumberInput, SelectArrayInput, SaveButton, Toolbar, SelectInput, required, number, minValue, FormDataConsumer } from 'react-admin'
import React, { Component } from 'react';
import { Fab, IconButton, Dialog, DialogContent, Button, Typography, Box, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import Image from './../components/Image';
import FileManager from '../util/FileManager';
import InputAdornment from '@material-ui/core/InputAdornment';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import axios from 'axios';
import { Fragment } from 'react';
import { useForm } from 'react-final-form';
import {useNotify} from 'react-admin';
import jwt_decode from "jwt-decode";
import {CloseConfirmationButton} from "../components/CloseConfirmationButton";
import Config from "../util/Config";

const MAX_TAGS = 4

/**
 * Dish edit form toolbar component
 * @param {*} props Props for toolbar Save Button
 */
const DishEditToolbar = props => (
  <Toolbar {...props}  >
    <div style={{flex: '0 0 auto', display: 'flex', padding: '8px', width: '100%', justifyContent: 'flex-end'}}
         {...props}>
      <SaveButton
        {...props}
        label="SAVE"
        submitOnEnter={false}
        redirect={'/dish'}
      />
    </div>
  </Toolbar>
);

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/**
* Custom Dialog Title Component
*/
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textAlign: 'left', fontWeight: "fontWeightBold" }}>{children}</Typography>
      {onClose ? (
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/dish' withIcon={true} classes={classes}/>
      ) : null}
    </MuiDialogTitle>
  );
});

/**
 * "browse" field used by the button for the loading of images
 * @param {*} props Props that contain id of dish
 */
const UploadButton = withStyles(styles)((props) =>{
    return(
        <label htmlFor={props.id}>
            <input style={{ display: 'none' }} id={props.id} name={props.id} type="file" accept="image/*" onChange={(e) => props.onChange(e)}/>
            <Fab color="secondary" size="small" component="span" aria-label="add" variant="extended" style={{maxWidth: '100px', maxHeight: '25px', minWidth: '100px', minHeight: '25px'}}>
                <h5>Browse</h5>     <FolderOpenIcon/>
            </Fab>
        </label>
    )
});

/**
 * Adapting upload image to react-admin
 * @param {*} props
 */
const ImgUploader = (props) => {
    const form = useForm();
    const [DishPicture, setDishPicture] = React.useState("")
    const [firstTime, setFirstTime] = React.useState(true)
    const setPicture = (val) => {
        setDishPicture(val);
        if (!firstTime) {
          form.change("dishPicture", val);
        }
    }
    React.useEffect(() => {
      if (firstTime) {
        setPicture(props.formData.dishPicture);
        setFirstTime(false)
      }
    }, [props.dishPicture, setPicture]);

    const notify = useNotify()

    // Button that upload images
    const loadDishPicture = (e) => {
      if (e.target.files[0]) {
        if (!e.target.files[0].type.includes("image")) {
          notify('You can load only images', 'error');
          return;
        }
        if (e.target.files[0].size > 1000000) {
            notify('The picture may not be greater than 1000 kilobytes','error');
            return;
        }
          FileManager.UploadImage("restaurants/image", e.target.files[0], setPicture);
      }
    }

    return (
        <Fragment>
          <div>
              <Box flex={1} mr="1em">
                  <div>
                      <div>
                          <h5>Picture</h5>
                          <UploadButton id="dishImage" onChange={loadDishPicture}/>
                      </div>
                  </div>
              </Box>
              <Box flex={1} mr="1em">
                  {DishPicture !== "" ? <Image src={DishPicture} width={200} height={200} mode='fit' /> : null}
              </Box>
          </div>
          <div style={{ display: 'none'}}>
            <TextInput source="dishPicture" style={{ display: 'none' }} />
          </div>
        </Fragment>
    );
};

/**
 * Custom multiple buttons secection for react-admin
 * @param {*} props
 */
function AddonsButtons (props) {
  const form = useForm();
  const [selectedAddons, setSelectedAddons] = React.useState(props.selectedAddons);
  React.useEffect(() => {
      setSelectedAddons(props.selectedAddons);
  }, [props.selectedAddons]);

  return (
      <Fragment>
        <div style={{ display: 'flex', flexDirection: 'column', width: '45%', paddingLeft: '10%' }}>
            <Typography variant="h7" style={{ margin: 8 }}>
                <b>Add-ons</b>
            </Typography>
            <Grid container item md={12} style={{ marginTop: 12 }}>
                {
                    props.addons.map((addon, i) => {
                        return (
                            selectedAddons.get(addon.id) ?
                                <Grid item md={6}>
                                    <Button style={{backgroundColor: '#5BB85D', borderRadius: 5, color: 'white', '&:hover': {backgroundColor: "#4f9950"}, border: '1px solid #4FAE50'}} size="medium" fullWidth={true}
                                        onClick={
                                            () => {
                                                setSelectedAddons(
                                                    selectedAddons.set(addon.id, false)
                                                );
                                                let selectedAddonsArray = [];
                                                selectedAddons.forEach((v, k)=>{
                                                    if (v)
                                                        selectedAddonsArray.push(k);
                                                });
                                                form.change("dishAddons", selectedAddonsArray);
                                            }
                                        }
                                    >
                                        {addon.name}
                                    </Button>
                                </Grid>
                                :
                                <Grid item md={6}>
                                    <Button style={{backgroundColor: 'white', borderRadius: 5, color: 'black', '&:hover': {backgroundColor: "#c6c6c6"}, border: '1px solid #D9D9D9'}} size="medium" fullWidth={true}
                                        onClick={
                                            () => {
                                                setSelectedAddons(
                                                  selectedAddons.set(addon.id, true)
                                                );
                                                let selectedAddonsArray = [];
                                                selectedAddons.forEach((v, k)=>{
                                                    if (v)
                                                        selectedAddonsArray.push(k);
                                                });
                                                form.change("dishAddons", selectedAddonsArray);
                                            }
                                        }
                                    >
                                        {addon.name}
                                    </Button>
                                </Grid>
                        )
                    })
                }
            </Grid>
        </div>
        <div style={{ display: 'none'}}>
          <SelectArrayInput source="dishAddons" style={{ display: 'none' }} />
        </div>
      </Fragment>
  );
};

/**
 * Custom multiple buttons secection for react-admin
 * @param {*} props
 */
function TagsButtons (props) {
  const form = useForm();
  const [selectedTags, setSelectedTags] = React.useState(props.selectedTags);
  const [showError, setShowError] = React.useState(false);

  const displayError = () => {
    setShowError(true);
    setTimeout(() => setShowError(false), 3000)
  }


  React.useEffect(() => {
      setSelectedTags(props.selectedTags);
  }, [props.selectedTags]);

  return (
      <Fragment>
        <div style={{ display: 'flex', flexDirection: 'column', width: '45%', paddingLeft: '10%' }}>
            <Typography variant="h7" style={{ margin: 8 }}>
                <b>Tags</b>
            </Typography>
            <Grid container item md={12} style={{ marginTop: 12 }}>
                {
                    props.tags.map((tag, i) => {
                        return (
                            tag.id !== Config.ALL_TAGS_ID ?
                            selectedTags.get(tag.id) ?
                                <Grid item md={6}>
                                    <Button style={{backgroundColor: '#5BB85D', borderRadius: 5, color: 'white', '&:hover': {backgroundColor: "#4f9950"}, border: '1px solid #4FAE50'}} size="medium" fullWidth={true}
                                        onClick={
                                            () => {
                                                setSelectedTags(
                                                    selectedTags.set(tag.id, false)
                                                );
                                                let selectedTagsArray = [];
                                                selectedTags.forEach((v, k)=>{
                                                    if (v)
                                                        selectedTagsArray.push(k);
                                                });
                                                form.change("dishTags", selectedTagsArray);
                                            }
                                        }
                                    >
                                        {tag.name}
                                    </Button>
                                </Grid>
                                :
                                <Grid item md={6}>
                                    <Button style={{backgroundColor: 'white', borderRadius: 5, color: 'black', '&:hover': {backgroundColor: "#c6c6c6"}, border: '1px solid #D9D9D9'}} size="medium" fullWidth={true}
                                        onClick={
                                            () => {
                                              var count = 0
                                              selectedTags.forEach((v, k)=>{
                                                if (v)
                                                    count++;
                                              });
                                              if (count >= MAX_TAGS) {
                                                displayError()
                                                return;
                                              }
                                              setSelectedTags(
                                                    selectedTags.set(tag.id, true)
                                                );
                                                let selectedTagsArray = [];
                                                selectedTags.forEach((v, k)=>{
                                                    if (v)
                                                        selectedTagsArray.push(k);
                                                });
                                                form.change("dishTags", selectedTagsArray);
                                            }
                                        }
                                    >
                                        {tag.name}
                                    </Button>
                                </Grid> : null
                        )
                    })
                }
            </Grid>
            <div style={{ borderRadius: 5, marginTop: 10, height: '50%', whiteSpace: 'pre-wrap', backgroundColor: props.errorColor, display: props.display }}>
              <div style={{paddingLeft: 10, marginTop: 5, marginBottom: 5}}>{props.errorMessage}</div>
            </div>
            { showError ?
              <div style={{ borderRadius: 5, marginTop: 10, height: '50%', whiteSpace: 'pre-wrap', backgroundColor: '#F2DEDF' }}>
                <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 5, marginBottom: 5}}>Maximum {MAX_TAGS} tags are allowed to select </div>
              </div>
              : null}

        </div>
        <div style={{ display: 'none'}}>
          <SelectArrayInput source="dishTags" style={{ display: 'none' }} />
        </div>
      </Fragment>
  );
};

  /**
   * Validate max value function
   * @param {*} value
   * @param {*} allValues
   */
  const tagsValidation = (value, allValues) => {
    if (Array.isArray(allValues.dishTags)) {
      if (allValues.dishTags.length === 0) {
        return 'error';
      }
    } else {
      return 'error'
    }

  return [];
};

const noLessThanZero = [required(), number(), minValue(0,"negative values are not allowed")];

const validateTags = [tagsValidation]

const DishEditLocalizedForm = props => (
    <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '45%' }}>
                <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                    <Box fontWeight="fontWeightBold" m={1}>
                        Name
                    </Box>
                </Typography>
                <TextInput source={props.dishNameSource ? props.dishNameSource : ''} label="Name" style={{ width: '100%' }} validate={props.required ? required() : []}/>
            </div>
            <FormDataConsumer>
                {formDataProps => (<AddonsButtons addons={props.data.addons} selectedAddons={props.data.selectedAddons} {...formDataProps}/>)}
            </FormDataConsumer>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '45%' }}>
                <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                    <Box fontWeight="fontWeightBold" m={1}>
                      Ingredients
                    </Box>
                </Typography>
                <TextInput multiline source={props.dishDescriptionSource ? props.dishDescriptionSource : ''} label="Ingredients" style={{ width: '100%' }}
                           validate={props.required ? required() : []}/>
            </div>
            <FormDataConsumer>
                {formDataProps => (<TagsButtons tags={props.data.tags} selectedTags={props.data.selectedTags}
                                                errorColor={props.data.errorColor} errorMessage={props.data.errorMessage}
                                                display={props.data.display} {...formDataProps}/>)}
            </FormDataConsumer>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '45%' }}>
                <Typography style={{ textAlign: 'left' }}>
                    <Box fontWeight="fontWeightBold" m={1}>
                        Dish price (€)
                    </Box>
                </Typography>
                <NumberInput source="dishPrice" inputProps={{ min: 0 }} style={{ width: '100%' }} validate={noLessThanZero} step={0.5}
                             InputProps={{ endAdornment: <InputAdornment position="end"> € </InputAdornment> }}/>
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '45%' }}>
                <Typography style={{ textAlign: 'left' }}>
                    <Box fontWeight="fontWeightBold" m={1}>
                        Category
                    </Box>
                </Typography>
                <SelectInput source="dishCategory" style={{ width: '100%' }} validate={required()} choices={props.data.categories} />
            </div>
        </div>
        <FormDataConsumer>
            {formDataProps => (<ImgUploader firstTime={props.data.firstTime} dishPicture={props.data.DishPicture} {...formDataProps} />)}
        </FormDataConsumer>
        <TextInput source="dishPicture" value={props.data.DishPicture} style={{ display: 'none' }} />
        <div style={{ display: 'none'}}>
            <NumberInput source='tagsValidation' validate={validateTags} style={{ display: 'none' }} />
        </div>
    </div>
)
/**
 * Dish edit class
 */
class DishEdit extends Component {

    constructor(props) {
      super(props);
      this.state = {categories: [], DishPicture: '', firstTime: true, addons: [], selectedAddons: new Map(),
          selectedTags: new Map(), tags: [], selAddons: [], selTags: [], errorMessage: '', errorColor: '', display: 'inline'};
    }

    /**
     * function to set firstTime value
     * @param {*} val
     */
    setFirstTime (val) {
      this.setState({firstTime: val});
    }

    /**
     * function to set disable value
     * @param {*} val
     */
    setDisable (val) {
      this.setState({disable: val});
    }

    /**
     * function to validate fields to enable save changes button
     * @param {*} values
     */
    validateFields = (values) => {
        if (typeof values.dishTags === 'undefined' || values.dishTags === null) {
          this.setState({display: 'inline', errorColor: 'rgba(217,237,246, 1)', errorMessage: 'Minimum 1 tag to select. Maximum ' + MAX_TAGS + ' tags to select.'})
        } else {
          if (values.dishTags.length === 0) {
            this.setState({display: 'inline', errorColor: 'rgba(217,237,246, 1)', errorMessage: 'Minimum 1 tag to select. Maximum ' + MAX_TAGS + ' tags to select.'})
          } else {
            this.setState({display: 'none'})
          }
        }
        if (this.state.firstTime) {
          this.setDishPicture(values.dishPicture);
          this.setState({selectedAddons: this.setSelectedTagsOrAddonsMap(values.dishAddons)});
          this.setState({selectedTags: this.setSelectedTagsOrAddonsMap(values.dishTags)});
          this.setFirstTime(false);
        }
    };

    /**
     * Function to put convert selected tags or addons from array to map.
     * @param {*} array - the array with ids of selected tags/addons.
     */
    setSelectedTagsOrAddonsMap(array) {
        let selectedValuesMap = new Map();
        array.forEach(i=>{
            selectedValuesMap.set(i, true);
        });
        return selectedValuesMap;
    }

    /**
     * function that triggers when the component is mounted
     */
    async componentDidMount () {
      const responseCategories = await axios.get(process.env.REACT_APP_API_URL + '/dish-category/restaurant/' + JSON.stringify(jwt_decode(localStorage.getItem('token')).restaurantId),{
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      });
      var categories = [];
      responseCategories.data.data.forEach(obj => {
        categories.push({id: obj.id, name: obj.dishCategoryName});
      });
      this.setState({categories: categories});

      const responseAddons = await axios.get(process.env.REACT_APP_API_URL + '/addon/' + JSON.stringify(jwt_decode(localStorage.getItem('token')).restaurantId),{
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      });
      var addons = [];
      responseAddons.data.data.forEach(obj => {
        addons.push({id: obj.id, name: obj.addonName});
      });
      this.setState({addons: addons});

      const responseTags = await axios.get(process.env.REACT_APP_API_URL + '/dish/tags',{
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      });
      var tags = [];
      responseTags.data.forEach(obj => {
        tags.push({id: obj.id, name: obj.name})
      });
      this.setState({tags: tags})

    }

    /**
     * function to set dish picture value
     * @param {*} val
     */
    setDishPicture = (val) => {
        this.setState({DishPicture: val});
    }

    // Button that upload images
    loadDishPicture = (e) => {
        FileManager.UploadImage("restaurants/image", e.target.files[0], this.setDishPicture);
    }

    render() {

    return (
      <div>
        <Dialog
          open={true}
          fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={() => this.props.history.push("/dish")}>
            Edit dish
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ width: '100%' }}>
              <Edit undoable={false} {...this.props}>
                <TabbedForm toolbar={<DishEditToolbar history={this.props.history} disabled={this.state.disable} />} validate={this.validateFields} {...this.props}>
                    <FormTab label="English">
                        <DishEditLocalizedForm data={this.state} dishNameSource="localizations.en.name" dishDescriptionSource="localizations.en.description" required/>
                    </FormTab>
                    <FormTab label="Italian">
                        <DishEditLocalizedForm data={this.state} dishNameSource="localizations.it.name" dishDescriptionSource="localizations.it.description"/>
                    </FormTab>
                </TabbedForm>
              </Edit>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

export default DishEdit;
