import { Edit, SimpleForm, TextInput, SaveButton, Toolbar, SelectInput, NumberInput, required, BooleanInput, FormDataConsumer, DateInput, number, minValue, maxValue } from 'react-admin'
import React, { Component, useCallback } from 'react'
import { IconButton, Dialog, DialogContent, Button, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import {useForm} from 'react-final-form';
import {generateCode} from '../util/Utility';
import {CloseConfirmationButton} from "../components/CloseConfirmationButton"

/**
 * Discount edit form toolbar component
 * @param {*} props Props for toolbar Save Button
 */
const DiscountToolBar = props => (
  <Toolbar {...props}  >
    <div style={{flex: '0 0 auto', display: 'flex', padding: '8px', width: '100%', justifyContent: 'flex-end'}}
         {...props}>
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/discounts' />
        <SaveButton
          {...props}
            label="SAVE"
            submitOnEnter={false}
            style={{marginLeft: '8px', width: '20%'}}
        />
    </div>
  </Toolbar>
);

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/**
* Custom Dialog Title Component
*/
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textAlign: 'left', fontWeight: "fontWeightBold" }}>{children}</Typography>
      {onClose ? (
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/discounts' withIcon={true} classes={classes}/>
      ) : null}
    </MuiDialogTitle>
  );
});

/**
 * Generate button component for random discount code generation.
 * @param variant the variant.
 * @param props the props.
 * @returns {*} Button component.
 * @constructor
 */
const GenerateCodeButton = ({ variant, ...props}) => {
    const form = useForm();
    const handleClick = useCallback(() => {
        var newCode = generateCode();
        form.change('code', newCode);
    }, [form]);

    return (
        <Button onClick={handleClick} {...props} />
    );
};

const noLessThanZero = [required(), number(), minValue(0,"negative values are not allowed")];

const noMoreThanHoundred = [required(), number(), minValue(0,"negative values are not allowed"), maxValue(100,"value can not exeed 100")];

/**
 * Function for validate form fields.
 * @param {*} values form values.
 */
const validateDiscountDate = (values) => {
    const errors = {};
    if(values.validEnd && values.validTo !== undefined) {
        if(values.validTo < values.validFrom) {
            errors.validTo = ["end date can't be entered as lower value than start date"]
        }
    }
    return errors;
}


/**
 * discount edit class
 */
class DiscountEdit extends Component {

    render() {
        return (
            <Dialog open={true} fullWidth={true}>
                <DialogTitle id="customized-dialog-title" onClose={() => window.history.back() }>
                    Edit Discount
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ width: '100%' }}>
                        <Edit undoable={false} {...this.props}>
                            <SimpleForm toolbar={<DiscountToolBar />} validate={validateDiscountDate}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '70%' }}>
                                            <TextInput label="Discount code" placeholder="e.g SUMMERSALE" source="code" style={{ width: '100%'}} validate={required()}/>
                                        </div>
                                        <div style={{ width: '30%', marginTop: 7 }}>
                                            <GenerateCodeButton variant="contained" style={{ fontSize: 12, height: 49, backgroundColor: '#3f51b5', color: 'white', width: '100%'}}>
                                                Generate code
                                            </GenerateCodeButton>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '100%' }}>
                                            <SelectInput source="type" style={{ width: '49%' }} validate={required()} choices={[
                                                { id: 'PERCENTAGE', name: 'Percentage' },
                                                { id: 'FIXED_AMOUNT', name: 'Fixed Amount' },
                                            ]}/>
                                            <NumberInput label="Discount value" step={0.01} source="value" style={{ width: '49%', float: 'right'}} validate={noMoreThanHoundred}/>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '100%'}}>
                                            <BooleanInput source="minPurchase" style={{ width: '49%', height: 50, marginTop: 6, display: 'inline-block' }} label="This discount requires a minimum purchase" />
                                            <FormDataConsumer>
                                                {({ formData, ...props }) => formData.minPurchase &&
                                                    <NumberInput source="purchase" step={0.01} style={{ width: '49%', float: 'right'}} validate={noLessThanZero} {...props}/>
                                                }
                                             </FormDataConsumer>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '100%'}}>
                                            <BooleanInput source="limitUsage" style={{ width: '49%', height: 50, marginTop: 6, display: 'inline-block' }} label="Limit number of times this discount can be used in total" />
                                            <FormDataConsumer>
                                                {({ formData, ...props }) => formData.limitUsage &&
                                                    <NumberInput source="usage" style={{ width: '49%', float: 'right'}} validate={noLessThanZero} {...props}/>
                                                }
                                            </FormDataConsumer>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '100%' }}>
                                            <BooleanInput source="validEnd" style={{ width: '100%', height: 50, marginTop: 6, display: 'inline-block' }} label="Set end date(active to)" />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '100%' }}>
                                            <DateInput label="Set start date(active from)" style={{ width: '49%' }} source="validFrom" validate={required()}/>
                                            <FormDataConsumer>
                                                {({ formData, ...props }) => formData.validEnd &&
                                                    <DateInput label="Set end date(active to)" source="validTo" style={{ width: '49%', float: 'right' }} validate={required()} {...props}/>                                                }
                                            </FormDataConsumer>
                                        </div>
                                    </div>
                                </div>
                            </SimpleForm>
                        </Edit>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}

export default DiscountEdit;


