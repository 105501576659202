import * as React from "react";
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/HighlightOff';
import { Fab, TextField, IconButton, Dialog, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Image from '../components/Image';
import FileManager from '../util/FileManager'
import DataManager from '../util/DataManager'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useDataProvider } from 'react-admin';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { useNotify } from 'react-admin';
import { showNotification as showNotificationAction } from 'react-admin';
import { connect } from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useConfirm } from 'material-ui-confirm';
import SaveIcon from '@material-ui/icons/Save';

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sameLine: {
        padding: '10px'
    },
    button: {
        marginBottom: '5px',
        marginTop: '1px'
    },
    container: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: theme.spacing(15),
        marginRight: theme.spacing(15),
        borderRadius: '15px 50px',
        border: '1px solid #1E90FF'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
});

const styleMargin = {
    marginTop10: {
        marginTop: '10px'
    },
    marginTop15: {
        marginTop: '15px'
    },
    marginBottom5: {
        marginBottom: '5px'
    },
    marginTop50: {
        marginTop: '50px'
    },
    marginTopBottom: {
        marginBottom: '5px',
        marginTop: '50px'
    },
    marginTopBottomDeliveryHoursEdit: {
        marginBottom: '20px',
        marginTop: '50px'
    },
    marginBottom40: {
        marginBottom: '40px'
    },
    widthMax: {
        width: '100%'
    },
    marginTopBottomDeliveryHoursCreate: {
        marginBottom: '5px',
        marginTop: '50px'
    },
    widtMaxTop10: {
        marginTop: '10px',
        width: '100%'
    }
}

/**
 * Custom letter spacing classes
 */
const letterSpacing = {
    letterSpacingSmall: {
        letterSpacing: '-0.3px'
    }
}

/**
 * "browse" field usedy by 3 buttons for the loading of images
 * @param {*} props Props that contain id of restaurant
 */
const UploadButton = withStyles(styles)((props) => {
    return (
        <label htmlFor={props.id}>
            <input style={{ display: 'none' }} id={props.id} name={props.id} type="file" accept="image/*" onChange={props.onChange} />
            <Fab color="secondary" size="small" component="span" aria-label="add" variant="extended" style={{ maxWidth: '100px', maxHeight: '25px', minWidth: '100px', minHeight: '25px' }}>
                <h5>Browse</h5>     <FolderOpenIcon />
            </Fab>
        </label>
    )
});

/**
 * The 3 buttons that allows the loading of images
 * @param {*} props Props that contains picture urls
 */
const UploadArea = withStyles(styles)((props) => {
    const { classes } = props;

    return (
        <div>
            <div>
                <Box flex={1} mr="1em">
                    <div className={classes.button}>
                        <div className={classes.sameLine}>
                            <h5 className={classes.button}>Restaurant picture</h5>
                            <UploadButton id="restaurantImage" onChange={props.onRestaurantPictureChange} />
                            <p style={{ color: 'red' }}>{props.errorRestaurantPicture ? 'Please select a file.' : null}</p>
                        </div>
                    </div>
                </Box>
                <Box flex={1} mr="1em">
                    {props.RestaurantPicture !== "" ? <Image src={props.RestaurantPicture} width={200} height={200} mode='fit' /> : null}
                </Box>
            </div>
            <div>
                <Box flex={1} mr="1em">
                    <div className={classes.button}>
                        <div className={classes.sameLine}>
                            <h5 className={classes.button}>Restaurant menu picture</h5>
                            <UploadButton id="restaurantMenuImage" onChange={props.onRestaurantMenuPictureChange} />
                            <p style={{ color: 'red' }}>{props.errorRestaurantMenuPicture ? 'Please select a file.' : null}</p>
                        </div>
                    </div>
                </Box>
                <Box flex={1} mr="1em">
                    {props.RestaurantMenuPicture !== "" ? <Image src={props.RestaurantMenuPicture} width={200} height={200} mode='fit' /> : null}
                </Box>
            </div>
            <div>
                <Box flex={1} mr="1em">
                    <div className={classes.button}>
                        <div className={classes.sameLine}>
                            <h5 className={classes.button}>Restaurant logo</h5>
                            <UploadButton id="restaurantLogo" onChange={props.onRestaurantLogoChange} />
                            <p style={{ color: 'red' }}>{props.errorRestaurantLogo ? 'Please select a file.' : null}</p>
                        </div>
                    </div>
                </Box>
                <Box flex={1} mr="1em">
                    {props.RestaurantLogo !== "" ? <Image src={props.RestaurantLogo} width={200} height={200} mode='fit' /> : null}
                </Box>
            </div>
        </div>
    )
});

/**
 * Custom Dialog Title Component
 * @param {*} props Props that contains styles and state variables
 */
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

/**
 * Create/edit button and confirmation dialog
 * @param {*} props Props that contain id of restaurant to delete
 */
const CreateEditButton = (props) => {

    // Variables declaration
    const [CreateEditDialogOpen, setCreateEditDialogOpen] = React.useState(props.edit ? false : true);
    const [checkedWorkingHoursSaturday, setCheckedWorkingHoursSaturday] = React.useState(false);
    const [checkedDeliveryHoursSaturday, setCheckedDeliveryHoursSaturday] = React.useState(false);
    const [checkedWorkingHoursSunday, setCheckedWorkingHoursSunday] = React.useState(false);
    const [checkedDeliveryHoursSunday, setCheckedDeliveryHoursSunday] = React.useState(false);
    const [RestaurantPicture, setRestaurantPicture] = React.useState("");
    const [RestaurantMenuPicture, setRestaurantMenuPicture] = React.useState("");
    const [RestaurantLogo, setRestaurantLogo] = React.useState("");
    const [RestaurantData, setRestaurantData] = React.useState({
        aboutContent: null,
        aboutHeading: null,
        address: "",
        deliveryCost: "",
        deliveryHoursStart: "",
        deliveryHoursEnd: "",
        deliveryHoursSaturdayStart: "",
        deliveryHoursSaturdayEnd: "",
        deliveryHoursSundayStart: "",
        deliveryHoursSundayEnd: "",
        deliveryTime: "",
        description: "",
        descriptionIT: "",
        headingImageUrl: "",
        id: 65,
        imageUrl: "",
        logoUrl: "",
        minDeliveryOrder: "",
        name: "",
        nameIT: "",
        phone: "",
        workingHoursStart: "",
        workingHoursEnd: "",
        workingHoursSaturdayStart: "",
        workingHoursSaturdayEnd: "",
        workingHoursSundayStart: "",
        workingHoursSundayEnd: "",
        zipCode: "",
        onMondayClose: false,
        onTuesdayClose: false,
        onWednesdayClose: false,
        onThursdayClose: false,
        onFridayClose: false,
        delivery: true,
        restaurant: true,
        range: "",
        admin: 0
    });

    const [admins, setAdmins] = React.useState([]);

    const [firstRender, setFirstRender] = React.useState(true);
    const confirm = useConfirm();

    const getAdmins = async () => {
        if (firstRender) {
            var a = await DataManager.getWithoutCallback('users/noRestaurant');
            setAdmins(a);
            setFirstRender(false);
        }
    }

    const dataProvider = useDataProvider();

    /* Methods declaration */

    // Buttons that upload images
    const notify = useNotify();

    const loadRestaurantPicture = (e) => {
        if(e.target.files[0]) {
            if (!e.target.files[0].type.includes("image")) {
                notify('You can load only images', 'error');
                return;
            }
            if (e.target.files[0].size > 1000000) {
                notify('The picture may not be greater than 1000 kilobytes', 'error');
                return;
            }

            FileManager.UploadImage("restaurants/image", e.target.files[0], setRestaurantPicture);
            setErrorRestaurantPicture(false);
        }
    };

    const loadRestaurantMenuPicture = (e) => {
        if(e.target.files[0]) {
            if (!e.target.files[0].type.includes("image")) {
                notify('You can load only images', 'error');
                return;
            }
            if (e.target.files[0].size > 1000000) {
                notify('The picture may not be greater than 1000 kilobytes', 'error');
                return;
            }

            FileManager.UploadImage("restaurants/image", e.target.files[0], setRestaurantMenuPicture);
            setErrorRestaurantMenuPicture(false);
        }

    }
    const loadRestaurantLogo = (e) => {
        if(e.target.files[0]) {
            if (!e.target.files[0].type.includes("image")) {
                notify('You can load only images', 'error');
                return;
            }
            if (e.target.files[0].size > 1000000) {
                notify('The picture may not be greater than 1000 kilobytes', 'error');
                return;
            }
            FileManager.UploadImage("restaurants/image", e.target.files[0], setRestaurantLogo);
            setErrorRestaurantLogo(false);
        }
    }

    /**
     * Validates restaurant data before save / update.
     **/
    const validateRestaurantData = () => {
        var isValidZip = /^\d{5}$/;

        var nameError = false;
        var addressError = false;
        var zipError = false;
        var phoneError = false;
        var hoursError = false;
        var hoursErrorOrderWk = false;
        var hoursErrorOrderDl = false;
        var adminError = false;
        var pickupDeliveryRangeError = false;
        var deliveryPriceError = false;
        var deliveryTimeError = false;
        var deliveryMinTimeError = false;
        var minDeliveryOrderError = false;

        var workingHoursStartError = false;
        var workingHoursEndError = false;
        var workingHoursSaturdayStartError = false;
        var workingHoursSaturdayEndError = false;
        var workingHoursSundayStartError = false;
        var workingHoursSundayEndError = false;

        var deliveryHoursStartError = false;
        var deliveryHoursEndError = false;
        var deliveryHoursSaturdayStartError = false;
        var deliveryHoursSaturdayEndError = false;
        var deliveryHoursSundayStartError = false;
        var deliveryHoursSundayEndError = false;

        var restaurantPictureError = false;
        var restaurantMenuPictureError = false;
        var restaurantLogoError = false;

        if (RestaurantData.name.trim() === '') {
            setErrorName(true);
            nameError = true;
        } else {
            setErrorName(false);
            nameError = false;
        }

        if (RestaurantData.address.trim() === '') {
            setErrorAddress(true);
            addressError = true;
        } else {
            setErrorAddress(false);
            addressError = false;
        }

        if (!isValidZip.test(RestaurantData.zipCode)) {
            setErrorZip(true);
            zipError = true;
        } else {
            setErrorZip(false);
            zipError = false;
        }

        if (RestaurantData.phone.length < 6) {
            setErrorPhone(true);
            phoneError = true;
        } else {
            setErrorPhone(false);
            phoneError = false;
        }

        if (RestaurantData.deliveryTime < 5) {
            setErrorDeliveryMinTime(true);
            deliveryMinTimeError = true;
        } else {
            setErrorDeliveryMinTime(false);
            deliveryMinTimeError = false;
        }

        if (!props.edit) {
            if (RestaurantData.admin === 0) {
                setErrorAdmin(true);
                adminError = true;
            } else {
                setErrorAdmin(false);
                adminError = false;
            }
        }

        if (RestaurantData.range.toString().trim() === '') {
            setErrorPickupDeliveryRange(true);
            pickupDeliveryRangeError = true;
        } else {
            setErrorPickupDeliveryRange(false);
            pickupDeliveryRangeError = false;
        }

        if (RestaurantData.delivery) {
            if (RestaurantData.deliveryCost.toString().trim() === '') {
                setErrorDeliveryPrice(true);
                deliveryPriceError = true;
            } else {
                setErrorDeliveryPrice(false);
                deliveryPriceError = false;
            }

            if (RestaurantData.deliveryTime.toString().trim() === '') {
                setErrorDeliveryTime(true);
                deliveryTimeError = true;
            } else {
                setErrorDeliveryTime(false);
                deliveryTimeError = false;
            }

            if (RestaurantData.minDeliveryOrder.toString().trim() === '') {
                setErrorMinDeliveryOrder(true);
                minDeliveryOrderError = true;
            } else {
                setErrorMinDeliveryOrder(false);
                minDeliveryOrderError = false;
            }

            if (RestaurantData.deliveryHoursStart.toString().trim() === '') {
                setErrorDeliveryHoursStart(true);
                deliveryHoursStartError = true;
            } else {
                setErrorDeliveryHoursStart(false);
                deliveryHoursStartError = false;
            }

            if (RestaurantData.deliveryHoursEnd.toString().trim() === '') {
                setErrorDeliveryHoursEnd(true);
                deliveryHoursEndError = true;
            } else {
                setErrorDeliveryHoursEnd(false);
                deliveryHoursEndError = false;
            }

            if (!checkedDeliveryHoursSaturday) {
                if (!RestaurantData.deliveryHoursSaturdayStart
                    || RestaurantData.deliveryHoursSaturdayStart.toString().trim() === '') {
                    setErrorDeliveryHoursSaturdayStart(true);
                    deliveryHoursSaturdayStartError = true;
                } else {
                    setErrorDeliveryHoursSaturdayStart(false);
                    deliveryHoursSaturdayStartError = false;
                }

                if (!RestaurantData.deliveryHoursSaturdayEnd
                    || RestaurantData.deliveryHoursSaturdayEnd.toString().trim() === '') {
                    setErrorDeliveryHoursSaturdayEnd(true);
                    deliveryHoursSaturdayEndError = true;
                } else {
                    setErrorDeliveryHoursSaturdayEnd(false);
                    deliveryHoursSaturdayEndError = false;
                }
            }

            if (!checkedDeliveryHoursSunday) {
                if (!RestaurantData.deliveryHoursSundayStart
                    || RestaurantData.deliveryHoursSundayStart.toString().trim() === '') {
                    setErrorDeliveryHoursSundayStart(true);
                    deliveryHoursSundayStartError = true;
                } else {
                    setErrorDeliveryHoursSundayStart(false);
                    deliveryHoursSundayStartError = false;
                }

                if (!RestaurantData.deliveryHoursSundayEnd
                    || RestaurantData.deliveryHoursSundayEnd.toString().trim() === '') {
                    setErrorDeliveryHoursSundayEnd(true);
                    deliveryHoursSundayEndError = true;
                } else {
                    setErrorDeliveryHoursSundayEnd(false);
                    deliveryHoursSundayEndError = false;
                }
            }
        }

        if (!RestaurantData.workingHoursStart
            || RestaurantData.workingHoursStart.toString().trim() === '') {
            setErrorWorkingHoursStart(true);
            workingHoursStartError = true;
        } else {
            setErrorWorkingHoursStart(false);
            workingHoursStartError = false;
        }

        if (!RestaurantData.workingHoursEnd
            || RestaurantData.workingHoursEnd.toString().trim() === '') {
            setErrorWorkingHoursEnd(true);
            workingHoursEndError = true;
        } else {
            setErrorWorkingHoursEnd(false);
            workingHoursEndError = false;
        }

        if (!checkedWorkingHoursSaturday) {
            if (!RestaurantData.workingHoursSaturdayStart
                || RestaurantData.workingHoursSaturdayStart.toString().trim() === '') {
                setErrorWorkingHoursSaturdayStart(true);
                workingHoursSaturdayStartError = true;
            } else {
                setErrorWorkingHoursSaturdayStart(false);
                workingHoursSaturdayStartError = false;
            }

            if (!RestaurantData.workingHoursSaturdayEnd
                || RestaurantData.workingHoursSaturdayEnd.toString().trim() === '') {
                setErrorWorkingHoursSaturdayEnd(true);
                workingHoursSaturdayEndError = true;
            } else {
                setErrorWorkingHoursSaturdayEnd(false);
                workingHoursSaturdayEndError = false;
            }
        }

        if (!checkedWorkingHoursSunday) {
            if (!RestaurantData.workingHoursSundayStart
                || RestaurantData.workingHoursSundayStart.toString().trim() === '') {
                setErrorWorkingHoursSundayStart(true);
                workingHoursSundayStartError = true;
            } else {
                setErrorWorkingHoursSundayStart(false);
                workingHoursSundayStartError = false;
            }

            if (!RestaurantData.workingHoursSundayEnd
                || RestaurantData.workingHoursSundayEnd.toString().trim() === '') {
                setErrorWorkingHoursSundayEnd(true);
                workingHoursSundayEndError = true;
            } else {
                setErrorWorkingHoursSundayEnd(false);
                workingHoursSundayEndError = false;
            }
        }

        if (RestaurantPicture.trim() === '') {
            setErrorRestaurantPicture(true);
            restaurantPictureError = true;
        } else {
            setErrorRestaurantPicture(false);
            restaurantPictureError = false;
        }

        if (RestaurantLogo.trim() === '') {
            setErrorRestaurantLogo(true);
            restaurantLogoError = true;
        } else {
            setErrorRestaurantLogo(false);
            restaurantLogoError = false;
        }

        if (RestaurantMenuPicture.trim() === '') {
            setErrorRestaurantMenuPicture(true);
            restaurantMenuPictureError = true;
        } else {
            setErrorRestaurantMenuPicture(false);
            restaurantMenuPictureError = false;
        }

        if (errorInHours(RestaurantData.workingHoursStart, RestaurantData.workingHoursEnd, RestaurantData.deliveryHoursStart, RestaurantData.deliveryHoursEnd, RestaurantData.onMondayClose, RestaurantData.onMondayClose) ||
            errorInHours(RestaurantData.workingHoursStart, RestaurantData.workingHoursEnd, RestaurantData.deliveryHoursStart, RestaurantData.deliveryHoursEnd, RestaurantData.onTuesdayClose, RestaurantData.onTuesdayClose) ||
            errorInHours(RestaurantData.workingHoursStart, RestaurantData.workingHoursEnd, RestaurantData.deliveryHoursStart, RestaurantData.deliveryHoursEnd, RestaurantData.onWednesdayClose, RestaurantData.onWednesdayClose) ||
            errorInHours(RestaurantData.workingHoursStart, RestaurantData.workingHoursEnd, RestaurantData.deliveryHoursStart, RestaurantData.deliveryHoursEnd, RestaurantData.onThursdayClose, RestaurantData.onThursdayClose) ||
            errorInHours(RestaurantData.workingHoursStart, RestaurantData.workingHoursEnd, RestaurantData.deliveryHoursStart, RestaurantData.deliveryHoursEnd, RestaurantData.onFridayClose, RestaurantData.onFridayClose) ||
            errorInHours(RestaurantData.workingHoursSaturdayStart, RestaurantData.workingHoursSaturdayEnd, RestaurantData.deliveryHoursSaturdayStart, RestaurantData.deliveryHoursSaturdayEnd, checkedWorkingHoursSaturday, checkedDeliveryHoursSaturday) ||
            errorInHours(RestaurantData.workingHoursSundayStart, RestaurantData.workingHoursSundayEnd, RestaurantData.deliveryHoursSundayStart, RestaurantData.deliveryHoursSundayEnd, checkedWorkingHoursSunday, checkedDeliveryHoursSunday)
        ) {
            setErrorHours(true);
            hoursError = true
        } else {
            setErrorHours(false);
            hoursError = false
        }

        hoursErrorOrderWk = workingError || (workingErrorSat && !checkedWorkingHoursSaturday) || (workingErrorSun && !checkedWorkingHoursSunday);

        if (RestaurantData.delivery) {
            hoursErrorOrderDl = deliveryError || (deliveryErrorSat && !checkedDeliveryHoursSaturday) || (deliveryErrorSun && !checkedDeliveryHoursSunday);
        }
        setErrorHoursOrder(hoursErrorOrderWk || hoursErrorOrderDl);

        let otherInputsError = nameError || zipError || phoneError || addressError || adminError || pickupDeliveryRangeError
            || deliveryPriceError || deliveryTimeError || minDeliveryOrderError || workingHoursStartError || workingHoursEndError
            || workingHoursSaturdayStartError || workingHoursSaturdayEndError || workingHoursSundayStartError || workingHoursSundayEndError
            || deliveryHoursStartError || deliveryHoursEndError || deliveryHoursSaturdayStartError || deliveryHoursSaturdayEndError
            || deliveryHoursSundayStartError || deliveryHoursSundayEndError || restaurantPictureError || restaurantMenuPictureError
            || restaurantLogoError || deliveryMinTimeError;

        let hoursOrderError = hoursErrorOrderWk || hoursErrorOrderDl;

        if (otherInputsError || hoursError || hoursOrderError){
            if (otherInputsError) {
                setShowErrorMsg(true);
            } else {
                setShowErrorMsg(false);
            }
            return false;
        }
        return true;

    };

    // Buttons for creating and editing restaurants
    const createRestaurant = () => {
        if (!validateRestaurantData()) {
            return;
        }

        var localizations = {
            en: {
                restaurantName: RestaurantData.name,
                restaurantDescription: RestaurantData.description
            }
        }
        if (RestaurantData.nameIT.trim().length > 0 && RestaurantData.descriptionIT.trim().length === 0) {
            localizations = {
                en: {
                    restaurantName: RestaurantData.name,
                    restaurantDescription: RestaurantData.description
                },
                it: {
                    restaurantName: RestaurantData.nameIT
                }
            }
        } else if (RestaurantData.nameIT.trim().length === 0 && RestaurantData.descriptionIT.trim().length > 0) {
            localizations = {
                en: {
                    restaurantName: RestaurantData.name,
                    restaurantDescription: RestaurantData.description
                },
                it: {
                    restaurantDescription: RestaurantData.descriptionIT
                }
            }
        } else if (RestaurantData.nameIT.trim().length > 0 && RestaurantData.descriptionIT.trim().length > 0) {
            localizations = {
                en: {
                    restaurantName: RestaurantData.name,
                    restaurantDescription: RestaurantData.description
                },
                it: {
                    restaurantName: RestaurantData.nameIT,
                    restaurantDescription: RestaurantData.descriptionIT
                }
            }
        }
        DataManager.create('restaurants', {
            name: RestaurantData.name,
            localizations: localizations,
            address: RestaurantData.address,
            description: RestaurantData.description,
            zipCode: RestaurantData.zipCode,
            phone: RestaurantData.phone,
            range: RestaurantData.range,
            deliveryCost: RestaurantData.deliveryCost,
            deliveryTime: RestaurantData.deliveryTime,
            minDeliveryOrder: RestaurantData.minDeliveryOrder,
            imageUrl: RestaurantPicture,
            headingImageUrl: RestaurantMenuPicture,
            logoUrl: RestaurantLogo,
            deliveryHours: [
                {
                    dayNumber: 0,
                    startTime: RestaurantData.onMondayClose ? null : RestaurantData.deliveryHoursStart,
                    endTime: RestaurantData.onMondayClose ? null : RestaurantData.deliveryHoursEnd
                },
                {
                    dayNumber: 1,
                    startTime: RestaurantData.onTuesdayClose ? null : RestaurantData.deliveryHoursStart,
                    endTime: RestaurantData.onTuesdayClose ? null : RestaurantData.deliveryHoursEnd
                },
                {
                    dayNumber: 2,
                    startTime: RestaurantData.onWednesdayClose ? null : RestaurantData.deliveryHoursStart,
                    endTime: RestaurantData.onWednesdayClose ? null : RestaurantData.deliveryHoursEnd
                },
                {
                    dayNumber: 3,
                    startTime: RestaurantData.onThursdayClose ? null : RestaurantData.deliveryHoursStart,
                    endTime: RestaurantData.onThursdayClose ? null : RestaurantData.deliveryHoursEnd
                },
                {
                    dayNumber: 4,
                    startTime: RestaurantData.onFridayClose ? null : RestaurantData.deliveryHoursStart,
                    endTime: RestaurantData.onFridayClose ? null : RestaurantData.deliveryHoursEnd
                },
                {
                    dayNumber: 5,
                    startTime: checkedDeliveryHoursSaturday ? null : RestaurantData.deliveryHoursSaturdayStart,
                    endTime: checkedDeliveryHoursSaturday ? null : RestaurantData.deliveryHoursSaturdayEnd
                },
                {
                    dayNumber: 6,
                    startTime: checkedDeliveryHoursSunday ? null : RestaurantData.deliveryHoursSundayStart,
                    endTime: checkedDeliveryHoursSunday ? null : RestaurantData.deliveryHoursSundayEnd
                }
            ],
            workingHours: [
                {
                    dayNumber: 0,
                    startTime: RestaurantData.onMondayClose ? null : RestaurantData.workingHoursStart,
                    endTime: RestaurantData.onMondayClose ? null : RestaurantData.workingHoursEnd
                },
                {

                    dayNumber: 1,
                    startTime: RestaurantData.onTuesdayClose ? null : RestaurantData.workingHoursStart,
                    endTime: RestaurantData.onTuesdayClose ? null : RestaurantData.workingHoursEnd
                },
                {

                    dayNumber: 2,
                    startTime: RestaurantData.onWednesdayClose ? null : RestaurantData.workingHoursStart,
                    endTime: RestaurantData.onWednesdayClose ? null : RestaurantData.workingHoursEnd
                },
                {

                    dayNumber: 3,
                    startTime: RestaurantData.onThursdayClose ? null : RestaurantData.workingHoursStart,
                    endTime: RestaurantData.onThursdayClose ? null : RestaurantData.workingHoursEnd
                },
                {
                    dayNumber: 4,
                    startTime: RestaurantData.onFridayClose ? null : RestaurantData.workingHoursStart,
                    endTime: RestaurantData.onFridayClose ? null : RestaurantData.workingHoursEnd
                },
                {

                    dayNumber: 5,
                    startTime: checkedWorkingHoursSaturday ? null : RestaurantData.workingHoursSaturdayStart,
                    endTime: checkedWorkingHoursSaturday ? null : RestaurantData.workingHoursSaturdayEnd
                },
                {

                    dayNumber: 6,
                    startTime: checkedWorkingHoursSunday ? null : RestaurantData.workingHoursSundayStart,
                    endTime: checkedWorkingHoursSunday ? null : RestaurantData.workingHoursSundayEnd
                }
            ],
            //To implement
            status: RestaurantData.restaurant,
            //To implement
            delivery: RestaurantData.delivery,
            //To implement
            admin: RestaurantData.admin,
        }
            , showCustomMsgAndCloseDialog);
    };

    const errorInHours = (startWork, endWork, startDelivery, endDelivery, workClosed, deliveryClosed) => {

        if (workClosed && RestaurantData.delivery && !deliveryClosed && startDelivery && endDelivery) {
            return true;
        }
        if ((!workClosed && startWork && endWork && deliveryClosed) || (workClosed && deliveryClosed)) {
            return false;
        }

        if (!startWork || !endWork || !startDelivery || !endDelivery) {
            return;
        }

        var startWorkDate = new Date(2000, 0, 1, startWork.split(':')[0], startWork.split(':')[1])
        var endWorkDate = new Date(2000, 0, 1, endWork.split(':')[0], endWork.split(':')[1])
        var startDeliveryDate = new Date(2000, 0, 1, startDelivery.split(':')[0], startDelivery.split(':')[1])
        var endDeliveryDate = new Date(2000, 0, 1, endDelivery.split(':')[0], endDelivery.split(':')[1])

        return RestaurantData.delivery && (startDeliveryDate.getTime() < startWorkDate.getTime() || endDeliveryDate.getTime() > endWorkDate.getTime());

    };

    /**
     * Method shows custom message to the user and closes edit/create restaurant modal.
     **/
    const showCustomMsgAndCloseDialog = () => {
        if (!props.edit) {
            props.showNotification('Element created.');
        } else {
            props.showNotification('Element updated.');
        }
        closeDialog();
    };

    const editRestaurant = (id) => {
        if (!validateRestaurantData()) {
            return;
        }
        var localizations = {
            en: {
                restaurantName: RestaurantData.name,
                restaurantDescription: RestaurantData.description
            }
        }
        if (RestaurantData.nameIT.trim().length > 0 && RestaurantData.descriptionIT.trim().length === 0) {
            localizations = {
                en: {
                    restaurantName: RestaurantData.name,
                    restaurantDescription: RestaurantData.description
                },
                it: {
                    restaurantName: RestaurantData.nameIT
                }
            }
        } else if (RestaurantData.nameIT.trim().length === 0 && RestaurantData.descriptionIT.trim().length > 0) {
            localizations = {
                en: {
                    restaurantName: RestaurantData.name,
                    restaurantDescription: RestaurantData.description
                },
                it: {
                    restaurantDescription: RestaurantData.descriptionIT
                }
            }
        } else if (RestaurantData.nameIT.trim().length > 0 && RestaurantData.descriptionIT.trim().length > 0) {
            localizations = {
                en: {
                    restaurantName: RestaurantData.name,
                    restaurantDescription: RestaurantData.description
                },
                it: {
                    restaurantName: RestaurantData.nameIT,
                    restaurantDescription: RestaurantData.descriptionIT
                }
            }
        }
        DataManager.update('restaurants', id, {
            name: RestaurantData.name,
            localizations: localizations,
            address: RestaurantData.address,
            description: RestaurantData.description,
            zipCode: RestaurantData.zipCode,
            phoneNumber: RestaurantData.phone,
            range: RestaurantData.range,
            deliveryCost: RestaurantData.deliveryCost,
            deliveryTime: RestaurantData.deliveryTime,
            minDeliveryOrder: RestaurantData.minDeliveryOrder,
            imageUrl: RestaurantPicture,
            headingImageUrl: RestaurantMenuPicture,
            logoUrl: RestaurantLogo,
            status: RestaurantData.restaurant,
            delivery: RestaurantData.delivery,
            deliveryHours: [
                {
                    dayNumber: 0,
                    startTime: RestaurantData.onMondayClose ? null : RestaurantData.deliveryHoursStart,
                    endTime: RestaurantData.onMondayClose ? null : RestaurantData.deliveryHoursEnd
                },
                {
                    dayNumber: 1,
                    startTime: RestaurantData.onTuesdayClose ? null : RestaurantData.deliveryHoursStart,
                    endTime: RestaurantData.onTuesdayClose ? null : RestaurantData.deliveryHoursEnd
                },
                {
                    dayNumber: 2,
                    startTime: RestaurantData.onWednesdayClose ? null : RestaurantData.deliveryHoursStart,
                    endTime: RestaurantData.onWednesdayClose ? null : RestaurantData.deliveryHoursEnd
                },
                {
                    dayNumber: 3,
                    startTime: RestaurantData.onThursdayClose ? null : RestaurantData.deliveryHoursStart,
                    endTime: RestaurantData.onThursdayClose ? null : RestaurantData.deliveryHoursEnd
                },
                {
                    dayNumber: 4,
                    startTime: RestaurantData.onFridayClose ? null : RestaurantData.deliveryHoursStart,
                    endTime: RestaurantData.onFridayClose ? null : RestaurantData.deliveryHoursEnd
                },
                {
                    dayNumber: 5,
                    startTime: checkedDeliveryHoursSaturday ? null : RestaurantData.deliveryHoursSaturdayStart,
                    endTime: checkedDeliveryHoursSaturday ? null : RestaurantData.deliveryHoursSaturdayEnd
                },
                {
                    dayNumber: 6,
                    startTime: checkedDeliveryHoursSunday ? null : RestaurantData.deliveryHoursSundayStart,
                    endTime: checkedDeliveryHoursSunday ? null : RestaurantData.deliveryHoursSundayEnd
                }
            ],
            workingHours: [
                {
                    dayNumber: 0,
                    startTime: RestaurantData.onMondayClose ? null : RestaurantData.workingHoursStart,
                    endTime: RestaurantData.onMondayClose ? null : RestaurantData.workingHoursEnd
                },
                {

                    dayNumber: 1,
                    startTime: RestaurantData.onTuesdayClose ? null : RestaurantData.workingHoursStart,
                    endTime: RestaurantData.onTuesdayClose ? null : RestaurantData.workingHoursEnd
                },
                {

                    dayNumber: 2,
                    startTime: RestaurantData.onWednesdayClose ? null : RestaurantData.workingHoursStart,
                    endTime: RestaurantData.onWednesdayClose ? null : RestaurantData.workingHoursEnd
                },
                {

                    dayNumber: 3,
                    startTime: RestaurantData.onThursdayClose ? null : RestaurantData.workingHoursStart,
                    endTime: RestaurantData.onThursdayClose ? null : RestaurantData.workingHoursEnd
                },
                {
                    dayNumber: 4,
                    startTime: RestaurantData.onFridayClose ? null : RestaurantData.workingHoursStart,
                    endTime: RestaurantData.onFridayClose ? null : RestaurantData.workingHoursEnd
                },
                {

                    dayNumber: 5,
                    startTime: checkedWorkingHoursSaturday ? null : RestaurantData.workingHoursSaturdayStart,
                    endTime: checkedWorkingHoursSaturday ? null : RestaurantData.workingHoursSaturdayEnd
                },
                {

                    dayNumber: 6,
                    startTime: checkedWorkingHoursSunday ? null : RestaurantData.workingHoursSundayStart,
                    endTime: checkedWorkingHoursSunday ? null : RestaurantData.workingHoursSundayEnd
                }
            ],
        }
            , showCustomMsgAndCloseDialog);
    }

    //Method that manage if some fields has to appear/disappear
    const handleChangeCheckedWorkingHoursSaturday = (checked) => {
        setCheckedWorkingHoursSaturday(checked);
        if (checked) {
            setErrorWorkingHoursSaturdayStart(false);
            setErrorWorkingHoursSaturdayEnd(false);
            handleChangeCheckedDeliveryHoursSaturday(true);
        }
    };
    const handleChangeCheckedDeliveryHoursSaturday = (checked) => {
        setCheckedDeliveryHoursSaturday(checked);
        if (checked) {
            setErrorDeliveryHoursSaturdayStart(false);
            setErrorDeliveryHoursSaturdayEnd(false);
        }
    };
    const handleChangeCheckedWorkingHoursSunday = (checked) => {
        setCheckedWorkingHoursSunday(checked);
        if (checked) {
            setErrorWorkingHoursSundayStart(false);
            setErrorWorkingHoursSundayEnd(false);
            handleChangeCheckedDeliveryHoursSunday(true);
        }
    };
    const handleChangeCheckedDeliveryHoursSunday = (checked) => {
        setCheckedDeliveryHoursSunday(checked);
        if (checked) {
            setErrorDeliveryHoursSundayStart(false);
            setErrorDeliveryHoursSundayEnd(false);
        }
    };

    // Dialog management
    const closeDialog = (closeButton = false) => {
        if (closeButton) {
            confirm({ description: "If you close your changes will be lost" })
                .then(() => {
                    setCreateEditDialogOpen(false);
                    setRestaurantPicture("");
                    setRestaurantMenuPicture("");
                    setRestaurantLogo("");
                    if (!props.edit) {
                        props.history.push(`/restaurants`);
                    } else {
                        let res = "restaurants";
                        if (props.myPage) {
                            res = "restaurants-my"
                        }
                        dataProvider.getOne(res, { id: props.id });
                        props.refresh();
                    }
                })
        } else {
            setCreateEditDialogOpen(false);
            setRestaurantPicture("");
            setRestaurantMenuPicture("");
            setRestaurantLogo("");
            if (!props.edit) {
                props.history.push(`/restaurants`);
            } else {
                let res = "restaurants";
                if (props.myPage) {
                    res = "restaurants-my"
                }
                dataProvider.getOne(res, { id: props.id });
                props.refresh();
            }
        }
    };

    const openDialog = () => {
        setCreateEditDialogOpen(true);
        if (props.edit) {
            dataProvider.getOne("restaurants", { id: props.id })
                .then(({ data }) => {
                    // Managing the days of the week opened
                    var onMondayClose = false;
                    var onTuesdayClose = false;
                    var onWednesdayClose = false;
                    var onThursdayClose = false;
                    var onFridayClose = false;

                    if (data.workingHours[0] === undefined) {
                        onMondayClose = true;
                        onTuesdayClose = true;
                        onWednesdayClose = true;
                        onThursdayClose = true;
                        onFridayClose = true;
                    } else {
                        if ((data.workingHours[0].startTime == null) || (data.workingHours[0].endTime == null)) {
                            onMondayClose = true;
                        }
                        if ((data.workingHours[1].startTime == null) || (data.workingHours[1].endTime == null)) {
                            onTuesdayClose = true;
                        }
                        if ((data.workingHours[2].startTime == null) || (data.workingHours[2].endTime == null)) {
                            onWednesdayClose = true;
                        }
                        if ((data.workingHours[3].startTime == null) || (data.workingHours[3].endTime == null)) {
                            onThursdayClose = true;
                        }
                        if ((data.workingHours[4].startTime == null) || (data.workingHours[4].endTime == null)) {
                            onFridayClose = true;
                        }
                    }

                    // Managing the hours
                    var workingHoursSaturdayStart = "";
                    var workingHoursSaturdayEnd = "";
                    var workingHoursSundayStart = "";
                    var workingHoursSundayEnd = "";
                    var deliveryHoursSaturdayStart = "";
                    var deliveryHoursSaturdayEnd = "";
                    var deliveryHoursSundayStart = "";
                    var deliveryHoursSundayEnd = "";
                    var workingHoursStartCalculated = "";
                    var workingHoursEndCalculated = "";
                    var deliveryHoursStartCalculated = "";
                    var deliveryHoursEndCalculated = "";
                    if (!(data.workingHours[0] === undefined)) {
                        for (let i = 0; i < 5; i++) {
                            if (!(data.workingHours[i].startTime == null)) {
                                workingHoursStartCalculated = data.workingHours[i].startTime;
                            }
                            if (!(data.workingHours[i].endTime == null)) {
                                workingHoursEndCalculated = data.workingHours[i].endTime;
                            }
                        }
                        workingHoursSaturdayStart = data.workingHours[5].startTime;
                        workingHoursSaturdayEnd = data.workingHours[5].endTime;
                        workingHoursSundayStart = data.workingHours[6].startTime;
                        workingHoursSundayEnd = data.workingHours[6].endTime;
                    }
                    if (!(data.deliveryHours[0] === undefined)) {
                        for (let i = 0; i < 5; i++) {
                            if (!(data.deliveryHours[i].startTime == null)) {
                                deliveryHoursStartCalculated = data.deliveryHours[i].startTime;
                            }
                            if (!(data.deliveryHours[i].endTime == null)) {
                                deliveryHoursEndCalculated = data.deliveryHours[i].endTime;
                            }
                        }
                        deliveryHoursSaturdayStart = data.deliveryHours[5].startTime;
                        deliveryHoursSaturdayEnd = data.deliveryHours[5].endTime;
                        deliveryHoursSundayStart = data.deliveryHours[6].startTime;
                        deliveryHoursSundayEnd = data.deliveryHours[6].endTime;
                    }

                    // Managing the 4 checkbox "closed"
                    if ((data.workingHours[0] === undefined)) {
                        handleChangeCheckedWorkingHoursSaturday(true);
                        handleChangeCheckedWorkingHoursSunday(true);
                    } else {
                        if ((data.workingHours[5].startTime == null) || (data.workingHours[5].endTime == null)) {
                            handleChangeCheckedWorkingHoursSaturday(true);
                        } else {
                            handleChangeCheckedWorkingHoursSaturday(false);
                        }
                        if ((data.workingHours[6].startTime == null) || (data.workingHours[6].endTime == null)) {
                            handleChangeCheckedWorkingHoursSunday(true);
                        } else {
                            handleChangeCheckedWorkingHoursSunday(false);
                        }
                    }

                    if ((data.deliveryHours[0] === undefined)) {
                        handleChangeCheckedDeliveryHoursSaturday(true);
                        handleChangeCheckedDeliveryHoursSunday(true);
                    } else {
                        if ((data.deliveryHours[5].startTime == null) || (data.deliveryHours[5].endTime == null)) {
                            handleChangeCheckedDeliveryHoursSaturday(true);
                        } else {
                            handleChangeCheckedDeliveryHoursSaturday(false);
                        }

                        if ((data.deliveryHours[6].startTime == null) || (data.deliveryHours[6].startTime == null)) {
                            handleChangeCheckedDeliveryHoursSunday(true);
                        } else {
                            handleChangeCheckedDeliveryHoursSunday(false);
                        }
                    }

                    setRestaurantData({
                        aboutContent: data.aboutContent,
                        aboutHeading: data.aboutHeading,
                        address: data.address,
                        deliveryCost: data.deliveryCost,
                        deliveryHoursStart: deliveryHoursStartCalculated,
                        deliveryHoursEnd: deliveryHoursEndCalculated,
                        deliveryHoursSaturdayStart: deliveryHoursSaturdayStart,
                        deliveryHoursSaturdayEnd: deliveryHoursSaturdayEnd,
                        deliveryHoursSundayStart: deliveryHoursSundayStart,
                        deliveryHoursSundayEnd: deliveryHoursSundayEnd,
                        deliveryTime: data.deliveryTime,
                        description: data.localizations.en ? data.localizations.en.restaurantDescription ? data.localizations.en.restaurantDescription : "" : "",
                        descriptionIT: data.localizations.it ?  data.localizations.it.restaurantDescription ? data.localizations.it.restaurantDescription : "" : "",
                        headingImageUrl: data.headingImageUrl,
                        id: data.id,
                        imageUrl: data.imageUrl,
                        logoUrl: data.logoUrl,
                        minDeliveryOrder: data.minDeliveryOrder,
                        name: data.localizations.en ? data.localizations.en.restaurantName ? data.localizations.en.restaurantName : "" : "",
                        nameIT:data.localizations.it ? data.localizations.it.restaurantName ? data.localizations.it.restaurantName : "" : "",
                        phone: data.phone,
                        workingHoursStart: workingHoursStartCalculated,
                        workingHoursEnd: workingHoursEndCalculated,
                        workingHoursSaturdayStart: workingHoursSaturdayStart,
                        workingHoursSaturdayEnd: workingHoursSaturdayEnd,
                        workingHoursSundayStart: workingHoursSundayStart,
                        workingHoursSundayEnd: workingHoursSundayEnd,
                        zipCode: data.zipCode,
                        onMondayClose: onMondayClose,
                        onTuesdayClose: onTuesdayClose,
                        onWednesdayClose: onWednesdayClose,
                        onThursdayClose: onThursdayClose,
                        onFridayClose: onFridayClose,
                        //Bind the service's field when it will be added
                        delivery: data.delivery,
                        //Bind the service's field when it will be added
                        restaurant: data.status,
                        //Bind the service's field when it will be added
                        range: data.range,
                        admin: 0
                    });

                    setRestaurantPicture(data.imageUrl);
                    setRestaurantMenuPicture(data.headingImageUrl);
                    setRestaurantLogo(data.logoUrl);
                })

        } else {
            handleChangeCheckedWorkingHoursSaturday(true);
            handleChangeCheckedWorkingHoursSunday(true);
            handleChangeCheckedDeliveryHoursSaturday(true);
            handleChangeCheckedDeliveryHoursSunday(true);
            setRestaurantData({
                aboutContent: null,
                aboutHeading: null,
                address: "",
                deliveryCost: 0,
                deliveryHoursStart: "20:00",
                deliveryHoursEnd: "23:00",
                deliveryHoursSaturdayStart: "20:00",
                deliveryHoursSaturdayEnd: "23:00",
                deliveryHoursSundayStart: "20:00",
                deliveryHoursSundayEnd: "23:00",
                deliveryTime: 0,
                description: "",
                descriptionIT:"",
                headingImageUrl: "",
                id: 65,
                imageUrl: "",
                logoUrl: "",
                minDeliveryOrder: 0,
                name: "",
                nameIT: "",
                phone: "",
                workingHoursStart: "20:00",
                workingHoursEnd: "23:00",
                workingHoursSaturdayStart: "20:00",
                workingHoursSaturdayEnd: "23:00",
                workingHoursSundayStart: "20:00",
                workingHoursSundayEnd: "23:00",
                zipCode: "",
                onMondayClose: false,
                onTuesdayClose: false,
                onWednesdayClose: false,
                onThursdayClose: false,
                onFridayClose: false,
                //Bind the service's field when it will be added
                delivery: true,
                //Bind the service's field when it will be added
                restaurant: true,
                //Bind the service's field when it will be added
                range: 0,
                admin: 0
            });
            // implement call to admin service when it will be present
            DataManager.get('users', (data) => setAdmins(data))
        }
    };


    //Method that is invoked when a field get modify
    const changeValue = (val, field) => {
        if (field === "range" || field === "deliveryCost" || field === "deliveryTime" || field === "minDeliveryOrder") {
            if (val.indexOf('-') !== -1) {
                setRestaurantData((RestaurantData) => ({ ...RestaurantData, [field]: '0' }))
                return;
            }
        }

        setRestaurantData((RestaurantData) => ({ ...RestaurantData, [field]: val }))
    };

    const checkCorrectTimes = (start, end, errorFun, checkboxValue = false) => {

        if (checkboxValue) {
            errorFun(false)
            return false
        }

        if (start !== '' && end !== '' && start !== null && end !== null && typeof start !== 'undefined' && typeof end !== 'undefined') {
            var startSeconds = (+start.split(':')[0]) * 60 * 60 + (+start.split(':')[1]) * 60
            var endSeconds = (+end.split(':')[0]) * 60 * 60 + (+end.split(':')[1]) * 60

            if (startSeconds < endSeconds) {
                errorFun(false)
                return false
            } else {
                errorFun(true)
                return true
            }
        }
    }
    if (!props.edit) {
        getAdmins();
    }

    const [color, setColor] = React.useState('blue');
    const [errorName, setErrorName] = React.useState(false);
    const [errorAddress, setErrorAddress] = React.useState(false);
    const [errorZip, setErrorZip] = React.useState(false);
    const [errorPickupDeliveryRange, setErrorPickupDeliveryRange] = React.useState(false);
    const [errorPhone, setErrorPhone] = React.useState(false);
    const [errorDeliveryPrice, setErrorDeliveryPrice] = React.useState(false);
    const [errorAdmin, setErrorAdmin] = React.useState(false);
    const [errorDeliveryTime, setErrorDeliveryTime] = React.useState(false);
    const [errorMinDeliveryOrder, setErrorMinDeliveryOrder] = React.useState(false);
    const [errorWorkingHoursStart, setErrorWorkingHoursStart] = React.useState(false);
    const [errorWorkingHoursEnd, setErrorWorkingHoursEnd] = React.useState(false);
    const [errorDeliveryHoursStart, setErrorDeliveryHoursStart] = React.useState(false);
    const [errorDeliveryHoursEnd, setErrorDeliveryHoursEnd] = React.useState(false);
    const [errorWorkingHoursSaturdayStart, setErrorWorkingHoursSaturdayStart] = React.useState(false);
    const [errorWorkingHoursSaturdayEnd, setErrorWorkingHoursSaturdayEnd] = React.useState(false);
    const [errorDeliveryHoursSaturdayStart, setErrorDeliveryHoursSaturdayStart] = React.useState(false);
    const [errorDeliveryHoursSaturdayEnd, setErrorDeliveryHoursSaturdayEnd] = React.useState(false);
    const [errorDeliveryMinTime, setErrorDeliveryMinTime] = React.useState(false);
    const [errorWorkingHoursSundayStart, setErrorWorkingHoursSundayStart] = React.useState(false);
    const [errorWorkingHoursSundayEnd, setErrorWorkingHoursSundayEnd] = React.useState(false);
    const [errorDeliveryHoursSundayStart, setErrorDeliveryHoursSundayStart] = React.useState(false);
    const [errorDeliveryHoursSundayEnd, setErrorDeliveryHoursSundayEnd] = React.useState(false);
    const [errorRestaurantLogo, setErrorRestaurantLogo] = React.useState(false);
    const [errorRestaurantPicture, setErrorRestaurantPicture] = React.useState(false);
    const [errorRestaurantMenuPicture, setErrorRestaurantMenuPicture] = React.useState(false);
    const [errorHours, setErrorHours] = React.useState(false);
    const [showErrorMsg, setShowErrorMsg] = React.useState(false);
    const [workingError, setWorkingError] = React.useState(false);
    const [deliveryError, setDeliveryError] = React.useState(false);
    const [workingErrorSat, setWorkingErrorSat] = React.useState(false);
    const [deliveryErrorSat, setDeliveryErrorSat] = React.useState(false);
    const [workingErrorSun, setWorkingErrorSun] = React.useState(false);
    const [deliveryErrorSun, setDeliveryErrorSun] = React.useState(false);
    const [errorHoursOrder, setErrorHoursOrder] = React.useState(false);


    const theme = React.useMemo(() => {
        if (color === 'blue') {
            return createMuiTheme({
                palette: {
                    secondary: {
                        main: blue[500],
                        contrastText: '#fff',
                    },
                },
            });
        }
        return createMuiTheme();
    }, [color]);

    const createAndUpdateForm = (props, index) => (
        <div>
            <div style={styles.container}>
                <Box display="flex">
                    {/* Left side */}
                    <Box flex={1} mr="1em">

                        {/* Left Text field */}
                        {index === 0 ?
                            <TextField error={errorName} label="Name" style={styleMargin.widthMax} value={ RestaurantData.name } onChange={e => {
                                    changeValue(e.target.value, "name");
                                    setErrorName(false);
                            }} /> :
                            <TextField label="Name" style={styleMargin.widthMax} value={ RestaurantData.nameIT } onChange={e => {
                                changeValue(e.target.value, "nameIT");
                            }} />
                        }
                        <TextField error={errorAddress} style={styleMargin.widtMaxTop10} label="Address" placeholder="E.g. Via Quintino Sella 29, Rome" value={RestaurantData.address} onChange={e => {
                            changeValue(e.target.value, "address");
                            setErrorAddress(false);
                        }} />
                        <TextField error={errorZip} style={styleMargin.widtMaxTop10} label="Zip code" value={RestaurantData.zipCode} onChange={e => {
                            changeValue(e.target.value, "zipCode");
                            var isValidZip = /^\d{5}$/;
                            if (isValidZip.test(e.target.value)) {
                                setErrorZip(false);
                            } else {
                                setErrorZip(true);
                            }
                        }} />
                        {errorZip ? <p style={{ color: 'red', margin: 0 }}>Please match the requested format.</p> : null}
                        <TextField error={errorPhone} style={styleMargin.widtMaxTop10} label="Phone number" value={RestaurantData.phone} onChange={e => {
                            changeValue(e.target.value, "phone");
                            var isValidPhoneNumber = /^[0-9]*$/;
                            if (e.target.value.length >= 6 && isValidPhoneNumber.test(e.target.value)) {
                                setErrorPhone(false);
                            } else {
                                setErrorPhone(true);
                            }
                        }} />
                        {errorPhone ? <p style={{ color: 'red', margin: 0 }}>Please match the requested format.</p> : null}
                        {props.edit ?
                            /* (Edit) Restaurant and Delivery Switch */
                            <Box style={styleMargin.marginTop15} display="flex">
                                <Box flex={1} mr="0.5em">
                                    <FormControlLabel value="Restaurant" control={<Switch checked={RestaurantData.restaurant} onChange={e => { changeValue(e.target.checked, "restaurant") }} name="restaurant" color="primary" />} label="Restaurant" labelPlacement="top" />
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    <FormControlLabel value="Delivery" control={<Switch checked={RestaurantData.delivery} onChange={e => { changeValue(e.target.checked, "delivery") }} name="delivery" color="primary" />} label="Delivery" labelPlacement="top" />
                                </Box>
                            </Box>
                            :
                            /* (Create) Admin dropdown */
                            <Box display="flex" style={styleMargin.marginTop10}>
                                <FormControl>
                                    <InputLabel error={errorAdmin} id="selectAdmin">Select admin</InputLabel>
                                    <Select error={errorAdmin} labelId="selectAdmin" className={errorAdmin ? 'Mui-error' : ''} style={{ minWidth: 120 }} onChange={e => {
                                        changeValue(e.target.value, "admin");
                                        setErrorAdmin(false);
                                    }}>
                                        {admins ?
                                            admins.map(admin => <MenuItem value={admin.id} key={admin.id}>{admin.name + ' ' + admin.last_name}</MenuItem>)
                                            : null}
                                    </Select>
                                </FormControl>
                            </Box>
                        }

                        {props.edit ?
                            <h4 style={{ marginTop: 55 }}>Working hours</h4>
                            :
                            <h4 style={{ marginBottom: 5, marginTop: 75 }}>Working hours</h4>
                        }

                        {/* Date Picker working hours */}
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextField error={errorWorkingHoursStart} id="time" ampm={false} label="From" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.workingHoursStart} onChange={async e => {
                                    changeValue(e.target.value, "workingHoursStart")
                                    setErrorWorkingHoursStart(false)
                                    checkCorrectTimes(e.target.value, RestaurantData.workingHoursEnd, setWorkingError)
                                }} />
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <TextField error={errorWorkingHoursEnd} id="time" ampm={false} label="To" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.workingHoursEnd} onChange={e => {
                                    changeValue(e.target.value, "workingHoursEnd")
                                    setErrorWorkingHoursEnd(false)
                                    checkCorrectTimes(RestaurantData.workingHoursStart, e.target.value, setWorkingError)
                                }} />
                            </Box>
                        </Box>

                        <h4 style={styleMargin.marginTopBottom}>Working hours (Saturday)</h4>

                        {/* CheckBox working hours (Saturday) */}
                        <FormControlLabel
                            control={
                                <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="checkedWorkingHoursSaturday" checked={checkedWorkingHoursSaturday} onChange={e => { handleChangeCheckedWorkingHoursSaturday(e.target.checked) }} />
                            }
                            label="Closed"
                        />

                        {/* Date Picker working hours (Saturday) */}
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextField error={errorWorkingHoursSaturdayStart} disabled={checkedWorkingHoursSaturday} id="time" ampm={false} label="From" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.workingHoursSaturdayStart} onChange={e => {
                                    changeValue(e.target.value, "workingHoursSaturdayStart")
                                    setErrorWorkingHoursSaturdayStart(false)
                                    checkCorrectTimes(e.target.value, RestaurantData.workingHoursSaturdayEnd, setWorkingErrorSat, checkedWorkingHoursSaturday)
                                }} />
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <TextField error={errorWorkingHoursSaturdayEnd} disabled={checkedWorkingHoursSaturday} id="time" ampm={false} label="To" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.workingHoursSaturdayEnd} onChange={e => {
                                    changeValue(e.target.value, "workingHoursSaturdayEnd")
                                    setErrorWorkingHoursSaturdayEnd(false)
                                    checkCorrectTimes(RestaurantData.workingHoursSaturdayStart, e.target.value, setWorkingErrorSat, checkedWorkingHoursSaturday)
                                }} />
                            </Box>
                        </Box>

                        <h4 style={styleMargin.marginTopBottom}>Working hours (Sunday)</h4>

                        {/* CheckBox working hours (sunday) */}
                        <FormControlLabel
                            control={
                                <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="checkedWorkingHoursSunday" checked={checkedWorkingHoursSunday} onChange={e => { handleChangeCheckedWorkingHoursSunday(e.target.checked) }} />
                            }
                            label="Closed"
                        />

                        {/* Date Picker working hours (Sunday) */}
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextField error={errorWorkingHoursSundayStart} disabled={checkedWorkingHoursSunday} id="time"  ampm={false} label="From" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.workingHoursSundayStart} onChange={e => {
                                    changeValue(e.target.value, "workingHoursSundayStart")
                                    setErrorWorkingHoursSundayStart(false)
                                    checkCorrectTimes(e.target.value, RestaurantData.workingHoursSundayEnd, setWorkingErrorSun, checkedWorkingHoursSunday)
                                }} />
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <TextField error={errorWorkingHoursSundayEnd} disabled={checkedWorkingHoursSunday} id="time" ampm={false} label="To" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.workingHoursSundayEnd} onChange={e => {
                                    changeValue(e.target.value, "workingHoursSundayEnd")
                                    setErrorWorkingHoursSundayEnd(false)
                                    checkCorrectTimes(RestaurantData.workingHoursSundayStart, e.target.value, setWorkingErrorSun, checkedWorkingHoursSunday)
                                }} />
                            </Box>
                        </Box>

                        <h4 style={styleMargin.marginTopBottom}>Closed on Weekdays</h4>

                        {/* Multiple checkbox closed on weekdays */}
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <FormControlLabel
                                    control={
                                        <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="onMonday" checked={RestaurantData.onMondayClose} onChange={e => {
                                            changeValue(e.target.checked, "onMondayClose")
                                        }} />
                                    } label="Monday" />
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <FormControlLabel
                                    control={
                                        <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="onThursday" checked={RestaurantData.onThursdayClose} onChange={e => { changeValue(e.target.checked, "onThursdayClose") }} />
                                    } label="Thursday" />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <FormControlLabel
                                    control={
                                        <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="onTuesday" checked={RestaurantData.onTuesdayClose} onChange={e => { changeValue(e.target.checked, "onTuesdayClose") }} />
                                    } label="Tuesday" />
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <FormControlLabel
                                    control={
                                        <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="onFriday" checked={RestaurantData.onFridayClose} onChange={e => { changeValue(e.target.checked, "onFridayClose") }} />
                                    } label="Friday" />
                            </Box>
                        </Box>
                        <FormControlLabel
                            style={styleMargin.marginBottom40}
                            control={
                                <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="onWednesday" checked={RestaurantData.onWednesdayClose} onChange={e => { changeValue(e.target.checked, "onWednesdayClose") }} />
                            } label="Wednesday" />
                    </Box>

                    {/* Right side */}
                    <Box flex={1} ml="1em">

                        {/* Right text field */}
                        {index === 0 ?
                            <TextField style={styleMargin.widthMax} label="Description" multiline rows={4} variant="outlined" value={RestaurantData.description} onChange={e => changeValue(e.target.value, "description")} /> :
                            <TextField style={styleMargin.widthMax} label="Description" multiline rows={4} variant="outlined" value={RestaurantData.descriptionIT} onChange={e => changeValue(e.target.value, "descriptionIT")} />
                        }
                        {/* Delivery range */}
                        <FormControl style={styleMargin.widthMax}>
                            <InputLabel error={errorPickupDeliveryRange} style={letterSpacing.letterSpacingSmall} htmlFor="standard-adornment-password">Pick up & delivery range (in meters)</InputLabel>
                            <Input error={errorPickupDeliveryRange} id="outlined-number" inputProps={{ min: "0" }} type="number" InputLabelProps={{ shrink: true, }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        m
                                        </InputAdornment>
                                }
                                value={RestaurantData.range} onChange={e => { changeValue(e.target.value, "range") }} />
                        </FormControl>

                        {/* Delivery price */}
                        {RestaurantData.delivery ?
                            <FormControl style={styleMargin.widthMax}>
                                <InputLabel error={errorDeliveryPrice} style={letterSpacing.letterSpacingSmall} htmlFor="standard-adornment-password">Delivery price</InputLabel>
                                <Input error={errorDeliveryPrice} id="outlined-number" min="0" type="number" inputProps={{ min: "0", step: "0.5" }} InputLabelProps={{ shrink: true, }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            €
                                                </InputAdornment>
                                    }
                                    value={RestaurantData.deliveryCost} onChange={e => { changeValue(e.target.value, "deliveryCost") }}
                                />
                            </FormControl>
                            : null}

                        {/* Delivery time */}
                        {RestaurantData.delivery ?
                            <FormControl style={styleMargin.widthMax}>
                                <InputLabel error={errorDeliveryTime || errorDeliveryMinTime} style={letterSpacing.letterSpacingSmall} htmlFor="standard-adornment-password">Delivery time</InputLabel>
                                <Input error={errorDeliveryTime || errorDeliveryMinTime} id="outlined-number" type="number" inputProps={{ min: "5" }} InInputLabelProps={{ shrink: true, }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            min
                                            </InputAdornment>
                                    }
                                    value={RestaurantData.deliveryTime} onChange={e => {
                                    changeValue(e.target.value, "deliveryTime");
                                    if (e.target.value < 5) {
                                        setErrorDeliveryMinTime(true);
                                    } else {
                                        setErrorDeliveryMinTime(false);
                                    }
                                }}
                                />
                            </FormControl>
                            : null}
                        {errorDeliveryMinTime ? <p style={{ color: 'red', margin: 0 }}>The delivery time can't be lower than 5 minutes</p> : null}

                        {/* Minimum restaurant delivery order */}
                        {RestaurantData.delivery ?
                            <FormControl style={styleMargin.widthMax}>
                                <InputLabel error={errorMinDeliveryOrder} style={letterSpacing.letterSpacingSmall} htmlFor="standard-adornment-password">Minimum delivery order</InputLabel>
                                <Input error={errorMinDeliveryOrder} id="outlined-number" min="0" type="number" inputProps={{ min: "0", step: "0.5" }} InputLabelProps={{ shrink: true }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            €
                                                </InputAdornment>
                                    }
                                    value={RestaurantData.minDeliveryOrder} onChange={e => { changeValue(e.target.value, "minDeliveryOrder") }}
                                />
                            </FormControl>
                            : null}

                        {/* Delivery hours */}
                        {RestaurantData.delivery ?

                            <h4 style={props.edit ? styleMargin.marginTopBottomDeliveryHoursEdit : styleMargin.marginTopBottomDeliveryHoursCreate}>Delivery hours</h4>
                            : null}

                        {/* Date picker Delivery hours */}
                        {RestaurantData.delivery ?
                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextField error={errorDeliveryHoursStart} id="time" ampm={false} label="From" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.deliveryHoursStart} onChange={e => {
                                        changeValue(e.target.value, "deliveryHoursStart")
                                        setErrorDeliveryHoursStart(false)
                                        checkCorrectTimes(e.target.value, RestaurantData.deliveryHoursEnd, setDeliveryError)
                                    }} />
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    <TextField error={errorDeliveryHoursEnd} id="time" ampm={false} label="To" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.deliveryHoursEnd} onChange={e => {
                                        changeValue(e.target.value, "deliveryHoursEnd")
                                        setErrorDeliveryHoursEnd(false)
                                        checkCorrectTimes(RestaurantData.deliveryHoursStart, e.target.value, setDeliveryError)
                                    }} />
                                </Box>
                            </Box> : null
                        }

                        {/* Delivery hours (Saturday) */}
                        {RestaurantData.delivery ?
                            <h4 style={styleMargin.marginTopBottom}>Delivery hours (Saturday)</h4> : null
                        }

                        {/* Checkbox delivery hours (Saturday) */}
                        {RestaurantData.delivery ?
                            <FormControlLabel
                                control={
                                    <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="checkedDeliveryHoursSaturday" checked={checkedDeliveryHoursSaturday} onChange={e => { handleChangeCheckedDeliveryHoursSaturday(e.target.checked) }} />
                                } label="Closed" /> : null
                        }

                        {/* Datepicker delivery hours (Saturday) */}
                        {RestaurantData.delivery ?
                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextField error={errorDeliveryHoursSaturdayStart} disabled={checkedDeliveryHoursSaturday} id="time" ampm={false} label="From" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.deliveryHoursSaturdayStart} onChange={e => {
                                        changeValue(e.target.value, "deliveryHoursSaturdayStart")
                                        setErrorDeliveryHoursSaturdayStart(false)
                                        checkCorrectTimes(e.target.value, RestaurantData.deliveryHoursSaturdayEnd, setDeliveryErrorSat, checkedDeliveryHoursSaturday)
                                    }} />
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    <TextField error={errorDeliveryHoursSaturdayEnd} disabled={checkedDeliveryHoursSaturday} id="time" ampm={false} label="To" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.deliveryHoursSaturdayEnd} onChange={e => {
                                        changeValue(e.target.value, "deliveryHoursSaturdayEnd")
                                        setErrorDeliveryHoursSaturdayEnd(false)
                                        checkCorrectTimes(RestaurantData.deliveryHoursSaturdayStart, e.target.value, setDeliveryErrorSat, checkedDeliveryHoursSaturday)
                                    }} />
                                </Box>
                            </Box> : null
                        }

                        {/* Delivery hours (Sunday) */}
                        {RestaurantData.delivery ?
                            <h4 style={styleMargin.marginTopBottom}>Delivery hours (Sunday)</h4> : null
                        }

                        {/* Checkbox delivery hours (Sunday) */}
                        {RestaurantData.delivery ?
                            <FormControlLabel
                                control={
                                    <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="checkedDeliveryHoursSunday" checked={checkedDeliveryHoursSunday} onChange={e => { handleChangeCheckedDeliveryHoursSunday(e.target.checked) }} />
                                } label="Closed" /> : null
                        }

                        {/* Datepicker delivery hours (Sunday) */}
                        {RestaurantData.delivery ?
                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextField error={errorDeliveryHoursSundayStart} disabled={checkedDeliveryHoursSunday} id="time" ampm={false} label="From" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.deliveryHoursSundayStart} onChange={e => {
                                        changeValue(e.target.value, "deliveryHoursSundayStart")
                                        setErrorDeliveryHoursSundayStart(false)
                                        checkCorrectTimes(e.target.value, RestaurantData.deliveryHoursSundayEnd, setDeliveryErrorSat, checkedDeliveryHoursSunday)
                                    }} />
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    <TextField error={errorDeliveryHoursSundayEnd} disabled={checkedDeliveryHoursSunday} id="time" ampm={false} label="To" type="time" className={styles.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} value={RestaurantData.deliveryHoursSundayEnd} onChange={e => {
                                        changeValue(e.target.value, "deliveryHoursSundayEnd")
                                        setErrorDeliveryHoursSundayEnd(false)
                                        checkCorrectTimes(RestaurantData.deliveryHoursSundayStart, e.target.value, setDeliveryErrorSat, checkedDeliveryHoursSunday)
                                    }} />
                                </Box>
                            </Box> : null
                        }
                    </Box>
                </Box>
            </div>

            {/* 3 Pictures */}
            <UploadArea
                onRestaurantPictureChange={loadRestaurantPicture}
                onRestaurantMenuPictureChange={loadRestaurantMenuPicture}
                onRestaurantLogoChange={loadRestaurantLogo}
                errorRestaurantPicture={errorRestaurantPicture}
                RestaurantPicture={RestaurantPicture}
                errorRestaurantMenuPicture={errorRestaurantMenuPicture}
                RestaurantMenuPicture={RestaurantMenuPicture}
                errorRestaurantLogo={errorRestaurantLogo}
                RestaurantLogo={RestaurantLogo} />
            { errorHours ? <div style={{ borderRadius: 5, marginTop: 30, height: 50, whiteSpace: 'pre-wrap', backgroundColor: 'rgba(217,237,246, 1)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ paddingLeft: 10, marginTop: 5, marginBottom: 5, textAlign: 'center' }}>Delivery closing hours exceed working hours</div>
            </div> : null}
            { errorHoursOrder ? <div style={{ borderRadius: 5, marginTop: 30, height: 50, whiteSpace: 'pre-wrap', backgroundColor: 'rgba(217,237,246, 1)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ paddingLeft: 10, marginTop: 5, marginBottom: 5, textAlign: 'center' }}>Start hour cannot be grater than end hour</div>
            </div> : null}
            { showErrorMsg ? <div style={{ borderRadius: 5, marginTop: 30, height: 50, whiteSpace: 'pre-wrap', backgroundColor: 'rgb(222,0,35)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ paddingLeft: 10, marginTop: 5, marginBottom: 5, textAlign: 'center', color: 'white' }}>All the required fields must be filled.</div>
            </div> : null}
        </div>
    )

    /**
     * Function used for generate tab props.
     * @param {*} index
     */
    function generateTabProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    /**
     * Value for switch language.
     */
    const [value, setValue] = React.useState(0);

    /**
     * Function for handle tabs changes.
     * @param {*} event
     * @param {*} newValue
     */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <span>

            {/* Check if showing modify button or create button */}
            {props.edit ?
                <Button
                onClick={() => openDialog()}
                color="primary"
                startIcon={<EditIcon style={{ color: "primary" }} > EDIT </EditIcon>}
            > EDIT </Button>
                : null
            }

            {/* All the dialog */}
            <Dialog scroll={'body'} open={CreateEditDialogOpen} >

                {/* Title dialog */}
                {props.edit ?
                    <DialogTitle id="customized-dialog-title" onClose={() => { closeDialog(true) }}> <div style={{ textAlign: 'left' }}> Edit Restaurant </div> </DialogTitle>
                    :
                    <DialogTitle id="customized-dialog-title" onClose={() => { closeDialog(true) }}> <div style={{ textAlign: 'left' }}> Create new Restaurant </div> </DialogTitle>
                }

                {/* Content dialog */}
                <DialogContent dividers>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" style={{marginBottom:'15px'}}>
                        <Tab label="English" {...generateTabProps(0)} />
                        <Tab label="Italian" {...generateTabProps(1)} />
                    </Tabs>
                    {createAndUpdateForm(props, value)}
                </DialogContent>

                {/* Dialog buttons */}
                <DialogActions>
                    <Button variant="outlined" style={{textTransform: 'none', width: '100px'}} onClick={() => { closeDialog(true) }}>Close</Button>
                    <ThemeProvider theme={theme}>
                        <Button variant="contained" color="secondary"
                                style={{backgroundColor: '#3f51b5'}}
                                startIcon={<SaveIcon />}
                                onClick={() => { props.edit ? editRestaurant(props.id) : createRestaurant()}}>
                            SAVE
                        </Button>
                    </ThemeProvider>
                </DialogActions>
            </Dialog>
        </span>
    )
};

export default connect(null, {
    showNotification: showNotificationAction,
})(CreateEditButton);
