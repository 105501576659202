import * as React from "react";
import { EditButton, List, Datagrid, TextField, ImageField, DeleteButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Component to a separator comma between addons
 */
const AddonField = ({ record = {} }) => <span>{typeof record.addons === 'undefined' || record.addons === null ? null : record.addons.map((d,i)=>{
    if (i===0)
        return d.dishAddonName
    else return ', ' + d.dishAddonName
    })}</span>;

/**
 * Component to add currency to the price field
 */
const CurrencyField = ({ record = {} }) => <span style={{ whiteSpace: 'nowrap'}}>{typeof record.dishPrice === 'undefined' ? null : record.dishPrice.toFixed(2) + ' €'}</span>;


/**
 * Edit button custom class
 * @param {*} props Props that contain attributes related to dishes
 */
const MyEditButton = props => {
    return <EditButton {...props} />;
};

/**
 * Dishes list class
 * @param {*} props Props that contain attributes related to dishes
 */
export const Dishes = (props) => (
    <List {...props} bulkActionButtons={false} pagination={false} exporter={false}>
        <Datagrid>
            <ImageField source="dishPicture" label="Picture" className="thumbNailView" sortable={false}/>
            <TextField source="dishName" label="Name"/>
            <TextField source="dishDescription" label="Ingredients"/>
            <AddonField source="addons" label="Add-ons"/>
            <CurrencyField source="dishPrice" label="Price"/>
            <MyEditButton/>
            <DeleteButton confirmTitle={'Delete dish'}
                          confirmContent={'Are you sure you want to delete this dish?'}
                          undoable={false} />
        </Datagrid>
    </List>
);
