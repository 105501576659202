import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction } from 'react-admin';
import CreateEditDialogButton from "./../modals/CreateEditDialogButton"

/**
 * This is the component that allow to show the action button on the table
 *
 */
class ActionsComponent extends Component {
    render() {
        const { record } = this.props;

        if (record) {
            return(
                <span label="Actions">
                <CreateEditDialogButton refresh={this.props.refresh} myPage={this.props.myPage} id={record.id} edit={true}/>
             </span>)
        } else {
            return null;
        }
    }
}

/**
 * Use Redux to set his props in the class
 *
 */
ActionsComponent.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotificationAction,
})(ActionsComponent);
