import * as React from "react";
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/HighlightOff';
import { IconButton, Dialog, DialogContent, DialogActions, Button, Typography, TextField, Switch, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import DataManager from './../util/DataManager'
import { green } from '@material-ui/core/colors';
import { withRouter } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import jwt_decode from "jwt-decode";
import { TabbedForm, FormTab } from 'react-admin';
import { useConfirm } from 'material-ui-confirm';
import {useNotify} from 'react-admin';
import SaveIcon from "@material-ui/icons/Save";

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sameLine: {
        padding: '10px'
    },
    button: {
        marginBottom: '5px',
        marginTop: '1px'
    },
    container: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: theme.spacing(15),
        marginRight: theme.spacing(15),
        borderRadius: '15px 50px',
        border: '1px solid #1E90FF'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    selectedButton: {
        backgroundColor: '#5BB85D',
        borderRadius: 5,
        color: 'white',
        '&:hover': {
            backgroundColor: "#4f9950",
        },
        border: '1px solid #4FAE50',
    },
    notSelectedButton: {
        backgroundColor: '#EEEEEE',
        borderRadius: 5,
        color: 'black',
        '&:hover': {
            backgroundColor: "#c6c6c6",
        },
        border: '1px solid #D9D9D9',
    }
});

/**
 * Green custom switch
 */
const GreenSwitch = withStyles({
    switchBase: {
        color: green[300],
        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[500],
        },
    },
    checked: {},
    track: {},
})(Switch);

/**
 * Custom Dialog Title Component
 * @param {*} props Props that contains styles and state variables
 */
export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    let creationDate = "";
    creationDate += props.menu.menuCreationDate.substring(0,4);
    creationDate += '-' + props.menu.menuCreationDate.substring(5,7);
    creationDate += '-' + props.menu.menuCreationDate.substring(8,10);
    creationDate += ' ' + props.menu.menuCreationDate.substring(11,13);
    creationDate += ':' + props.menu.menuCreationDate.substring(14,16);

    let expirationDate = ""

    if (props.menu.menuExpirationDate !== null) {
        expirationDate += props.menu.menuCreationDate.substring(0,4);
        expirationDate += '-' + props.menu.menuExpirationDate.substring(5,7);
        expirationDate += '-' + props.menu.menuExpirationDate.substring(8,10);
        expirationDate += ' ' + props.menu.menuExpirationDate.substring(11,13);
        expirationDate += ':' + props.menu.menuExpirationDate.substring(14,16);
    }


    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6"> {children}</Typography>
            <p>{`Created: ${creationDate}`}</p>
            {
                props.menu.menuExpirationDate !== null ?
                    <p style={{ marginTop: -8 }}>{`Expired: ${expirationDate}`}</p>
                    : null
            }

            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

/**
 * Edit menu button with modal component
 */
export const EditRestaurantMenu = withRouter(withStyles(styles)((props) => {

    const [menu, setMenu] = React.useState(props.menu);
    const [menuName, setMenuName] = React.useState(props.menu.localizations.en ? props.menu.localizations.en.menuName : "");
    const [menuNameIt, setMenuNameIt] = React.useState(props.menu.localizations.it ? props.menu.localizations.it.menuName : "");
    const [menuStatus, setMenuStatus] = React.useState(props.menu.menuStatus);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dishes, setDishes] = React.useState([]);
    const [selectedDishes, setSelectedDishes] = React.useState([]);

    const dataProvider = useDataProvider();
    const confirm = useConfirm();

    const { classes } = props;
    let decodedJwt = jwt_decode(localStorage.getItem('token'));
    let restaurantId = JSON.stringify(decodedJwt.restaurantId);

    const onClose = () => {
        confirm({ description: "If you close your changes will be lost" })
            .then(() => {
                setMenu(props.menu);
                setMenuName(props.menu.menuName);
                setMenuStatus(props.menu.menuStatus);
                var selectedDishesId = []
                props.menu.dishes.forEach(element => {
                    selectedDishesId.push(element.dishId);
                });
                setSelectedDishes(selectedDishesId);
                setOpenDialog(false);
            })
    };

    /**
     * Retrieve data from api.
     */
    const retrieveData = async () => {
        var response = await DataManager.getWithoutCallback(`dish/restaurant/${restaurantId}`);
        var selectedDishesId = []
        props.menu.dishes.forEach(element => {
            selectedDishesId.push(element.dishId);
        });
        setSelectedDishes(selectedDishesId);
        setDishes(response.data);
        setOpenDialog(true);
    };

    /**
     * Update method.
     */
    const update = async (notify) => {
        if (menuName.length > 45 || menuNameIt.length > 45) {
            notify('Menu name cannot be longer than 45 characters.', 'error');
            return;
        }

        var dateTime = new Date();
        var localization = {};
        if (menuNameIt.trim().length === 0) {
            localization = {
                en: {
                    menuName: menuName
                }
            }
        } else {
            localization = {
                it: {
                    menuName: menuNameIt
                },
                en: {
                    menuName: menuName
                }
            }
        }
        let data = {
            menuName: menuName,
            localizations: localization,
            menuStatus: menuStatus,
            menuExpirationDate: dateTime,
            selectedDishes: selectedDishes
        };

        await DataManager.update(
            'menu',
            props.menu.id,
            data,
            () => {
                setOpenDialog(false);
                notify('Element updated.');
                dataProvider.getOne("menu", { id: props.menu.id })
            }
        );
    };

    /**
     * Renders the form.
     * @param locale the locale.
     * @returns {*}
     */
    const renderForm = (locale) => {
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ paddingBottom: 5 }}>
                        <Typography variant="subtitle1">
                            <b>Menu name</b>
                        </Typography>
                    </div>
                    <div>
                        {locale === 'en' ?
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={menuName}
                                onChange={(event) => { setMenuName(event.target.value) }}
                                error={menuName.trim().length === 0 || menuName.length > 45}
                                helperText={menuName.length > 0 ? "" : "Fill in this field"}
                            />
                        :
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={menuNameIt}
                                onChange={(event) => { setMenuNameIt(event.target.value) }}
                                error={menuNameIt.length > 45}
                            />}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 20 }}>
                    <div style={{ paddingBottom: 5, paddingTop: 6 }}>
                        <Typography variant="subtitle1">
                            <b>Menu status</b>
                        </Typography>
                    </div>
                    <div>
                        <GreenSwitch
                            checked={menuStatus}
                            onChange={() => setMenuStatus(!menuStatus)}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
                    <Typography variant="subtitle1" style={{ paddingBottom: 28 }}>
                        <b>Dishes</b>
                    </Typography>
                    <Grid container item md={12}>
                        {
                            dishes.map((dish, i) => {

                                var width = 4;
                                if (i === dishes.length - 1 && i % 3 === 0) {
                                    width = 12;
                                }
                                if (i === dishes.length - 1 && i % 3 === 1) {
                                    width = 6
                                }
                                if (i === dishes.length - 2 && i % 3 === 0) {
                                    width = 6;
                                }
                                return (
                                    selectedDishes.includes(dish.id) ?
                                        <Grid key={dish} item md={width}>
                                            <Button className={classes.selectedButton} size="large" fullWidth={true}
                                                    onClick={
                                                        () => {
                                                            setSelectedDishes(selectedDishes.filter((id) => {
                                                                return id !== dish.id;
                                                            }))
                                                        }
                                                    }
                                            >
                                                {dish.dishName}
                                            </Button>
                                        </Grid>
                                        :
                                        <Grid key={dish} item md={width}>
                                            <Button className={classes.notSelectedButton} size="large" fullWidth={true}
                                                    onClick={
                                                        () => {
                                                            setSelectedDishes([...selectedDishes, dish.id]);
                                                        }
                                                    }
                                            >
                                                {dish.dishName}
                                            </Button>
                                        </Grid>

                                )
                            })
                        }
                    </Grid>
                </div>
            </div>
        );
    };

    const notify = useNotify();
    return (
        <span>
           <Button
                onClick={() => retrieveData()}
                color="primary"
                startIcon={<EditIcon style={{ color: 'primary' }} > EDIT </EditIcon>}
            > EDIT </Button>
            <Dialog open={openDialog} maxWidth="md" fullWidth={true} onClose={() => onClose()}>
                <DialogTitle id="customized-dialog-title" onClose={() => onClose()} menu={props.menu}>
                    Edit menu
                </DialogTitle>
                <DialogContent dividers>
                    <TabbedForm toolbar={null}>
                            <FormTab label={'English'}>
                                <div style={{width: '100%'}}>{renderForm('en')}</div>
                            </FormTab>
                            <FormTab label={'Italian'}>
                                <div style={{width: '100%'}}>{renderForm('it')}</div>
                            </FormTab>
                    </TabbedForm>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                            onClick={() => { onClose() }}
                            style={{ textTransform: 'none', width: '100px' }}>
                        Close
                    </Button>
                    <Button variant="contained"
                            color="secondary"
                            style={{backgroundColor: '#3f51b5'}}
                            startIcon={<SaveIcon />}
                            onClick={() => update(notify)}
                            disabled={menuName!==undefined && menuName.length > 0 ? false : true}>
                        SAVE
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    )
}));

