import { SimpleForm, TextInput, SaveButton, Toolbar, PasswordInput, Edit, required, email, minLength } from 'react-admin'
import React from 'react'
import { IconButton, Dialog, DialogContent, Typography, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import { CloseConfirmationButton } from "../components/CloseConfirmationButton"
import { useNotify } from 'react-admin';

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

/**
 * Custom Dialog Title Component
 */
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>{children}</Typography>
            {onClose ? (
                <CloseConfirmationButton message="If you close your changes will be lost" destination='/users' withIcon={true} classes={classes} />
            ) : null}
        </MuiDialogTitle>
    );
});

/**
 * User edit form toolbar component
 * @param {*} props Props for toolbar Save Button
 */
const PostCreateToolbar = props => {

    const notify = useNotify();

    return (
        <Toolbar {...props} >
            <div style={{width: '100%'}}>
                <SaveButton
                    label="SAVE"
                    style={{float: 'right'}}
                    submitOnEnter={true}
                    redirect={'/users'}
                    onFailure={() => notify('Email is already taken', 'warning')}
                />
            </div>
        </Toolbar>
    )
};

/**
 * User edit component
 * @param {*} props Props to create user component
 */
export const UserEdit = (props) => {
    const [deleteDialogOpen] = React.useState(true);

    /**
     * format the role array in a string
     * @param {*} value
     */
    const formatRoles = (value) => {
        var roleNames = [];
        if(value !== null) {
            value.map(v => roleNames.push(v.roleName))
            return roleNames.join(', ').replace('_', ' ')
        } else {
            return roleNames;
        }
    }

    /**
     * password matching validation
     * @param {*} value
     * @param {*} allValues
     */
    const confirmPassValidation = (value, allValues) => {
        if (value !== allValues.password) {
            return 'Does not match'
        }
        return null;
    };

    /**
     * validations
     */
    const emailValidation = [required(), email('The email must be a valid email address')];
    const confirmPasswordValidation = [required(), minLength(6, 'The password must be at least 6 characters'), confirmPassValidation];
    const passwordValidation = [required(), minLength(6, 'The password must be at least 6 characters')];

    return (
        <React.Fragment>
            <Dialog
                open={deleteDialogOpen}
                id='update-dialog'
            >
                <DialogTitle id="customized-dialog-title" onClose={() => window.history.back()}>
                    User edit
                </DialogTitle>
                <DialogContent dividers>
                    <Edit {...props} undoable={false}>
                        <SimpleForm toolbar={<PostCreateToolbar {...props} />}>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '45%' }}>
                                        <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                                            <Box fontWeight="fontWeightBold" m={1}>
                                                Name
                                            </Box>
                                        </Typography>
                                        <TextInput source="name" style={{ width: '100%' }} disabled />
                                    </div>
                                    <div style={{ width: '45%', paddingLeft: '10%' }}>
                                        <Typography style={{ textAlign: 'left' }}>
                                            <Box fontWeight="fontWeightBold" m={1}>
                                                Last name
                                            </Box>
                                        </Typography>
                                        <TextInput source="last_name" style={{ width: '100%' }} disabled />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '100%' }}>
                                        <Typography style={{ textAlign: 'left' }}>
                                            <Box fontWeight="fontWeightBold" m={1}>
                                                Email
                                            </Box>
                                        </Typography>
                                        <TextInput source="email" style={{ width: '100%' }} validate={emailValidation} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '100%' }}>
                                        <Typography style={{ textAlign: 'left' }}>
                                            <Box fontWeight="fontWeightBold" m={1}>
                                                Role
                                            </Box>
                                            <TextInput source="roles" style={{ width: '100%' }} format={formatRoles} disabled />
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '45%' }}>
                                        <Typography style={{ textAlign: 'left' }}>
                                            <Box fontWeight="fontWeightBold" m={1}>
                                                Password
                                            </Box>
                                        </Typography>
                                        <PasswordInput source="password" style={{ width: '100%' }} validate={passwordValidation} />
                                    </div>
                                    <div style={{ width: '45%', paddingLeft: '10%' }}>
                                        <Typography style={{ textAlign: 'left' }}>
                                            <Box fontWeight="fontWeightBold" m={1}>
                                                Confirm new password
                                            </Box>
                                        </Typography>
                                        <PasswordInput source="confirm_password" style={{ width: '100%' }} validate={confirmPasswordValidation} />
                                    </div>
                                </div>
                            </div>
                        </SimpleForm>
                    </Edit>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
