import * as React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router-dom';
import Config from '../util/Config';
import jwt_decode from "jwt-decode";

/**
 * Menu component of menubar
 * @param onMenuClick click handler
 * @param logout logout handler
 * @returns {menu}
 * @constructor
 */
const Menu = ({ onMenuClick, logout }) => {
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const [dishSubOpen, setDishSubOpen] = React.useState(false);
    const [addonSubOpen, setAddonSubOpen] = React.useState(false);

    /**
     * Function to handle dish menu element click.
     */
    const handleClickDish = () => {
        setDishSubOpen(!dishSubOpen);
    };

    /**
     * Function to handle dish  menu element click.
     */
    const handleClickAddon = () => {
        setAddonSubOpen(!addonSubOpen);
    };

    /**
     * Function to check if element is restaurant.
     */
    const isAdminResources = () => {
        let decodedJwt = jwt_decode(localStorage.getItem('token'));
        const roles = JSON.stringify(decodedJwt.roles);
        let roleIds = [];
        if (JSON.parse(roles) == null) {
            return true;
        }
        JSON.parse(roles).forEach(function(entry) {
            roleIds.push(entry.id)
        });
        return roleIds.includes(Config.ADMIN_ROLE);

    };

    return (
        <div>
            <List component="nav" >
                <List component="div" disablePadding>
                    {resources.map(resource => {
                        if (resource.name.indexOf('addon') === -1 && resource.name.indexOf('dish') === -1) {
                            if (resource.name !== 'profile') {
                                return (
                                    <MenuItemLink
                                        key={resource.name}
                                        to={`/${resource.name}`}
                                        primaryText={
                                            (resource.options && resource.options.label) ||
                                            resource.name
                                        }
                                        leftIcon={
                                            resource.icon ? <resource.icon/> : <DefaultIcon/>
                                        }
                                        onClick={onMenuClick}
                                        sidebarIsOpen={open}
                                    />
                                )
                            }
                        }
                    })}
                </List>
                { isAdminResources() ? <ListItem button onClick={handleClickDish}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dish configuration" />
                        {dishSubOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem> : null }
                { isAdminResources() ? <Collapse in={dishSubOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {resources.map(resource => {
                            if (resource.name.indexOf('dish') !== -1) {
                                return (
                                    <MenuItemLink
                                        key={resource.name}
                                        to={`/${resource.name}`}
                                        primaryText={
                                            (resource.options && resource.options.label) ||
                                            resource.name
                                        }
                                        leftIcon={
                                            resource.icon ? <resource.icon /> : <DefaultIcon />
                                        }
                                        onClick={onMenuClick}
                                        sidebarIsOpen={open}
                                    />
                                )
                            }
                        })}
                    </List>
                </Collapse> : null}
                { isAdminResources() ? <ListItem button onClick={handleClickAddon}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Add-on configuration" />
                        {addonSubOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem> : null }
                { isAdminResources() ? <Collapse in={addonSubOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {resources.map(resource => {
                            if (resource.name.indexOf('addon') !== -1) {
                                return (
                                    <MenuItemLink
                                        key={resource.name}
                                        to={`/${resource.name}`}
                                        primaryText={
                                            (resource.options && resource.options.label) ||
                                            resource.name
                                        }
                                        leftIcon={
                                            resource.icon ? <resource.icon /> : <DefaultIcon />
                                        }
                                        onClick={onMenuClick}
                                        sidebarIsOpen={open}
                                    />
                                )
                            }
                        })}
                    </List>
                </Collapse> : null}
            </List>
        </div>
    );
};

export default withRouter(Menu);
