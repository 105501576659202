import axios from 'axios';

/**
 * This is a class that allow to invoke services
 * 
 */
export default class DataManager {
  static create(resource, data, callbackFun) {
    axios.put(process.env.REACT_APP_API_URL + '/' + resource, data, {
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    })
      .then(res => {
        callbackFun();
      }).catch(err => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem('token');
          localStorage.setItem('unAuthError', 'true');
          window.location.href = '/login'
        }
      })
  }

  static createPost(resource, data, callbackFun) {
    axios.post(process.env.REACT_APP_API_URL + '/' + resource, data, {
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    })
      .then(res => {
        callbackFun();
      }).catch(err => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem('token');
          localStorage.setItem('unAuthError', 'true');
          window.location.href = '/login'
        }
      })
  }

  static update(resource, id, data, callbackFun) {
    axios.put(process.env.REACT_APP_API_URL + '/' + resource + '/' + id, data, {
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    })
      .then(res => {
        callbackFun();
      }).catch(err => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem('token');
          localStorage.setItem('unAuthError', 'true');
          window.location.href = '/login'
        }
      })
  }

  static get(resource, callbackFun) {
    axios.get(process.env.REACT_APP_API_URL + '/' + resource, {
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    })
      .then(res => {
        callbackFun(res.data);
      }).catch(err => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem('token');
          localStorage.setItem('unAuthError', 'true');
          window.location.href = '/login'
        }
      })
  }

  static async getWithoutCallback(resource) {
    return axios.get(process.env.REACT_APP_API_URL + '/' + resource, {
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    })
      .then(res => {
        return (res.data);
      }).catch(err => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem('token');
          localStorage.setItem('unAuthError', 'true');
          window.location.href = '/login'
        }
      })
  }

  static checkStripeUser(resource, callbackFun) {
    axios.get(process.env.REACT_APP_API_URL + '/' + resource, {
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    })
      .then(res => {
        callbackFun(res);
      }).catch(err => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem('token');
          localStorage.setItem('unAuthError', 'true');
          window.location.href = '/login'
        }
      })
  }

  static connectStripeAccount(resource, data) {
    axios.post(process.env.REACT_APP_API_URL + '/' + resource, data, {
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    }).then((res) => window.location.href = res.data.url)
  }
}