import { TabbedForm, FormTab, TextInput, NumberInput, SaveButton, Toolbar, Edit, required, number, minValue} from 'react-admin'
import React from 'react'
import { IconButton, Dialog, DialogContent, Typography, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import {CloseConfirmationButton} from "../components/CloseConfirmationButton"

const noLessThanZero = [required(), number(), minValue(0,"negative values are not allowed")];

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

/**
 * Custom Dialog Title Component
 */
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>{children}</Typography>
            {onClose ? (
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/dish-category' withIcon={true} classes={classes}/>
            ) : null}
        </MuiDialogTitle>
    );
});

/**
 * Dish category edit form toolbar component
 * @param {*} props Props for toolbar Save Button
 */
const PostCreateToolbar = props => (
    <Toolbar {...props} >
        <div style={{flex: '0 0 auto', display: 'flex', padding: '8px', width: '100%', justifyContent: 'flex-end'}}
          {...props}>
            <SaveButton
              {...props}
                label="SAVE"
                submitOnEnter={true}
            />
        </div>
    </Toolbar>
);

/**
 * Dish category edit localized form.
 * @param props the props.
 * @returns {React.Fragment}
 */
const DishCategoryEditLocalizedForm = props => (
    <React.Fragment>
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '45%' }}>
                    <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                        <Box fontWeight="fontWeightBold" m={1}>
                            Name
                        </Box>
                    </Typography>
                    <TextInput label={'Category name'} source={props.categoryNameSource} style={{ width: '100%' }} validate={props.required ? required() : []}/>
                </div>
                <div style={{ width: '45%', paddingLeft: '10%' }}>
                    <Typography style={{ textAlign: 'left' }}>
                        <Box fontWeight="fontWeightBold" m={1}>
                            Priority
                        </Box>
                    </Typography>
                    <NumberInput source="dishCategoryPriority" inputProps={{ min: 0 }} style={{ width: '100%' }} validate={noLessThanZero}/>
                </div>
            </div>
        </div>
    </React.Fragment>
);

/**
 * Dish category edit component
 * @param {*} props Props to create dish category component
 */
export const DishCategoryEdit = (props) => {
    console.log(props);
    const [deleteDialogOpen] = React.useState(true);

    return (
        <React.Fragment>
            <Dialog
                open={deleteDialogOpen}
                id='update-dialog'
            >
                <DialogTitle id="customized-dialog-title" onClose={() => props.history.push(`/dish-category`)}>
                    Edit category
                </DialogTitle>
                <DialogContent dividers>
                    <Edit undoable={false} {...props}>
                        <TabbedForm toolbar={<PostCreateToolbar {...props} />}>
                            <FormTab label={'English'}>
                                <DishCategoryEditLocalizedForm categoryNameSource="localizations.en.categoryName" required/>
                            </FormTab>
                            <FormTab label={'Italian'}>
                                <DishCategoryEditLocalizedForm categoryNameSource="localizations.it.categoryName"/>
                            </FormTab>
                        </TabbedForm>
                    </Edit>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
