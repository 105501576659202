import { Edit, FormTab, TabbedForm, TextInput, SaveButton, Toolbar, SelectInput, required, number, minValue, NumberInput } from 'react-admin'
import React, { Component } from 'react'
import { IconButton, Dialog, DialogContent, Button, Typography, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import {CloseConfirmationButton} from "../components/CloseConfirmationButton"
/**
 * price validations
 */
const noLessThanZero = [required(), number(), minValue(0, "negative values are not allowed")];

/**
 * User create form toolbar component
 * @param {*} props Props for toolbar Save Button
 */
const AddonEditToolbar = props => (
  <Toolbar {...props}  >
    <div style={{flex: '0 0 auto', display: 'flex', padding: '8px', width: '100%', justifyContent: 'flex-end'}}
         {...props}>
      <CloseConfirmationButton message="If you close your changes will be lost" destination='/addon' />
      <SaveButton {...props}
        label="SAVE"
        submitOnEnter={false}
        style={{ marginLeft: '8px' }}
      />
    </div>
  </Toolbar>
);

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/**
* Custom Dialog Title Component
*/
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textAlign: 'left', fontWeight: "fontWeightBold" }}>{children}</Typography>
      {onClose ? (
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/addon' withIcon={true} classes={classes}/>
      ) : null}
    </MuiDialogTitle>
  );
});

/**
 * Addon edit class
 */
class AddonEdit extends Component {

  constructor(props) {
    super(props);
    this.state = { categories: [], defaultCategory: undefined };
  }

  async componentDidMount() {
    let decodedJwt = jwt_decode(localStorage.getItem('token'));
    let restaurantId = JSON.stringify(decodedJwt.restaurantId);
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/addon-category/restaurant/${restaurantId}`,{
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    });
    var categories = [];
    response.data.data.forEach(obj => {
      categories.push({ id: obj.id, name: obj.categoryName })
    });
    this.setState({categories: categories})
    const responseCategory = await axios.get(`${process.env.REACT_APP_API_URL}/addon/${restaurantId}/${this.props.id}`,{
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    })
    this.setState({ defaultCategory: responseCategory.data.category[0].id });
  }

  /**
   * Addon creation localized form
   * @param {*} props
   * @returns {React.Fragment}
   */
  AddonEditLocalizedForm = props => (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '100%' }}>
            <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
              <Box fontWeight="fontWeightBold" m={1}>
                Name
                        </Box>
            </Typography>
            <TextInput label="Name" source={props.addonNameSource} style={{ width: '100%' }} validate={props.required ? required() : []} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '50%' }}>
            <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
              <Box fontWeight="fontWeightBold" m={1}>
                Price
                        </Box>
            </Typography>
            <NumberInput source="addonPrice" inputProps={{ min: 0 }} style={{ width: '100%' }} validate={noLessThanZero} step={0.5} />
          </div>
          <div style={{ width: '50%', paddingLeft: '30%' }}>
            <Typography style={{ textAlign: 'left' }}>
              <Box fontWeight="fontWeightBold" m={1}>
                Category
                        </Box>
            </Typography>
            <SelectInput source="categoryToSet" validate={required()} choices={this.state.categories} defaultValue={this.state.defaultCategory} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )

  /**
   * method that go back to addon datatable
   * @param {*} props
   */
  goToDatatable = props => {
    this.props.history.push("/addon")
  }

  render() {
    return (
      <Dialog
        open={true}
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => this.goToDatatable()}>
          Edit add-on
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ width: '100%' }}>
            <Edit undoable={false} {...this.props}>
              <TabbedForm toolbar={<AddonEditToolbar backFun={this.goToDatatable} />} redirect={`/addon`}>
                <FormTab label="English">
                  <this.AddonEditLocalizedForm addonNameSource="localizations.en.addonName" required />
                </FormTab>
                <FormTab label="Italian">
                  <this.AddonEditLocalizedForm addonNameSource="localizations.it.addonName" />
                </FormTab>
              </TabbedForm>
            </Edit>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

export default AddonEdit;


