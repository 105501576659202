import { Create, TabbedForm, FormTab, TextInput, NumberInput, SelectArrayInput, SaveButton, Toolbar, SelectInput, required, number, minValue, FormDataConsumer } from 'react-admin';
import React, { Component } from 'react';
import { Fab, IconButton, Dialog, DialogContent, Button, Typography, Box, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import Image from './../components/Image';
import FileManager from './../util/FileManager';
import InputAdornment from '@material-ui/core/InputAdornment';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import axios from 'axios';
import { Fragment } from 'react';
import { useForm } from 'react-final-form';
import {useNotify} from 'react-admin';
import jwt_decode from "jwt-decode";
import {CloseConfirmationButton} from "../components/CloseConfirmationButton";
import Config from "../util/Config";

const MAX_TAGS = 4

/**
 * Dish create form toolbar component
 * @param {*} props Props for toolbar Save Button
 */
const DishCreateToolbar = props => (
  <Toolbar {...props}  >
    <div style={{flex: '0 0 auto', display: 'flex', padding: '8px', width: '100%', justifyContent: 'flex-end'}}
         {...props}>
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/dish' />
        <SaveButton
          {...props}
          label="SAVE"
          style={{ marginLeft: '8px', width: '20%' }}
          redirect={'/dish'}
        />
    </div>
  </Toolbar>
);

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/**
* Custom Dialog Title Component
*/
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textAlign: 'left', fontWeight: "fontWeightBold" }}>{children}</Typography>
      {onClose ? (
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/dish' withIcon={true} classes={classes}/>
      ) : null}
    </MuiDialogTitle>
  );
});

/**
 * "browse" field used by the button for the loading of images
 * @param {*} props Props that contain id of dish
 */
const UploadButton = withStyles(styles)((props) =>{
    return(
        <label htmlFor={props.id}>
            <input style={{ display: 'none' }} id={props.id} name={props.id} type="file" accept="image/*" onChange={(e) => props.onChange(e)}/>
            <Fab color="secondary" size="small" component="span" aria-label="add" variant="extended" style={{maxWidth: '100px', maxHeight: '25px', minWidth: '100px', minHeight: '25px'}}>
                <h5>Browse</h5>     <FolderOpenIcon/>
            </Fab>
        </label>
    )
});

/**
 * Adapting upload image to react-admin
 * @param {*} props 
 */
const ImgUploader = ({ formData, ...rest }) => {
    const form = useForm();
    const [DishPicture, setDishPicture] = React.useState("")
    const setPicture = (val) => {
        setDishPicture(val);
        form.change("dishPicture", val);
    }
    const notify = useNotify()

    // Button that upload images
    const loadDishPicture = async (e) => {
      if (e.target.files[0]) {
        if (!e.target.files[0].type.includes("image")) {
          notify('You can load only images', 'error');
          return;
        }
        if (e.target.files[0].size > 1000000) {
          notify('The picture may not be greater than 1000 kilobytes','error');
          return;
        }

      }
      FileManager.UploadImage("restaurants/image", e.target.files[0], setPicture);
    }

    return (
        <Fragment>
          <div>
              <Box flex={1} mr="1em">
                  <div>
                      <div>
                          <h5>Picture</h5>
                          <UploadButton id="dishImage" onChange={loadDishPicture}/>
                      </div>
                  </div>
              </Box>
              <Box flex={1} mr="1em">
                  {DishPicture !== "" ? <Image src={DishPicture} width={200} height={200} mode='fit' /> : null}  
              </Box>
          </div>
          <div style={{ display: 'none'}}>
            <TextInput source="dishPicture" style={{ display: 'none' }} />
          </div>
        </Fragment>
    );
};

/**
 * Custom multiple buttons secection for react-admin
 * @param {*} props 
 */
function AddonsButtons (props) {
  const form = useForm();
  const [selectedAddons, setSelectedAddons] = React.useState(props.selectedAddons);

  return (
      <Fragment>
        <div style={{ display: 'flex', flexDirection: 'column', width: '45%', paddingLeft: '10%' }}>
            <Typography variant="h7" style={{ margin: 8 }}>
                <b>Add-ons</b>
            </Typography>
            <Grid container item md={12} style={{ marginTop: 12 }}>
                {
                    props.addons.map((addon, i) => {
                        return (
                            selectedAddons.get(addon.id) ?
                                <Grid item md={6}>
                                    <Button style={{backgroundColor: '#5BB85D', borderRadius: 5, color: 'white', '&:hover': {backgroundColor: "#4f9950"}, border: '1px solid #4FAE50'}} size="medium" fullWidth={true}
                                        onClick={
                                            () => {
                                                setSelectedAddons(
                                                    selectedAddons.set(addon.id, false)
                                                );
                                                let selectedAddonsArray = [];
                                                selectedAddons.forEach((v, k)=>{
                                                    if (v)
                                                        selectedAddonsArray.push(k);
                                                });
                                                form.change("dishAddons", selectedAddonsArray);
                                            }
                                        }
                                    >
                                        {addon.name}
                                    </Button>
                                </Grid>
                                :
                                <Grid item md={6}>
                                    <Button style={{backgroundColor: 'white', borderRadius: 5, color: 'black', '&:hover': {backgroundColor: "#c6c6c6"}, border: '1px solid #D9D9D9'}} size="medium" fullWidth={true}
                                        onClick={
                                            () => {
                                                setSelectedAddons(
                                                  selectedAddons.set(addon.id, true)
                                                );
                                                let selectedAddonsArray = [];
                                                selectedAddons.forEach((v, k)=>{
                                                    if (v)
                                                        selectedAddonsArray.push(k);
                                                });
                                                form.change("dishAddons", selectedAddonsArray);
                                            }
                                        }
                                    >
                                        {addon.name}
                                    </Button>
                                </Grid>
                        )
                    })
                }
            </Grid>
        </div>
        <div style={{ display: 'none'}}>
          <SelectArrayInput source="dishAddons" style={{ display: 'none' }} />
        </div>
      </Fragment>
  );
};

/**
 * Custom multiple buttons secection for react-admin
 * @param {*} props 
 */
function TagsButtons (props) {
  const form = useForm();
  const [selectedTags, setSelectedTags] = React.useState(props.selectedTags);
  const [showError, setShowError] = React.useState(false);

  const displayError = () => {
    setShowError(true);
    setTimeout(() => setShowError(false), 3000)
  }


  return (
      <Fragment>
        <div style={{ display: 'flex', flexDirection: 'column', width: '45%', paddingLeft: '10%' }}>
            <Typography variant="h7" style={{ margin: 8 }}>
                <b>Tags</b>
            </Typography>
            <Grid container item md={12} style={{ marginTop: 12 }}>
                {
                    props.tags.map((tag, i) => {
                        return (
                            tag.id !== Config.ALL_TAGS_ID ?
                            selectedTags.get(tag.id) ?
                                <Grid item md={6}>
                                    <Button style={{backgroundColor: '#5BB85D', borderRadius: 5, color: 'white', '&:hover': {backgroundColor: "#4f9950"}, border: '1px solid #4FAE50'}} size="medium" fullWidth={true}
                                        onClick={
                                            () => {
                                                setSelectedTags(
                                                    selectedTags.set(tag.id, false)
                                                );
                                                let selectedTagsArray = [];
                                                selectedTags.forEach((v, k)=>{
                                                    if (v)
                                                        selectedTagsArray.push(k);
                                                });
                                                form.change("dishTags", selectedTagsArray);
                                            }
                                        }
                                    >
                                        {tag.name}
                                    </Button>
                                </Grid>
                                :
                                <Grid item md={6}>
                                    <Button style={{backgroundColor: 'white', borderRadius: 5, color: 'black', '&:hover': {backgroundColor: "#c6c6c6"}, border: '1px solid #D9D9D9'}} size="medium" fullWidth={true}
                                        onClick={
                                            () => {
                                                var count = 0 
                                                selectedTags.forEach((v, k)=>{
                                                  if (v)
                                                      count++;
                                                });
                                                if (count >= MAX_TAGS) {
                                                  displayError()
                                                  return;
                                                }
                                                setSelectedTags(
                                                    selectedTags.set(tag.id, true)
                                                );
                                                let selectedTagsArray = [];
                                                selectedTags.forEach((v, k)=>{
                                                    if (v)
                                                        selectedTagsArray.push(k);
                                                });
                                                form.change("dishTags", selectedTagsArray);
                                            }
                                        }
                                    >
                                        {tag.name}
                                    </Button>
                                </Grid> : null
                        )
                    })
                }
            </Grid>
            <div style={{ borderRadius: 5, marginTop: 10, height: '50%', whiteSpace: 'pre-wrap', backgroundColor: props.errorColor, display: props.display }}>
              <div style={{paddingLeft: 10, marginTop: 5, marginBottom: 5}}>{props.errorMessage}</div>
            </div>
            { showError ? 
              <div style={{ borderRadius: 5, marginTop: 10, height: '50%', whiteSpace: 'pre-wrap', backgroundColor: '#F2DEDF' }}>
                <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 5, marginBottom: 5}}>Maximum {MAX_TAGS} tags are allowed to select </div>
              </div> 
              : null}
        </div>

        <div style={{ display: 'none'}}>
          <SelectArrayInput source="dishTags" style={{ display: 'none' }} />
        </div>
      </Fragment>
  );
};

  /**
   * Validate max value function
   * @param {*} value 
   * @param {*} allValues 
   */
  const tagsValidation = (value, allValues) => {
    if (Array.isArray(allValues.dishTags)) {
      if (allValues.dishTags.length === 0) {
        return 'error';
      }
    } else {
      return 'error'
    }

  return [];
};

const noLessThanZero = [required(), number(), minValue(0,"negative values are not allowed")];

const validateTags = [tagsValidation]

const DishCreateLocalizedForm = props => (
    <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '45%' }}>
                <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                    <Box fontWeight="fontWeightBold" m={1}>
                        Name
                    </Box>
                </Typography>
                <TextInput source={props.dishNameSource} label="Name" style={{ width: '100%' }} validate={props.required ? required() : []}/>
            </div>
            <FormDataConsumer>
                {formDataProps => (<AddonsButtons selectedAddons={props.data.selectedAddons} addons={props.data.addons}
                                                  {...formDataProps}/>)}
            </FormDataConsumer>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '45%' }}>
                <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                    <Box fontWeight="fontWeightBold" m={1}>
                        Ingredients
                    </Box>
                </Typography>
                <TextInput multiline source={props.dishDescriptionSource} label="Ingredients" style={{ width: '100%' }} validate={props.required ? required() : []}/>
            </div>
            <FormDataConsumer>
                {formDataProps => (<TagsButtons selectedTags={props.data.selectedTags} tags={props.data.tags} errorColor={props.data.errorColor} errorMessage={props.data.errorMessage} display={props.data.display} {...formDataProps}/>)}
            </FormDataConsumer>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '45%' }}>
                <Typography style={{ textAlign: 'left' }}>
                    <Box fontWeight="fontWeightBold" m={1}>
                        Dish price (€)
                    </Box>
                </Typography>
                <NumberInput source="dishPrice" inputProps={{ min: 0 }} style={{ width: '100%' }} validate={noLessThanZero} step={0.5}
                             InputProps={{ endAdornment: <InputAdornment position="end"> € </InputAdornment> }}/>
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '45%' }}>
                <Typography style={{ textAlign: 'left' }}>
                    <Box fontWeight="fontWeightBold" m={1}>
                        Category
                    </Box>
                </Typography>
                <SelectInput source="dishCategory" style={{ width: '100%' }} validate={required()} choices={props.data.categories}/>
            </div>
        </div>
        <FormDataConsumer>
            {formDataProps => (<ImgUploader {...formDataProps}/>)}
        </FormDataConsumer>
        <div style={{ display: 'none'}}>
            <NumberInput source="restaurantId" defaultValue={JSON.stringify(jwt_decode(localStorage.getItem('token')).restaurantId)} style={{ display: 'none' }} />
        </div>
        <div style={{ display: 'none'}}>
            <NumberInput source='tagsValidation' validate={validateTags} style={{ display: 'none' }} />
        </div>
    </div>
)

/**
 * Dish create class
 */
class DishCreate extends Component {

    constructor(props) {
      super(props);
      this.state = {categories: [], DishPicture: '', disable: true, addons: [], selectedAddons: new Map(),
          selectedTags: new Map(), tags: [], errorMessage: '', errorColor: '', display: 'inline'};
    }

    /**
     * function to set disable value
     * @param {*} val 
     */
    setDisable (val) {
      this.setState({disable: val});
    }

    /**
     * function to validate fields to enable create button
     * @param {*} values 
     */
    validateFields = (values) => {
        if (typeof values.dishTags === 'undefined') {
          this.setState({display: 'inline', errorColor: 'rgba(217,237,246, 1)', errorMessage: 'Minimum 1 tag to select. Maximum ' + MAX_TAGS + ' tags to select.'})
        } else {
          if (values.dishTags.length === 0) {
            this.setState({display: 'inline', errorColor: 'rgba(217,237,246, 1)', errorMessage: 'Minimum 1 tag to select. Maximum ' + MAX_TAGS + ' tags to select.'})
          } else {
            this.setState({display: 'none'})
          } 
        }
    };

    /**
     * function that triggers when the component is mounted
     */
    async componentDidMount () {
      let decodedJwt = jwt_decode(localStorage.getItem('token'));
      let restaurantId = JSON.stringify(decodedJwt.restaurantId);
      const responseCategories = await axios.get(process.env.REACT_APP_API_URL + '/dish-category/restaurant/' + restaurantId,{
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      });
      var categories = [];
      responseCategories.data.data.forEach(obj => {
        categories.push({id: obj.id, name: obj.dishCategoryName});
      });
      this.setState({categories: categories});

      const responseAddons = await axios.get(process.env.REACT_APP_API_URL + '/addon/' + restaurantId,{
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      });
      var addons = [];
      responseAddons.data.data.forEach(obj => {
        addons.push({id: obj.id, name: obj.addonName})
      });
      this.setState({addons: addons});

      const responseTags = await axios.get(process.env.REACT_APP_API_URL + '/dish/tags',{
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      });
      var tags = [];
      responseTags.data.forEach(obj => {
        tags.push({id: obj.id, name: obj.name})
      });
      this.setState({tags: tags});

    }

    render() {

      return (
        <div>
          <Dialog
            open={true}
            fullWidth={true}
          >
            <DialogTitle id="customized-dialog-title" onClose={() => this.props.history.push("/dish")}>
              Create new dish
            </DialogTitle>
            <DialogContent dividers>
              <div style={{ width: '100%' }}>
                <Create {...this.props} >
                  <TabbedForm toolbar={<DishCreateToolbar history={this.props.history}/>} validate={this.validateFields}>
                      <FormTab label="English">
                          <DishCreateLocalizedForm data={this.state} dishNameSource="localizations.en.name" dishDescriptionSource="localizations.en.description" required/>
                      </FormTab>
                      <FormTab label="Italian">
                          <DishCreateLocalizedForm data={this.state} dishNameSource="localizations.it.name" dishDescriptionSource="localizations.it.description"/>
                      </FormTab>
                  </TabbedForm>
                </Create>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )
    }
}

export default DishCreate;