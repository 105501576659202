import {
    Edit,
    TabbedForm,
    TextInput,
    SaveButton,
    Toolbar,
    NumberInput,
    BooleanInput,
    required,
    minValue,
    FormTab
} from 'react-admin'
import React from 'react'
import { IconButton, Dialog, DialogContent, Typography, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import Config from './../util/Config'
import jwt_decode from "jwt-decode";
import {CloseConfirmationButton} from "../components/CloseConfirmationButton"

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

/**
 * Custom Dialog Title Component
 */
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>{children}</Typography>
            {onClose ? (
              <CloseConfirmationButton message="If you close your changes will be lost" destination='/addon-category' withIcon={true} classes={classes}/>
            ) : null}
        </MuiDialogTitle>
    );
});



/**
 * User edit component
 * @param {*} props Props to create user component
 */
export const AddonCategoryEdit = (props) => {
    const [deleteDialogOpen] = React.useState(true);
    const [disableMax, setDisableMax] = React.useState(false);
    const [checkedUn, setCheckedUn] = React.useState(false);
    const [minVal, setMinVal] = React.useState(0);
    const [firstTime, setFirstTime] = React.useState(true);
    const [meaning, setMeaning] = React.useState("Not all fields filled");

    /**
     * Validate max value function
     * @param {*} value
     * @param {*} allValues
     */
    const mandatoryValidationMax = (value, allValues) => {
      if (allValues.isMandatory === true) {
        if (allValues.isUnlimited !== true)
          if(value === null || value === ''){
            return 'This value is mandatory'
          }
      }

      if((value !== null) && (allValues.minAddons !== null)){
        if (allValues.isUnlimited !== true) {
          if (value < allValues.minAddons) {
            return 'Value must be greater than or equal to ' + allValues.minAddons
          }
        }
      }

      return null;
    };

    /**
     * validation arrays
     */
    const validateMinimum = [minValue(0,"negative values are not allowed"), required()];
    let validateMaximum = [mandatoryValidationMax];
    if (!checkedUn) {
      validateMaximum.push(required())
    }
    const validateName = [required()]

    /**
     * User edit form toolbar component
     * @param {*} props Props for toolbar Save Button
     */

    const AddonCategoryCreateToolbar = props => (
        <Toolbar {...props} >
          <div style={{flex: '0 0 auto', display: 'flex', padding: '8px', width: '100%', justifyContent: 'flex-end'}}
               {...props}>
              {checkedUn ?
                  <SaveButton
                      {...props}
                      label="SAVE"
                      submitOnEnter={false}
                  />
                  :
                  <SaveButton
                      {...props}
                      label="SAVE"
                      submitOnEnter={true}
                  />
              }
          </div>

        </Toolbar>
    );

    const validateFields = (values) => {
        setMinVal(values.minAddons)
        console.log(values.minAddons)
        if (firstTime && values.maxAddons === Config.MAX_VAL){
            setDisableMax(true);
            setCheckedUn(true);
            setFirstTime(false)
        } else {
            setFirstTime(false)
        }

        if (values.localizations && values.localizations.en && values.localizations.en.categoryName != null ) {
          if (!values.isMandatory) {
            if(values.minAddons != null){
              if (!values.isUnlimited){
                if (values.maxAddons != null) {
                  if (values.minAddons<=values.maxAddons){
                    setMeaning("Can pick from " + values.minAddons + " to " + values.maxAddons + " addons.");
                    return;
                  } else {
                    setMeaning("Minimum value can not be bigger than maximum")
                    return;
                  }
                }
              } else {
                setMeaning("Can pick from " + values.minAddons + " to unlimited addons.");
                return;
              }
            } else {
              return;
            }
          }
        }

        if (values.localizations && values.localizations.en && values.localizations.en.categoryName != null && values.minAddons != null && values.maxAddons != null) {
          if (values.isUnlimited){
            setMeaning("Can pick from " + values.minAddons + " to unlimited addons.");
            return;
          }
          if (!values.isUnlimited){
            if (values.minAddons <= values.maxAddons){
              setMeaning("Can pick from " + values.minAddons + " to " + values.maxAddons + " addons.");
              return;
            } else {
              setMeaning("Minimum value can not be bigger than maximum")
              return;
            }
          }
        } else if (values.localizations && values.localizations.en && values.localizations.en.categoryName != null && values.minAddons != null && values.isUnlimited) {
          setMeaning("Can pick from " + values.minAddons + " to unlimited addons.");
          return;
        }

        setMeaning("Not all fields filled")

    };

  const handleChange = () => {

    if(checkedUn === true){
      setCheckedUn(false)
      setDisableMax(false)
    } else {
      setCheckedUn(true)
      setDisableMax(true)

    }
  }

  const AddonCategoryEditLocalizedForm = props => (
      <React.Fragment>
          <div style={{ width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '100%' }}>
                      <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                          <Box fontWeight="fontWeightBold" m={1}>
                              Category
                          </Box>
                      </Typography>
                      <TextInput label="Category Name"
                                 source={props.categoryNameSource}
                                 validate={props.required ? validateName : []}
                                 style={{ width: '100%' }}
                      />
                  </div>
              </div>
          </div>
        <div style={{ display: 'none'}}>
            <NumberInput source="restaurantId" defaultValue = {JSON.stringify(jwt_decode(localStorage.getItem('token')).restaurantId)} />
        </div>
      </React.Fragment>
  );

  const renderOtherFields = () => {
    return (
        <div style={{width: '100%'}}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '45%'}}>
                      <Typography style={{ textAlign: 'left' }}>
                          <Box fontWeight="fontWeightBold" m={1}>
                              Minimum add-ons
                          </Box>
                      </Typography>
                      <NumberInput source="minAddons"  inputProps={{ min: 0 }} style={{ width: '100%' }} validate={validateMinimum}/>
                  </div>
                  <div style={{ width: '45%', paddingLeft: '10%' }}>
                      <Typography style={{ textAlign: 'left' }}>
                          <Box fontWeight="fontWeightBold" m={1}>
                              Maximum add-ons
                          </Box>
                      </Typography>
                      <NumberInput source="maxAddons" inputProps={{ min: 0 }} style={{ width: '100%' }} disabled = {disableMax} validate={validateMaximum}/>
                  </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '45%'}}>
                      <BooleanInput source="isMandatory" label="Is mandatory" style={{ width: '100%' }}/>
                  </div>
                  <div style={{ width: '45%', paddingLeft: '10%' }}>
                      <BooleanInput source="isUnlimited" label="Unlimited" style={{ width: '100%' }} onChange={handleChange}/>
                  </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '100%' }}>
                      <Typography style={{ textAlign: 'left' }}>
                          <Box>
                              <span  style={{fontWeight: 'bold'}}>Meaning: </span>{meaning}
                          </Box>
                      </Typography>
                  </div>
              </div>
        </div>
    )
  }

    return (
        <React.Fragment>
            <Dialog
                open={deleteDialogOpen}
                id='update-dialog'
            >
                <DialogTitle id="customized-dialog-title" onClose={() => props.history.push('/addon-category')}>
                    Add-on category edit
                </DialogTitle>
                <DialogContent dividers>
                    <Edit undoable={false} {...props} >
                        <TabbedForm validate={validateFields} toolbar={<AddonCategoryCreateToolbar {...props} />}>
                            <FormTab label="English">
                                <AddonCategoryEditLocalizedForm categoryNameSource="localizations.en.categoryName" required/>
                                {renderOtherFields()}
                            </FormTab>
                            <FormTab label="Italian">
                                <AddonCategoryEditLocalizedForm categoryNameSource="localizations.it.categoryName" />
                                {renderOtherFields()}
                            </FormTab>
                        </TabbedForm>
                    </Edit>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
