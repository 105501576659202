import ProfileEdit from "./components/ProfileEdit";
import React from "react";
import { Route } from 'react-router-dom';

export default [
    <Route
        key="profile"
        path="/profile"
        component={ProfileEdit}
    />
];