import * as React from "react";
import { List, Datagrid, TextField, ArrayField, SingleFieldList, EditButton, DeleteButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Edit button custom class
 * @param {*} props Props that contain attributes related to addons
 */
const MyEditButton = props => {
    return <EditButton {...props} />;
};

/**
 * Component to a separator comma between categories
 */
const CategoryField = ({ record = {} }) => <span>{typeof record.category === 'undefined' || record.category === null || !Array.isArray(record.category) ? null : record.category.map((d,i)=>{
    if (i===0)
        return d.categoryName
    else return ', ' + d.categoryName
    })}</span>;

/**
 * Addon list class
 * @param {*} props Props that contain attributes related to addons
 */
export const Addon = (props) => (
    <List {...props} bulkActionButtons={false} pagination={false} title="Add-ons" exporter={false}>
        <Datagrid>
            <TextField source="addonName" label="Add-on"/>
            <CategoryField source="category" style={{color:"black"}}/>
            <TextField source="addonPrice" label="Price"/>
            <MyEditButton />
          <DeleteButton confirmTitle={'Delete add-on'}
                        confirmContent={'Are you sure you want to delete this add-on?'}
                        undoable={false} />
        </Datagrid>
    </List>
);
