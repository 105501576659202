import React from "react";
import {Edit, SimpleForm, TextInput, Toolbar, SaveButton, required, minLength, maxLength} from "react-admin";
import jwtDecode from "jwt-decode";

export const ProfileEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton/>
    </Toolbar>
);

export const passwordMatchValidation = (value, allValues) => {
    if (value !== allValues.newPassword) {
        return 'Passwords should match'
    }
}

export const passwordNotMatchValidation = (value, allValues) => {
    if (value === allValues.password) {
        return 'Current and new password should not match'
    }
}

const ProfileEdit = ({ staticContext, ...props }) => {

    const getUserId = () => {
        let token = localStorage.getItem("token");
        let decodedToken = jwtDecode(token);
        return decodedToken.userId;
    }

    const newPasswordValidations = [
        required(),
        minLength(8),
        maxLength(25),
        passwordNotMatchValidation
    ];

    return (
        <Edit
            id={getUserId()}
            resource="profile"
            basePath="/profile"
            redirect={false}
            title="User profile"
            undoable={false}
            {...props}
        >
            <SimpleForm toolbar={<ProfileEditToolbar/>}>
                <TextInput label="Current password" source="password" type="password" validate={[required()]}/>
                <TextInput source="newPassword" type="password" validate={newPasswordValidations}/>
                <TextInput source="confirmPassword" type="password" validate={[required(), passwordMatchValidation]}/>
            </SimpleForm>
        </Edit>
    );
};

export default ProfileEdit;