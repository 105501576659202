import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import jwt_decode from "jwt-decode";

/**
 * Auth provider.
 */
export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { email, password } = params;
        const request = new Request(process.env.REACT_APP_API_URL + '/users/authenticate', {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                localStorage.setItem('token', "Bearer " + response.token);
            });
    }
    if (type === AUTH_LOGOUT) {
        if(localStorage.getItem('token')) {
            localStorage.removeItem('token');
            window.location.reload();
        }
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401 || status === 403) {
            localStorage.setItem('unAuthError', 'true');
            return Promise.reject({ redirectTo: '/no-access' });
        }
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        let jwtToken = localStorage.getItem('token');
        if (jwtToken && jwtToken.length > 0) {
            if (jwt_decode(jwtToken).exp < Date.now() / 1000) {
                localStorage.setItem('unAuthError', 'true');
                Promise.reject()
            } else {
                Promise.resolve()
            }
        } else {
            if (localStorage.getItem('token')) {
                localStorage.setItem('unAuthError', 'true');
            }
            Promise.reject()
        }
    }
    if (type === AUTH_GET_PERMISSIONS) {
        if(localStorage.getItem('token')) {
            let decodedJwt = jwt_decode(localStorage.getItem('token'));
            const roles = JSON.stringify(decodedJwt.roles);
            let roleIds = [];
            JSON.parse(roles).forEach(function(entry) {
                roleIds.push(entry.id);
            });
            return roleIds ? Promise.resolve(roleIds) : Promise.reject();
        }
    }
    return Promise.resolve();
}