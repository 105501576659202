import { Edit, SimpleForm, TextInput, SaveButton, Toolbar, required} from 'react-admin'
import React, { Component } from 'react'
import { IconButton, Dialog, DialogContent, Button, Typography, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import RichTextInput from 'ra-input-rich-text';
import {CloseConfirmationButton} from "../components/CloseConfirmationButton"

/**
 * User create form toolbar component
 * @param {*} props Props for toolbar Save Button
 */
const AboutEditToolbar = props => (
  <Toolbar {...props}  >
    <div style={{flex: '0 0 auto', display: 'flex', padding: '8px', width: '100%', justifyContent: 'flex-end'}}
         {...props}>
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/restaurants-my' />
        <SaveButton
          {...props}
            label="SAVE"
            submitOnEnter={false}
            style={{marginLeft:'8px', width: '20%'}}
        />
    </div>
  </Toolbar>
);

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/**
* Custom Dialog Title Component
*/
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textAlign: 'left', fontWeight: "fontWeightBold" }}>{children}</Typography>
      {onClose ? (
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/restaurants-my' withIcon={true} classes={classes}/>
      ) : null}
    </MuiDialogTitle>
  );
});

/**
 * Addon edit class
 */
class RestaurantAboutEdit extends Component {

  render() {

    const toolbar = [
          [{ 'font': [] }, { 'size': [] }],
          [ 'bold', 'italic', 'underline', 'strike' ],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'script': 'super' }, { 'script': 'sub' }],
          [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
          [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
          [ 'direction', { 'align': [] }],
          [ 'link', 'image', 'video', 'formula' ],
          [ 'clean' ]
    ];

    return (
          <Dialog
          open={true}
          fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={() => window.history.back() }>
            Edit restaurant about page
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ width: '100%' }}>
            <Edit undoable={false} {...this.props}>
                <SimpleForm toolbar={<AboutEditToolbar />}>
                  <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: '100%' }}>
                        <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                          <Box fontWeight="fontWeightBold" m={1}>
                            Main heading
                          </Box>
                        </Typography>
                        <TextInput source="aboutHeading" style={{ width: '100%' }} validate={required()}/>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: '100%' }}>
                        <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                          <Box fontWeight="fontWeightBold" m={1}>
                            Content
                          </Box>
                        </Typography>
                        <RichTextInput toolbar={toolbar} source="aboutContent" style={{ width: '100%' }} validate={required()}/>
                      </div>
                    </div>
                    </div>
                </SimpleForm>
                </Edit>
            </div>
          </DialogContent>
        </Dialog>
    )
  }
}

export default RestaurantAboutEdit;


