import * as React from "react";

import Config from './../util/Config'

import {List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';
import './../App.css';

/**
 * Component that shows unlimited if maxAddons <= 0
 */
const MaximumAddonField = ({ record = {} }) => <span>{record.maxAddons === Config.MAX_VAL || record.isUnlimited ? "Unlimited" : record.maxAddons === 0 ? null : record.maxAddons}</span>;
MaximumAddonField.defaultProps = { label: 'Name' };

const MinimumAddonField = ({ record = {} }) => <span>{record.minAddons === 0 && record.maxAddons === 0 ? null : record.minAddons}</span>;
MinimumAddonField.defaultProps = { label: 'Name' };
/**
 * Dish Categories list class
 * @param {*} props Props that contain attributes related to Dish Categories
 */
export const AddonCategories = (props) => (
    <span>
        <List {...props} bulkActionButtons={false} exporter={false} title={'Add-on categories'}>
            <Datagrid>
                <TextField source="categoryName" label="Category" sortable={false} />
                <MinimumAddonField source="minAddons" label="Minimum" sortable={false} />
                <MaximumAddonField source="maxAddons" label="Maximum" sortable={false} />
                <EditButton />
                <DeleteButton confirmTitle={'Delete add-on category'}
                              confirmContent={'Are you sure you want to delete this add-on category?'}
                              undoable={false} />
            </Datagrid>
        </List>
    </span>
);
