import Config from './Config';

/**
 * Generates random discount code in 12 character length.
 * @returns {string} the code.
 */
export const generateCode = () => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 12; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

/**
 * Generates text from order records
 * @returns {string} the text.
 */
export const generateTextFromOrderRecords = (records) => {

    var result = "Order number\tCreation date\t\t\tType\t\tTime\tPrice\t\tStatus\n";
    records.map(record => result += "#" + record.id + "\t\t\t\t" + parseDate(record.orderCreationDate) + "\t\t" + Config.DELIVERY_STATUS.get(record.orderDeliveryStatus) + "\t" + record.orderDeliverytime.slice(0, -3) + "\t€ " + record.orderPrice.toFixed(2) + "\t\t" + Config.ORDER_STATUS.get(record.orderStatus) + "\n")
    
    return result;
};

/**
 * method that parse the date to a readable format for date input fields
 * @param {*} d
 */
export const parseDate = (d) => {
    if (d != null) {
        if (d == '')
            return null;
        var date = new Date(d);
        var yyyy = date.getFullYear();
        var mm = date.getMonth() + 1;
        var dd = date.getDate();

        var hh = date.getHours();
        var min = date.getMinutes();
        var ss = date.getSeconds()

        if (hh.toString().length < 2)
            hh = '0' + hh;
        if (min.toString().length < 2)
            min = '0' + min;
        if (ss.toString().length < 2)
            ss = '0' + ss;
        if (dd.toString().length < 2)
            dd = '0' + dd;
        if (mm.toString().length < 2)
            mm = '0' + mm;
        return dd + '/' + mm + '/' + yyyy + ', ' + hh + ':' + min + ':' + ss;
    }
    return date;
}