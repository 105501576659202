import {Show, SimpleShowLayout} from 'react-admin'
import React, { Component} from 'react'
import {Dialog, DialogContent, Button, DialogActions } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Config from '../util/Config'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import { renderToString } from 'react-dom/server'

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

/**
 * generate a string with all addons joined with a comma.
 * @param addons the addon list.
 * @returns {*} addnon list string.
 */
const createAddonsString = addons => {

    let addonsName = [];
    addons.forEach(element => addonsName.push(element.dishAddonName));

    return addonsName.join(', ')
    
}

/**
 * parse the date string in iso to an more readable date.
 * @param dateString tha date to parses.
 * @returns {*} string parsed with date.
 */
const parseDateString = d => {

    if (d != null) {
        if (d == '')
            return null;
        var date = new Date(d);
        var yyyy = date.getFullYear();
        var mm = date.getMonth() + 1;
        var dd = date.getDate();

        var hh = date.getHours();
        var min = date.getMinutes();
        var ss = date.getSeconds()

        if (hh.toString().length < 2)
            hh = '0' + hh;
        if (min.toString().length < 2)
            min = '0' + min;
        if (ss.toString().length < 2)
            ss = '0' + ss;
        if (dd.toString().length < 2)
            dd = '0' + dd;
        if (mm.toString().length < 2)
            mm = '0' + mm;
        return dd + '/' + mm + '/' + yyyy + ', ' + hh + ':' + min + ':' + ss;
    }
    return date;
}



const calculateTotalPrice = (basicTotal, isDelivery, deliveryPrice, discount) => {
    var tot = basicTotal;
    if (isDelivery) {
        if (deliveryPrice) {
            tot += deliveryPrice
        }
    }

    if (discount) {
        tot -= discount
    }

    return tot
}

/**
 * generate a table with the dishes in record.
 * @param record record of the selected order.
 * @returns {*} a table.
 */
const DishesTable = ({ record = {} }) => (
    <TableContainer component={Paper}>
        <Table aria-label="spanning table">
            <TableHead>
                <TableRow>
                    <TableCell align="center" style={{fontWeight: 'bold'}}>
                        Dish
                    </TableCell>
                    <TableCell align="center" style={{fontWeight: 'bold'}}>Addons</TableCell>
                    <TableCell align="center" style={{fontWeight: 'bold'}}>Quantity</TableCell>
                    <TableCell align="center" style={{fontWeight: 'bold'}}>Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {record.dishes == null ? null : record.dishes.map( dish => (
                    <TableRow>
                        <TableCell align="center">{dish.dishName}</TableCell>
                        <TableCell align="center">{createAddonsString(dish.addons)}</TableCell>
                        <TableCell align="center">{dish.dishesQuantity}</TableCell>
                        <TableCell align="center">{dish.dishAndAddonPrice * dish.dishesQuantity} €</TableCell>
                    </TableRow>
                ))}
                { Config.DELIVERY_STATUS.get(record.orderDeliveryStatus) === 'Delivery' ?
                    <TableRow>
                        <TableCell align="center" style={{fontWeight: 'bold'}}>Delivery</TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center" style={{fontWeight: 'bold'}}>{record.orderDeliveryPrice} €</TableCell>
                    </TableRow>
                    : null
                }
                { record.orderDiscount > 0 ?
                    <TableRow>
                        <TableCell align="center" style={{fontWeight: 'bold'}}>Discount</TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center" style={{fontWeight: 'bold'}}>-{record.orderDiscount} €</TableCell>
                    </TableRow>
                    : null
                }
            </TableBody>
            <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center" style={{fontWeight: 'bold'}}>Total: {calculateTotalPrice(record.orderPrice, Config.DELIVERY_STATUS.get(record.orderDeliveryStatus) === 'Delivery', record.orderDeliveryPrice, record.orderDiscount)} €</TableCell>
            </TableRow>
        </Table>
    </TableContainer>
);

/**
 * generate a unique id for tabs.
 * @param index tha date to parses.
 * @returns {*} id of the tab.
 */
function generateTabId(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

/**
 * generate a panel for tabs.
 * @param props data ti conficure the panel.
 * @returns {*} the pael containint the chosen children.
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

/**
 * Area that contains the tabs with contact informations.
 * @param record record of the selected order.
 * @returns {*} tabs with informations.
 */
const InfoArea = ({ record = {} }) => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <div style={{flexDirection: 'row', display: 'flex', paddingBottom: '10px'}}>
            <div style={{width: '45%', border: '1px solid #DFDFDF', minHeight: '200px'}}>
                <Paper>
                    <Tabs centered value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Client" {...generateTabId(0)} />
                        <Tab label="Company" {...generateTabId(1)} />
                    </Tabs>
                </Paper>
                { record.contacts == null ? null :
                    <TabPanel value={value} index={0}>
                        <div><span style={{fontWeight: 'bold'}}>Name:</span> <span>{record.contacts.contactName === null || record.contacts.contactName === '' ? 'not given' : record.contacts.contactName}</span></div>
                        <div><span style={{fontWeight: 'bold'}}>Surname:</span> <span>{record.contacts.contactSurname === null || record.contacts.contactSurname === '' ? 'not given' : record.contacts.contactSurname}</span></div>
                        <div><span style={{fontWeight: 'bold'}}>Phone number:</span> <span>{record.contacts.contactPhoneNumber === null || record.contacts.contactPhoneNumber === '' ? 'not given' : record.contacts.contactPhoneNumber}</span></div>
                        <div><span style={{fontWeight: 'bold'}}>Email:</span> <span>{record.contacts.contactEmail === null || record.contacts.contactEmail === '' ? 'not given' : record.contacts.contactEmail}</span></div>
                    </TabPanel>
                }
                { record.contacts == null ? null :
                    <TabPanel value={value} index={1}>
                        <div><span style={{fontWeight: 'bold'}}>Company name:</span> <span>{record.contacts.contactCompanyName === null || record.contacts.contactCompanyName === '' ? 'not given' : record.contacts.contactCompanyName}</span></div>
                        <div><span style={{fontWeight: 'bold'}}>Company address:</span> <span>{record.contacts.contactCompanyCode === null || record.contacts.contactCompanyCode === '' ? 'not given' : record.contacts.contactCompanyCode}</span></div>
                        <div><span style={{fontWeight: 'bold'}}>Company code number:</span> <span>{record.contacts.contactCompanyAddress === null || record.contacts.contactCompanyAddress === '' ? 'not given' : record.contacts.contactCompanyAddress}</span></div>
                    </TabPanel>
                }
            </div>
            <div style={{width: '10%'}}></div>
            <div style={{width: '45%', border: '1px solid #DFDFDF', minHeight: '200px'}}>
                <TabPanel>
                    <div><span style={{fontWeight: 'bold'}}>Order type:</span> <span>{Config.DELIVERY_STATUS.get(record.orderDeliveryStatus)}</span></div>
                    { record.contacts == null ? null :
                        <div><span style={{fontWeight: 'bold'}}>Delivery address:</span> <span>{record.contacts.deliveryAddress === null || record.contacts.deliveryAddress === '' ? 'not given' : record.contacts.deliveryAddress}</span></div>
                    }
                    <div><span style={{fontWeight: 'bold'}}>Payment type:</span> <span>{Config.ORDER_STATUS.get(record.paymentType)}</span></div>
                    <div><span style={{fontWeight: 'bold'}}>Time:</span> <span>{record.orderDeliverytime}</span></div>
                    <div><span style={{fontWeight: 'bold'}}>Needs invoice:</span> <span>{record.orderNeedinvoice === 0 ? "Yes" : "No"}</span></div>
                </TabPanel>
            </div>
        </div>
        
    )
}

/**
 * Area that contains the order comment.
 * @param record record of the selected order.
 * @returns {*} area with informations.
 */
const CommentArea = ({ record = {} }) => {

    return (
        <div style={{paddingBottom: '10px', textAlign: 'center', paddingTop: '20px'}}>
            <Typography>
                <div><span style={{fontWeight: 'bold'}}>Comment</span></div>
            </Typography>
            <div style={{width: '100%', border: '1px solid #DFDFDF', wordWrap: 'break-word'}}>
                <div>
                    <Typography><div>{record.orderComment === null || record.orderComment === '' ? "no comment" : record.orderComment}</div></Typography>
                </div>
            </div>
        </div>
        
    )
}

/**
 * div that contains the printable content.
 * @param record record of the selected order.
 * @returns {*} printable content.
 */
const PrintableDiv = ({ record = {} }) => (
    <div>
        <div style={{width: '100%', textAlign: 'center', paddingBottom: '25px'}}>
            <div style={{fontSize: '40px'}}>Order #{record.id} details</div>
            <div style={{fontSize: '17px'}}>{parseDateString(record.orderCreationDate)}</div>
        </div>
        <div style={{flexDirection: 'row', display: 'flex', paddingBottom: '25px'}}>
            <div style={{width: '45%'}}>
                <div style={{fontWeight: 'bold'}}>Client information</div>
                <div style={{border: '1px solid #DFDFDF', minHeight: '140px'}}>
                { record.contacts == null ? null :
                    <TabPanel>
                        <div><span style={{fontWeight: 'bold'}}>Name:</span> <span>{record.contacts.contactName === null || record.contacts.contactName === '' ? 'not given' : record.contacts.contactName}</span></div>
                        <div><span style={{fontWeight: 'bold'}}>Surname:</span> <span>{record.contacts.contactSurname === null || record.contacts.contactSurname === '' ? 'not given' : record.contacts.contactSurname}</span></div>
                        <div><span style={{fontWeight: 'bold'}}>Phone number:</span> <span>{record.contacts.contactPhoneNumber === null || record.contacts.contactPhoneNumber === '' ? 'not given' : record.contacts.contactPhoneNumber}</span></div>
                        <div><span style={{fontWeight: 'bold'}}>Email:</span> <span>{record.contacts.contactEmail === null || record.contacts.contactEmail === '' ? 'not given' : record.contacts.contactEmail}</span></div>
                    </TabPanel>
                }
                </div>
            </div>
            <div style={{width: '10%'}}></div>
            <div style={{width: '45%'}}>
                <div style={{fontWeight: 'bold'}}>Company information</div>
                <div style={{border: '1px solid #DFDFDF', minHeight: '140px'}}>
                { record.contacts == null ? null :
                    <TabPanel>
                        <div><span style={{fontWeight: 'bold'}}>Company name:</span> <span>{record.contacts.contactCompanyName === null || record.contacts.contactCompanyName === '' ? 'not given' : record.contacts.contactCompanyName}</span></div>
                        <div><span style={{fontWeight: 'bold'}}>Company address:</span> <span>{record.contacts.contactCompanyCode === null || record.contacts.contactCompanyCode === '' ? 'not given' : record.contacts.contactCompanyCode}</span></div>
                        <div><span style={{fontWeight: 'bold'}}>Company code number:</span> <span>{record.contacts.contactCompanyAddress === null || record.contacts.contactCompanyAddress === '' ? 'not given' : record.contacts.contactCompanyAddress}</span></div>
                    </TabPanel>
                }
                </div>
            </div>
        </div>
        <div style={{width: '100%', paddingBottom: '25px'}}>
            <div style={{fontWeight: 'bold'}}>Order details</div>
            <div style={{border: '1px solid #DFDFDF'}}>
                <TabPanel>
                    <div><span style={{fontWeight: 'bold'}}>Order type:</span> <span>{Config.DELIVERY_STATUS.get(record.orderDeliveryStatus)}</span></div>
                    { record.contacts == null ? null :
                        <div><span style={{fontWeight: 'bold'}}>Delivery address:</span> <span>{record.contacts.deliveryAddress === null || record.contacts.deliveryAddress === '' ? 'not given' : record.contacts.deliveryAddress}</span></div>
                    }
                    <div><span style={{fontWeight: 'bold'}}>Payment type:</span> <span>{Config.ORDER_STATUS.get(record.paymentType)}</span></div>
                    <div><span style={{fontWeight: 'bold'}}>Time:</span> <span>{record.orderDeliverytime}</span></div>
                    <div><span style={{fontWeight: 'bold'}}>Needs invoice:</span> <span>{record.orderNeedinvoice === 0 ? "Yes" : "No"}</span></div>
                </TabPanel>
            </div>
        </div>
        <div style={{paddingBottom: '25px'}}>
            <DishesTable record={record}/>
        </div>
        <div style={{paddingBottom: '25px'}}>
            <div><span style={{fontWeight: 'bold'}}>Comment</span></div>
            <div style={{width: '100%', border: '1px solid #DFDFDF', wordWrap: 'break-word'}}>
                <div>
                    <Typography><div style={{marginLeft: '10px', marginRight: '10px'}}>{record.orderComment === null || record.orderComment === '' ? "no comment" : record.orderComment}</div></Typography>
                </div>
            </div>
        </div>
    </div>
)

/**
 * Utility component that recieve the record from the parent and set the modal title
 * @param record record of the selected order.
 */
const TitleSetter = ({ record = {} }) => {
        return document.getElementById("orderDialogTitle") == null ? null :
                document.getElementById("orderDialogTitle").innerHTML
                        = "Order #" + record.id + " - " + parseDateString(record.orderCreationDate);
}

/**
 * Utility component that recieve the record from the parent and generate an hidden div with printable content
 * @param record record of the selected order.
 */
const PrintableContentSetter = ({ record = {} }) => {

    var body = document.getElementsByTagName("BODY")[0];

    var node = document.getElementById("printableContent")
    if (node == null && record.contacts != null) {
        node = document.createElement("DIV");  
        node.setAttribute("id", "printableContent");
        node.setAttribute("class", "printable");
        node.innerHTML= renderToString(<PrintableDiv record={record}/>);
        body.appendChild(node);
    } else if (node != null) {
        node.innerHTML = renderToString(<PrintableDiv record={record}/>);
    }


    return null;
}

/**
* Custom Dialog Title Component
*/
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6" style={{ textAlign: 'left', fontWeight: "fontWeightBold" }}>{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

/**
 * Order show class
 */
class OrderShow extends Component {

    render() {
        return (
            <Dialog open={true} fullWidth={true} maxWidth={'md'} className="noPrint">
                <DialogTitle id="customized-dialog-title" onClose={() => window.history.back() }>
                    <span id="orderDialogTitle">Order</span>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ width: '100%' }}>
                        <Show {...this.props}>
                            <SimpleShowLayout>
                                <TitleSetter/>
                                <InfoArea/>
                                <DishesTable/>
                                <CommentArea/>
                                <PrintableContentSetter/>
                            </SimpleShowLayout>
                        </Show>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => window.history.back() } color="primary">
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PrintIcon />}
                        onClick={() => {
                            window.print()
                        }}
                    >
                        Print
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default OrderShow;


