import { Create, TabbedForm, FormTab, TextInput, SaveButton, Toolbar, NumberInput, BooleanInput, minValue, required } from 'react-admin'
import React, { useState } from 'react'
import { IconButton, Dialog, DialogContent, Button, Typography, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import jwt_decode from "jwt-decode";
import {CloseConfirmationButton} from "../components/CloseConfirmationButton"


/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/**
* Custom Dialog Title Component
*/
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textAlign: 'left', fontWeight: "fontWeightBold" }}>{children}</Typography>
      {onClose ? (
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/addon-category' withIcon={true} classes={classes}/>
      ) : null}
    </MuiDialogTitle>
  );
});


/**
 * Addon category create component
 * @param {*} props Props to create Addon category component
 */
export const AddonCategoryCreate = props => {
  const [disableMax, setDisableMax] = useState(false);
  const [checkedUn, setCheckedUn] = useState(false);
  const [minVal, setMinVal] = useState(0);
  const [meaning, setMeaning] = useState("Not all fields filled");

  /**
   * Validate max value function
   * @param {*} value 
   * @param {*} allValues 
   */
  const mandatoryValidationMax = (value, allValues) => {
      if (allValues.isUnlimited !== true) {
        if(value === null || value === ''){
          return 'Required'
        }
      }
      
      if((value !== null) && (allValues.minAddon !== null)){
        if (allValues.isUnlimited !== true) {
          if (value < allValues.minAddon) {
            return 'Value must be greater than or equal to ' + allValues.minAddon
          }
        }
      }

    return null;
  };

  /**
   * validation arrays
   */
  const validateMinimum = [minValue(0,"negative values are not allowed"), required()];
  let validateMaximum = [mandatoryValidationMax];
  if (!checkedUn) {
    validateMaximum.push(required())
  }
  const validateName = [required()]

/**
 * Addon category create form toolbar component
 * @param {*} props Props for toolbar Save Button
 */
const AddonCategoryCreateToolbar = props => (
  <Toolbar {...props}  >
    <div style={{flex: '0 0 auto', display: 'flex', padding: '8px', width: '100%', justifyContent: 'flex-end'}}
         {...props}>
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/addon-category' />
        <SaveButton
          {...props}
          label="SAVE"
          style={{marginLeft:'8px', width: '20%'}}
          redirect={`/addon-category`}
          />
    </div>
  </Toolbar>
);
  /**
   * field validate function
   * @param {*} values 
   */
  const validateFields = (values) => {
    setMinVal(values.minAddon)
    if (values.localizations && values.localizations.en.categoryName != null ) {
        if(values.minAddon != null){
          if (!values.isUnlimited){
            if (values.maxAddon != null) {
              if (values.minAddon<=values.maxAddon){
                setMeaning("Can pick from " + values.minAddon + " to " + values.maxAddon + " addons.");
                return;
              } else {
                setMeaning("Minimum value can not be bigger than maximum")
                return;
              }
            }
          }
        } else {
          return;
        }
    }

    if (values.localizations && values.localizations.en.categoryName != null && values.minAddon != null && values.maxAddon != null) {
      if (values.isUnlimited){
        setMeaning("Can pick from " + values.minAddon + " to unlimited addons.");
        return;
      }
      if (!values.isUnlimited){
        if (values.minAddon<=values.maxAddon){
          setMeaning("Can pick from " + values.minAddon + " to " + values.maxAddon + " addons.");
          return;
        } else {
          setMeaning("Minimum value can not be bigger than maximum")
          return;
        }
      }
    } else if (values.localizations &&  values.localizations.en.categoryName != null && values.minAddon != null && values.isUnlimited) {
      setMeaning("Can pick from " + values.minAddon + " to unlimited addons.");
      return;
    }

    setMeaning("Not all fields filled")

  };


  const [open] = useState(true);
  
  
  const handleChange = () => {

    if(checkedUn === true){
      setCheckedUn(false)    
      setDisableMax(false)
    } else {
      setCheckedUn(true)
      setDisableMax(true)
      
    }
  }

  const AddonCategoryCreateLocalizedForm = props => (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '100%' }}>
            <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
              <Box fontWeight="fontWeightBold" m={1}>
                Category
              </Box>
            </Typography>
            <TextInput
                label="Category Name"
                source={props.categoryNameSource}
                validate={props.required ? validateName : []}
                style={{ width: '100%' }}
            />
          </div>
        </div>
        
      </div>
      <div style={{ display: 'none'}}>
        <NumberInput source="restaurantId" defaultValue = {JSON.stringify(jwt_decode(localStorage.getItem('token')).restaurantId)} />
      </div>
    </React.Fragment>
);

const renderOtherFields = () => {
  return (
      <div style={{width: '100%'}}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '45%'}}>
            <Typography style={{ textAlign: 'left' }}>
              <Box fontWeight="fontWeightBold" m={1}>
                Minimum add-ons
              </Box>
            </Typography>
            <NumberInput source="minAddon" inputProps={{ min: 0 }} style={{ width: '100%' }} validate={validateMinimum}/>
          </div>
          <div style={{ width: '45%', paddingLeft: '10%' }}>
            <Typography style={{ textAlign: 'left' }}>
              <Box fontWeight="fontWeightBold" m={1}>
                Maximum add-ons
              </Box>
            </Typography>
            <NumberInput source="maxAddon" inputProps={{ min: 0 }} style={{ width: '100%' }} disabled = {disableMax} validate={validateMaximum}/>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '45%'}}>
            <BooleanInput source="isMandatory" defaultValue = {false} label="Is mandatory" style={{ width: '100%' }}/>
          </div>
          <div style={{ width: '45%', paddingLeft: '10%' }}>
            <BooleanInput source="isUnlimited" defaultValue = {false} label="Unlimited" style={{ width: '100%' }} onChange={handleChange}/>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '100%' }}>
            <Typography style={{ textAlign: 'left' }}>
              <Box>
                <span  style={{fontWeight: 'bold'}}>Meaning: </span>{meaning}
              </Box>
            </Typography>
          </div>
        </div>
      </div>
  )
}

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => props.history.push(`/addon-category`)}>
          Create add-on category
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ width: '100%' }}>
            <Create {...props} onSuccess={() => props.history.push(`/addon-category`)}>
              <TabbedForm validate={validateFields} toolbar={<AddonCategoryCreateToolbar {...props} />}>
                <FormTab label="English">
                  <AddonCategoryCreateLocalizedForm categoryNameSource="localizations.en.categoryName" required/>
                  {renderOtherFields()}
                </FormTab>
                <FormTab label="Italian">
                  <AddonCategoryCreateLocalizedForm categoryNameSource="localizations.it.categoryName" />
                  {renderOtherFields()}
                </FormTab>
              </TabbedForm>
            </Create>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  ) // change rastaurant id after auth
}

