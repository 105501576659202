import React from "react";
import { Admin, Resource } from 'react-admin';
import UserIcon from '@material-ui/icons/People';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import dataProvider from './components/DataProvider'
import { Restaurants } from './views/Restaurants';
import { MyRestaurant } from './views/MyRestaurant';
import { Discounts } from './views/Discounts';
import { AddonCategories } from './views/AddonCategories';
import { Users } from './views/Users';
import { Orders } from './views/Orders';
import { Menus } from './views/RestaurantMenu'
import { UserEdit } from './modals/UserEdit';
import { AddonCategoryEdit } from './modals/AddonCategoryEdit';
import { AddonCategoryCreate } from './modals/AddonCategoryCreate';
import { UserCreate } from "./modals/UserCreate";
import CreateEditDialogButton from "./modals/CreateEditDialogButton";
import { Addon } from "./views/Addon";
import AddonCreate from "./modals/AddonCreate";
import AddonEdit from "./modals/AddonEdit";
import RestaurantAboutEdit from "./modals/RestaurantAboutEdit";
import CreateMenuButton from './modals/CreateMenuButton'
import ListAltIcon from '@material-ui/icons/ListAlt';
import { DishCategories } from "./views/DishCategories";
import { DishCategoryCreate } from "./modals/DishCategoryCreate";
import { DishCategoryEdit } from "./modals/DishCategoryEdit";
import { Dishes } from './views/Dishes';
import DishCreate from "./modals/DishCreate";
import DishEdit from "./modals/DishEdit";
import authProvider from './authProvider';
import LoginPage from './views/LoginPage';
import Payment from './views/Payment';
import Config from './util/Config'
import MenuLayout from "./components/MenuLayout";
import DiscountEdit from "./modals/DiscountEdit";
import DiscountCreate from "./modals/DiscountCreate";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import OrderShow from "./modals/OrderShow";
import jwt_decode from "jwt-decode";
import { ConfirmProvider } from 'material-ui-confirm';

import customRoutes from "./routes";

const App = () => (
    <ConfirmProvider>
        <Admin
            layout={MenuLayout}
            loginPage={LoginPage}
            authProvider={authProvider}
            dataProvider={dataProvider(process.env.REACT_APP_API_URL)}
            customRoutes={customRoutes}
        >
            {permissions => {
                const resources = [
                    <Resource name="profile"/>
                ];
                if (permissions.includes(Config.SUPER_ADMIN_ROLE))
                    return [
                        <Resource name={'order'} options={{ label: 'Orders' }} list={Orders} show={OrderShow}/>,
                        <Resource name={'restaurants'} options={{ label: 'Restaurants' }} list={Restaurants} create = {CreateEditDialogButton}/>,
                        <Resource name={'users'} options={{ label: 'Users' }} list={Users} create = {UserCreate} edit={UserEdit} icon={UserIcon}/>,
                        ...resources
                    ];
                else if (permissions.includes(Config.ADMIN_ROLE)) {
                    return [
                        <Resource name={'order'} options={{ label: 'Orders' }} list={Orders} show={OrderShow}/>,
                        <Resource name={'addon-category'} options={{ label: 'Categories' }} list = {AddonCategories} create = {AddonCategoryCreate} edit = {AddonCategoryEdit}/>,
                        <Resource name={'addon'} options={{label:"Add-ons"}} list={Addon} create= {AddonCreate} edit={AddonEdit} />,
                        <Resource name={'menu'} options={{ label: 'Menus' }} list={Menus} icon={ListAltIcon} create={CreateMenuButton}/>,
                        <Resource name={'dish-category'} options={{ label: 'Categories' }} list={DishCategories} create={DishCategoryCreate} edit={DishCategoryEdit} icon={RestaurantIcon}/>,
                        <Resource name={'dish'} options={{ label: 'Dishes' }} list={Dishes} create={DishCreate} edit={DishEdit} icon={RestaurantIcon}/>,
                        <Resource name={'discounts'} options={{ label: 'Discounts' }} list={Discounts} create={DiscountCreate} edit={DiscountEdit} icon={ConfirmationNumberIcon} />,
                        <Resource name={'restaurants-my'} options={{ label: 'My restaurant' }} list={MyRestaurant} edit={RestaurantAboutEdit} />,
                        <Resource name={'payments'} options={{ label: 'Credit card payment' }} list={Payment} icon={CreditCardIcon}/>,
                        ...resources
                    ]
                }
            }}
        </Admin>
    </ConfirmProvider>

);

export default App;
