import * as React from "react";
import './../App.css';
import { Button } from '@material-ui/core';
import DataManager from './../util/DataManager'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StripeLogo from '../assets/img/stripe_logo.png';
import jwt_decode from "jwt-decode";

/**
 * generate a panel for tabs.
 * @param props data ti conficure the panel.
 * @returns {*} the pael containint the chosen children.
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

/**
 * generate a unique id for tabs.
 * @param index tha date to parses.
 * @returns {*} id of the tab.
 */
function generateTabId(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


/**
 * Stripe account component.
 */
class Payment extends React.Component {

    constructor(props) {
        super()
        this.state = {
            id: null,
            email: null,
            businessName: null,
            businessUrl: null,
            country: null,
            detailsSubmitted: false,
            charges: false,
            value: 0
        }
    }

    /**
     * Function for set stripe data
     * @param {*} data 
     */
    async setStripeData(data) {
        await this.setState({ ...data })
    }

    /**
     * Function for manage connect to Stripe
     */
    async connectToStripe() {
        DataManager.connectStripeAccount('payments/connect-account', { email: jwt_decode(localStorage.getItem('token')).sub });
    }

    /**
     * Function for manage disconnect to Stripe
     */
    async disconnectToStripe() {
        DataManager.checkStripeUser('payments/disconnect-account/' + JSON.stringify(jwt_decode(localStorage.getItem('token')).userId), async (res) => { window.location.reload(); });
    }

    /**
     * Disconnected account area component
     */
    DisconnectedAccountArea = () => {
        return (
            <div style={{ width: '70%', backgroundColor: '#f5f5f5', padding: '30px', border: '1px solid #ddd', borderRadius: '7px' }}>
                <div style={{ textAlign: 'center' }}>
                    <img alt={''} src={StripeLogo} />
                </div>
                <div style={{ fontSize: '25px', textAlign: 'center' }}>
                    Connect with Stripe to accept credit card payments.
                </div>
                <div style={{ fontSize: '15px', textAlign: 'center', marginTop: '20px' }}>
                    Your restaurant payments is not yet connected with Stripe.
                </div>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button variant="contained" color="primary" onClick={() => this.connectToStripe()}>
                        Connect with Stripe
                    </Button>
                </div>
            </div>
        )
    }

    /**
     * Function for manage tabs changes
     * @param {*} event 
     * @param {*} newValue 
     */
    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
    };

    /**
     * Connected account area component
     */
    ConnectedAccountArea = () => {
        return (
            <div style={{ width: '70%', backgroundColor: '#f5f5f5', padding: '30px', border: '1px solid #ddd', borderRadius: '7px' }}>
                <div style={{ textAlign: 'center' }}>
                    <img alt={''} src={StripeLogo} />
                </div>
                <div>
                    <Tabs value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example">
                        <Tab label="Status" {...generateTabId(0)} />
                        <Tab label="Details" {...generateTabId(1)} />
                    </Tabs>
                </div>
                <TabPanel value={this.state.value} index={0} style={{ minHeight: '190px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <tr><td style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Stripe account connection:</td><td><FiberManualRecordIcon style={{ color: 'green', verticalAlign:'middle' }} /></td></tr>
                        <tr><td style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Stripe details:</td><td><FiberManualRecordIcon style={{ color: this.state.detailsSubmitted ? 'green' : 'red', verticalAlign:'middle' }} /></td></tr>
                        <tr><td style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Stripe charges:</td><td><FiberManualRecordIcon style={{ color: this.state.charges ? 'green' : 'red', verticalAlign:'middle' }} /></td></tr>
                    </table>
                </TabPanel>
                <TabPanel value={this.state.value} index={1} style={{ minHeight: '190px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <tr><td style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Stripe user id: </td><td style={{ borderBottom: '1px solid #ddd' }}>{this.state.id}</td></tr>
                        <tr><td style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Stripe user email: </td><td style={{ borderBottom: '1px solid #ddd' }}>{this.state.email}</td></tr>
                        <tr><td style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Business name: </td><td style={{ borderBottom: '1px solid #ddd' }}>{this.state.businessName}</td></tr>
                        <tr><td style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Business url: </td><td style={{ borderBottom: '1px solid #ddd' }}>{this.state.businessUrl}</td></tr>
                        <tr><td style={{ fontWeight: 'bold' }}>Country: </td><td >{this.state.country}</td></tr>
                    </table>
                </TabPanel>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    Login your Stripe dashboard to access account or payment details.
                </div>
                <div style={{ marginTop: '25px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Button variant="contained" color="primary" onClick={() => window.open('https://dashboard.stripe.com/')} >Stripe dashboard</Button>
                    </div>
                    <div>
                        <Button variant="contained" color="default" onClick={() => this.disconnectToStripe()} >Disconnect Stripe</Button>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        DataManager.checkStripeUser('payments/retrieve-account/' + JSON.stringify(jwt_decode(localStorage.getItem('token')).userId), async (res) => { await this.setStripeData(res.data); });
    }

    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                {this.state.id === null || this.state.detailsSubmitted === false ?
                    this.DisconnectedAccountArea()
                    :
                    this.ConnectedAccountArea()
                }
            </div>
        )
    }

}
export default Payment;