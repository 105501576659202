import { Create, TabbedForm, FormTab, TextInput, NumberInput, BooleanInput, SaveButton, Toolbar, required, number, minValue } from 'react-admin'
import React, { useState } from 'react'
import { IconButton, Dialog, DialogContent, Button, Typography, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import jwt_decode from "jwt-decode";
import {CloseConfirmationButton} from "../components/CloseConfirmationButton"

const noLessThanZero = [required(), number(), minValue(0,"negative values are not allowed")];

/**
 * Dish category create form toolbar component
 * @param {*} props Props for toolbar Save Button
 */
const DishCategoryCreateToolbar = props => (
  <Toolbar {...props}  >
    <div style={{flex: '0 0 auto', display: 'flex', padding: '8px', width: '100%', justifyContent: 'flex-end'}}
         {...props}>
      <CloseConfirmationButton message="If you close your changes will be lost" destination='/dish-category' />
      <SaveButton
        {...props}
        label="SAVE"
        style={{marginLeft:'8px', width: '20%'}}
        redirect={'/dish-category'}
      />
    </div>
  </Toolbar>
);

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/**
* Custom Dialog Title Component
*/
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textAlign: 'left', fontWeight: "fontWeightBold" }}>{children}</Typography>
      {onClose ? (
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/dish-category' withIcon={true} classes={classes}/>
      ) : null}
    </MuiDialogTitle>
  );
});

/**
 * Dish category localized form.
 * @param props the props.
 * @returns {React.Fragment}
 */
const DishCategoryCreateLocalizedForm = props => (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '45%' }}>
            <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
              <Box fontWeight="fontWeightBold" m={1}>
                Name
              </Box>
            </Typography>
            <TextInput label={'Category name'} source={props.categoryNameSource} style={{ width: '100%' }} validate={props.required ? required() : []}/>
          </div>
          <div style={{ width: '45%', paddingLeft: '10%' }}>
            <Typography style={{ textAlign: 'left' }}>
              <Box fontWeight="fontWeightBold" m={1}>
                Priority
              </Box>
            </Typography>
            <NumberInput source="dish_category_priority" inputProps={{ min: 0 }} style={{ width: '100%' }} validate={noLessThanZero}/>
          </div>
          <div style={{ display: 'none'}}>
            <NumberInput source="restaurant_id" defaultValue={JSON.stringify(jwt_decode(localStorage.getItem('token')).restaurantId)}/>
          </div>
          <div style={{ display: 'none'}}>
            <BooleanInput source="dish_category_status" defaultValue="true"/>
          </div>
          <div style={{ display: 'none'}}>
            <TextInput source="lang_id" defaultValue="en"/>
          </div>
        </div>
      </div>
    </React.Fragment>
);

/**
 * Dish category create component
 * @param {*} props Props to create dish category component
 */
export const DishCategoryCreate = props => {
  const [open] = useState(true);
  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => props.history.push(`/dish-category`)}>
          Add new category
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ width: '100%' }}>
            <Create {...props}>
              <TabbedForm toolbar={<DishCategoryCreateToolbar {...props} />}>
                <FormTab label={"English"}>
                  <DishCategoryCreateLocalizedForm categoryNameSource="localizations.en.categoryName" required/>
                </FormTab>
                <FormTab label={"Italian"}>
                  <DishCategoryCreateLocalizedForm categoryNameSource="localizations.it.categoryName"/>
                </FormTab>
              </TabbedForm>
            </Create>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

