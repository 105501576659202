import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction } from 'react-admin';
import { EditRestaurantMenu } from './../modals/EditMenuButton';

/**
 * This is the component that allow to show the action button on the table
 * 
 */
class RestaurantMenuAction extends Component {
    render() {
        const { record } = this.props;

        return(
             typeof record === 'undefined' ? null : <span label="Actions">
                <EditRestaurantMenu id={record.id} edit={true} menu={this.props.record} />
            </span>);
    }
}

/**
 * Use Redux to set his props in the class
 * 
 */
RestaurantMenuAction.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotificationAction,
})(RestaurantMenuAction);