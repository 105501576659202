import React, { Component } from 'react'
import {Dialog, DialogContent, Button, DialogActions, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import DataManager from '../util/DataManager'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import jwt_decode from "jwt-decode";
import SaveIcon from "@material-ui/icons/Save";


/**
 * Restaurant option class
 */
class RestaurantOptions extends Component {

    /**
     * class constructor
     * @param {*} props 
     */
    constructor(props) {
        super()
        this.state = {opened: false, dateError: false, restaurant: {
            promoAbout: false,
            chargeOn: false,
            chargeOrder: 0,
            chargeWd: 0,
            logs: [],
            fixedCost: 0,
            promotion: false,
            daysWorking: 0,
            wdCharged: 0,
            currentRecievedOrders: 0,
            currentFees: 0,
            startPromotion: null,
            endPromotion: null
        }}
    }
    
    /**
     * method that open  the dialog
     */
    openDialog = async () => {
        var restaurant = await DataManager.getWithoutCallback('restaurants/' + this.props.record.id);
        this.setState({opened: true, restaurant: restaurant});
        this.setNestedRestaurantState('startPromotion', this.parseDate(restaurant.startPromotion));
        this.setNestedRestaurantState('endPromotion', this.parseDate(restaurant.endPromotion));
    }

    /**
     * Method that requests order fee report for a specific restaurant.
     * @returns {Promise<void>}
     */
    requestOrderReport = async () => {
        await DataManager.getWithoutCallback(`order/report/${this.props.record.id}`);
    }

    /**
     * method that update the restaurant options
     */
    submitUpdate = async () => {

        if (this.validateDates()) {
            this.setState({dateError: true});
            return;
        } else {
            this.setState({dateError: false});
        }

        var userId = JSON.stringify(jwt_decode(localStorage.getItem('token')).userId);

        DataManager.update("restaurants", this.props.record.id, {
            promoAbout: this.state.restaurant.promoAbout,
            chargeOn: this.state.restaurant.chargeOn,
            chargeOrder: this.state.restaurant.chargeOrder,
            chargeWd: this.state.restaurant.chargeWd,
            fixedCost: this.state.restaurant.fixedCost,
            promotion: this.state.restaurant.promotion,
            startPromotion: this.state.restaurant.promotion ? this.state.restaurant.startPromotion : null,
            endPromotion: this.state.restaurant.promotion ? this.state.restaurant.endPromotion : null,
            workingHours: this.state.restaurant.workingHours,
            deliveryHours: this.state.restaurant.deliveryHours,
            userEditor: userId,
            delivery: this.state.restaurant.delivery
        },() => {this.setState({opened: false})})
    }

    /**
     * method that parse the date to a readable format for date input fields 
     * @param {*} d 
     */
    parseDate = (d) => {
        if (d != null) {
            if (d == '')
                return null;
            var date = new Date(d);
            var yyyy = date.getFullYear();
            var mm = date.getMonth()+1;
            var dd = date.getDate();
            if (dd.toString().length < 2)
                dd = '0' + dd;
            if (mm.toString().length < 2)
                mm = '0' + mm;
            return yyyy + '-' + mm + '-' + dd;
        }
        return date;
    }

    /**
     * method that validates the dates
     */
    validateDates = () => {
        if (this.state.restaurant.promotion) {
            if (this.state.restaurant.startPromotion == '' || this.state.restaurant.startPromotion == null ||
                this.state.restaurant.endPromotion == '' || this.state.restaurant.endPromotion == null) {
                return true;
            }
            if (new Date(this.state.restaurant.startPromotion).getTime() > new Date(this.state.restaurant.endPromotion).getTime())
                return true;
        }

        return false;
    }

    /**
     * method that set the internal restaurant state
     * @param {*} field 
     * @param {*} value 
     */
    setNestedRestaurantState = (field, value) => {
        const { restaurant } = { ...this.state };
        const currentState = restaurant;
        currentState[field] = value;
        this.setState({ restaurant: currentState });
    }

    /**
     * method that set promo about state
     * @param {*} event 
     * @param {*} newAbout 
     */
    handleChangeAbout = (event, newAbout) => {      
        if (newAbout !== null) {
            this.setNestedRestaurantState('promoAbout', newAbout);
        }
    };

    /**
     * method that sets the charge on state
     * @param {*} event 
     * @param {*} newAbout 
     */
    handleChangeChargeOn = (event, newAbout) => {      
        if (newAbout !== null) {
            this.setNestedRestaurantState('chargeOn', newAbout);
        }
    };

    /**
     * generic method to hangle the changes
     * @param {*} field 
     * @param {*} value 
     */
    handleChange = (field, value) => {      
        if (value !== null) {
            if (field == 'chargeOrder' || field == 'chargeWd' || field == 'fixedCost') {
                if (value < 0 || value == '')
                    value = 0
            }

            this.setNestedRestaurantState(field, value);
        }
    };

    /**
     * method that generates a green tile with given infos
     * @param {*} icon 
     * @param {*} text 
     * @param {*} value 
     */
    generateTile = (icon, text, value) => <div className='tileDiv'>
        <div style={{width: '30%'}}>
            {icon}
        </div>
        <div style={{width: '70%', textAlign: 'left'}}>
            <div style={{fontSize: 20, fontWeight: 'bold'}}>
                {value}
            </div>
            <div style={{fontSize: 14}}>
                {text}
            </div>
        </div>
    </div>

    render() {
        const { record } = this.props;

        return (
            <span>
                {this.state.opened ?
                    <Dialog open={true} fullWidth={true} maxWidth={'sm'}>
                        <DialogTitle id="customized-dialog-title" onClose={() => window.history.back() }>
                            <span id="orderDialogTitle">Handle restaurant features and billing</span>
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ width: '100%', marginBottom: 20}}>
                                <span style={{width: '45%', display: 'inline-block'}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 10}}>
                                        Restaurant about page feature
                                    </div>
                                    <div>
                                        <ToggleButtonGroup size="large" value={this.state.restaurant.promoAbout} exclusive onChange={this.handleChangeAbout}>
                                            <ToggleButton value={true} className={this.state.restaurant.promoAbout ? 'toggleOn' : 'toggleInactive'}>
                                                ON
                                            </ToggleButton>
                                            <ToggleButton value={false} className={!this.state.restaurant.promoAbout ? 'toggleOff' : 'toggleInactive'}>
                                                OFF
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </span>
                                <span style={{width: '10%', display: 'inline-block'}} ></span>
                                <span style={{width: '45%', display: 'inline-block'}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 10}}>
                                        Charges for restaurant
                                    </div>
                                    <div>
                                        <ToggleButtonGroup size="large" value={this.state.restaurant.chargeOn} exclusive onChange={this.handleChangeChargeOn}>
                                            <ToggleButton value={true} className={this.state.restaurant.chargeOn ? 'toggleOn' : 'toggleInactive'}>
                                                ON
                                            </ToggleButton>
                                            <ToggleButton value={false} className={!this.state.restaurant.chargeOn ? 'toggleOff' : 'toggleInactive'}>
                                                OFF
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </span>
                            </div>
                            <div style={{width: '100%', marginBottom: 20}}>
                                <span style={{width: '45%', display: 'inline-block'}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 10}}>
                                        Charge/Order (Shipping exluded)
                                    </div>
                                    <div>
                                        <Input id="outlined-persentage" style={{width: '100%'}} inputProps={{ min:"0" }} type="number" 
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    %
                                                </InputAdornment>
                                            }
                                            value={this.state.restaurant.chargeOrder} onChange={ e => this.handleChange('chargeOrder', e.target.value)}
                                        />
                                    </div>
                                </span>
                                <span style={{width: '10%', display: 'inline-block'}} ></span>
                                <span style={{width: '45%', display: 'inline-block'}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 10}}>
                                        Fixed price/work day
                                    </div>
                                    <div>
                                        <Input id="outlined-number" style={{width: '100%'}} inputProps={{ min:"0" }} type="number" 
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>
                                            }
                                            value={this.state.restaurant.chargeWd} onChange={ e => this.handleChange('chargeWd', e.target.value)}
                                        />
                                    </div>
                                </span>
                            </div>
                            <div style={{width: '100%', marginBottom: 20}}>
                                <span style={{width: '100%', display: 'inline-block'}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 10}}>
                                        Fixed cost per order
                                    </div>
                                    <div>
                                        <Input id="outlined-fixed" style={{width: '100%'}} inputProps={{ min:"0" }} type="number" 
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>
                                            }
                                            value={this.state.restaurant.fixedCost} onChange={ e => this.handleChange('fixedCost', e.target.value)}
                                        />
                                    </div>
                                </span>
                            </div>
                            <div style={{width: '100%', marginBottom: 20}}>
                                <span style={{width: '20%', display: 'inline-block'}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 10}}>
                                        Promotion
                                    </div>
                                    <div>
                                        <Switch
                                            checked={this.state.restaurant.promotion}
                                            onChange={(e) => this.handleChange('promotion', e.target.checked)}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            color={'primary'}
                                        />
                                    </div>
                                </span>
                                {this.state.restaurant.promotion ? <span style={{width: '35%', display: 'inline-block', minHeight: 38}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 14}}>
                                        Start promotion
                                    </div>
                                    <div>
                                        <TextField
                                            id="datetime-local"
                                            error = {this.state.dateError}
                                            helperText = {this.state.dateError ? 'One or both dates are invalid' : ''}
                                            value={this.parseDate(this.state.restaurant.startPromotion)}
                                            type="date"
                                            style={{width: '100%'}}
                                            onChange={e => this.handleChange('startPromotion', e.target.value)}
                                        />
                                    </div>
                                </span> : null}
                                {this.state.restaurant.promotion ? <span style={{width: '10%', display: 'inline-block'}} ></span> : null }
                                {this.state.restaurant.promotion ? <span style={{width: '35%', display: 'inline-block', minHeight: 38}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 14}}>
                                        End promotion
                                    </div>
                                    <div>
                                        <TextField
                                            id="datetime-local"
                                            error = {this.state.dateError}
                                            helperText = {this.state.dateError ? 'One or both dates are invalid' : ''}
                                            value={this.state.restaurant.endPromotion}
                                            type="date"
                                            style={{width: '100%'}}
                                            onChange={e => this.handleChange('endPromotion', e.target.value)}
                                        />
                                    </div>
                                </span> : null}
                            </div>
                            <div style={{width: '100%', marginBottom: 20}}>
                                <span style={{width: '100%', display: 'inline-block'}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 10}}>
                                        Log:
                                    </div>
                                    <div style={{border: '1px solid grey', borderRadius: 5, maxHeight: 200, overflowX: 'hidden', overflowY: 'scroll', padding: 5}}>
                                        {this.state.restaurant.logs.length > 0 ? 
                                        this.state.restaurant.logs.map((text) => <span>{text}<br/></span>) : 'No logs for this restaurant'}
                                    </div>
                                </span>
                            </div>
                            <div style={{width: '100%', marginBottom: 20}}>
                                <div style={{fontWeight: 'bold', marginBottom: 10}}>
                                        Summary:
                                </div>
                                <div style={{width: '100%', marginBottom: 20, display: 'flex', flexDirection: 'row'}}>
                                    <div style={{paddingRight: 10, width: '50%'}}>{this.generateTile(<ShoppingCartIcon fontSize='large'/>, "Current received orders", this.state.restaurant.currentRecievedOrders)}</div>
                                    <div style={{paddingLeft: 10, width: '50%'}}>{this.generateTile(<EuroSymbolIcon fontSize='large'/>, "Current fees on orders", this.state.restaurant.currentFees)}</div>
                                </div>
                                <div style={{width: '100%', marginBottom: 20, display: 'flex', flexDirection: 'row'}}>
                                    <div style={{paddingRight: 10, width: '50%'}}>{this.generateTile(<EventAvailableIcon fontSize='large'/>, "Current worked days", this.state.restaurant.daysWorking)}</div>
                                    <div style={{paddingLeft: 10, width: '50%'}}>{this.generateTile(<EuroSymbolIcon fontSize='large'/>, "Current fees on work days", this.state.restaurant.wdCharged)}</div>
                                </div>
                            </div>
                            <div style={{width: '100%', marginBottom: 20}}>
                                <Button
                                    style={{width: '30%', fontSize: '12px'}}
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.requestOrderReport}
                                >
                                    Order fee report
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => this.setState({opened: false}) }>
                                Close
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{backgroundColor: '#3f51b5'}}
                                startIcon={<SaveIcon />}
                                onClick={this.submitUpdate}
                            >
                                SAVE
                            </Button>
                        </DialogActions>
                    </Dialog> : null }
                    <IconButton aria-label="Options" size="large" onClick={this.openDialog}>
                        <EditAttributesIcon fontSize="large" />
                    </IconButton>
                </span>

        )
    }
}

/**
 * Use Redux to set his props in the class
 * 
 */
RestaurantOptions.propTypes = {
    record: PropTypes.object,
};

export default connect(null, {
})(RestaurantOptions);