import * as React from "react";
import { EditButton, List, Datagrid, TextField, BooleanField, DeleteButton  } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

makeStyles({
    button: {
        fontSize: '0',
        '& svg': { color: 'green' }
    }
});

/**
 * Role field custom component.
 * @param {*} record props that contains all user information.
 */
const RoleField = ({ record = {} }) => <span style={{ whiteSpace: 'nowrap' }}>{record.roles ? record.roles.map(obj => {
    return <div>{obj.roleName === "super_admin" ? "Super admin" : "Admin"}</div>
}) : null}</span>;

/**
 * Users list class
 * @param {*} props Props that contain attributes related to users
 */
export const Users = (props) => (
    <List {...props} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <TextField source="name" sortable={false}/>
            <TextField source="last_name" sortable={false}/>
            <TextField source="email" sortable={false}/>
            <RoleField source="role" sortable={false}/>
            <BooleanField source="stripe_flag" label="Stripe" sortable={false}/>
            <BooleanField source="stripe_charges_flag" label="Stripe charges" sortable={false}/>
            <EditButton/>
            <DeleteButton confirmTitle={'Delete user'}
                          confirmContent={'Are you sure you want to delete this user?'}
                          undoable={false} />
        </Datagrid>
    </List>
);






