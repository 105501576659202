import * as React from "react";
import { EditButton, List, Datagrid, TextField, DeleteButton} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Edit button custom class
 * @param {*} props Props that contain attributes related to addons
 */
const MyEditButton = props => {
    return <EditButton {...props} />;
};

/**
 * Dish categories list class
 * @param {*} props Props that contain attributes related to dish categories
 */
export const DishCategories = (props) => (
    <List {...props} bulkActionButtons={false} exporter={false} title={'Dish categories'}>
        <Datagrid>
            <TextField source="dishCategoryName" label="Category"/>
            <TextField source="dishCategoryPriority" label="Priority"/>
            <MyEditButton/>
          <DeleteButton confirmTitle={'Delete dish category'}
                        confirmContent={'Are you sure you want to delete this dish category?'}
                        undoable={false} />
        </Datagrid>
    </List>
);
