import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify, Notification, defaultTheme } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';

/**
 * Login page
 */
const LoginPage = ({ theme }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();
    const history = useHistory();
    const submit = e => {
        e.preventDefault();
        login({ email, password })
          .then(result => history.push("/order"))
          .catch(() =>
            notify('Invalid email or password', 'warning')
        );
    };

    var unAuthError = localStorage.getItem('unAuthError');
    if (unAuthError) {
        if (unAuthError === 'true') {
            notify("Token expired", 'warning')
            localStorage.removeItem('unAuthError')
        }
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div style={{ textAlign: "center", paddingTop: "30%" }}>
                <Typography component="h1" variant="h5">
                Sign in
                </Typography>
                <ThemeProvider theme={createMuiTheme(defaultTheme)}>
                    <form onSubmit={submit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email Address"
                            name="email"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            name="password"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button 
                            type='submit'
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            SIGN IN
                        </Button>
                    </form>
                    <Notification />
                </ThemeProvider>
            </div>
        </Container>
    );
};
export default connect(undefined, { userLogin })(LoginPage);
