import React, { useEffect, useRef } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import Config from './../util/Config'
import { cloneElement } from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Filter, SearchInput, downloadCSV, Pagination, useNotify, useDataProvider, useListContext, TopToolbar, ExportButton, Button, sanitizeListRestProps, List, Datagrid, ShowButton, TextField } from 'react-admin';
import './../App.css';
import { generateTextFromOrderRecords, parseDate } from '../util/Utility';
import jsonExport from 'jsonexport/dist';
import { useRefresh } from 'react-admin';

/**
 * Component that shows the order number
 */
const OrderNumberField = ({ record = {} }) => <span>#{record.id}</span>;

/**
 * Component that shows the type
 */
const DeliveryStatusField = ({ record = {} }) => <span>{<span>{Config.DELIVERY_STATUS.get(record.orderDeliveryStatus)}</span>}</span>;

/**
 * Component that shows the price
 */
const CurrencyField = ({ record = {} }) => <span style={{ whiteSpace: 'nowrap' }}>{typeof record.totalOrderPrice === 'undefined' ? null : record.totalOrderPrice.toFixed(2) + ' €'}</span>;

/**
 * Component that shows the fee
 */
const FeeField = ({ record = {} }) => <span style={{ whiteSpace: 'nowrap' }}>{typeof record.orderFee === 'undefined' || record.orderFee == null ? null : record.orderFee.toFixed(2) + ' €'}</span>;

/**
 * Component that shows the time
 */
const MyDateField = ({ record = {} }) => {
    if (typeof record.orderCreationDate === 'undefined')
        return (<span></span>);


    return (
        <span style={{ whiteSpace: 'nowrap' }}>{parseDate(record.orderCreationDate)}</span>
    )
};

/**
 * Component that shows the time
 */
const TimeField = ({ record = {} }) => <span style={{ whiteSpace: 'nowrap' }}>{typeof record.orderDeliverytime === 'undefined' ? null : record.orderDeliverytime.slice(0, -3)}</span>;

/**
 * Component that shows the status for super admin
 */
const SimpleOrderStatusField = ({ record = {} }) => <span>{Config.ORDER_STATUS.get(record.orderStatus)}</span>;

/**
 * Component that shows the status
 */
const OrderStatusField = ({ record = {} }) => {

    /**
     * create the select input with status
     * @param {*} sel
     */
    const StatusSelect = sel => {

        const [selectedItem, setSelectedItem] = React.useState(sel)
        const dataProvider = useDataProvider();
        const notify = useNotify()

        /**
         * on change function
         * @param {*} event
         */
        const handleChange = (event) => {
            setSelectedItem(event.target.value)
            dataProvider.update('order', {
                id: record.id,
                data: { orderStatus: event.target.value },
                previousData: { orderStatus: record.orderStatus }
            }).then(() => notify('Order updated'))
        }

        return (<Select
            value={selectedItem}
            onChange={handleChange}
        >
            {[...Config.ORDER_STATUS.keys()].map(key => {
                if (key !== Config.STATUS_PAYMENT_FAILED)
                    return (<MenuItem value={key}>{Config.ORDER_STATUS.get(key)}</MenuItem>)
            })}
        </Select>)
    }

    return (
        <span>
            {record.orderStatus === Config.STATUS_PAYMENT_FAILED ? <span>{Config.ORDER_STATUS.get(record.orderStatus)}</span> : <span>{StatusSelect(record.orderStatus)}</span>}
        </span>
    )
}

/**
 * custom action list with export and copy buttons
 * @param {*} props
 */
const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
        page,
        perPage
    } = useListContext();

    const dataProvider = useDataProvider();
    const notify = useNotify()

    /**
     * function that get regords paginated and copy to clipboard
     */
    const handleCopyToClipboard = () => {
        dataProvider.getList(resource, {
            pagination: { page: page, perPage: perPage },
            filter: filterValues,
            sort: currentSort
        }).then(res => {
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = generateTextFromOrderRecords(res.data);
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            notify('copied to clipboard');
        })
    }

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
            />
            {/* Add your custom actions */}
            <Button
                onClick={handleCopyToClipboard}
                label="Copy to clipboard"
            >
                <FileCopyIcon />
            </Button>
        </TopToolbar>
    );
};

/**
 * row styling function
 * @param {*} record
 * @param {*} index
 */
const orderRowStyle = (record, index) => {
    if (record.orderStatus === Config.STATUS_ACTIVE) {
        return ({
            backgroundColor: '#D0E9C6',
        });
    } else if (record.orderStatus === Config.STATUS_PAYMENT_FAILED) {
        return ({
            backgroundColor: '#F2DEDE',
        });
    } else {
        return ({
            backgroundColor: 'white',
        });
    }
}

/**
 * Pagination values component
 * @param {*} props
 */
const OrdersPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const exporter = (orders, props) => {
    const ordersForExport = orders.map(order => {
        var orderToExport = {};
        orderToExport.Order_number = '#' + order.id;
        if (typeof props.permissions !== 'undefined' && props.permissions !== null && props.permissions.includes(Config.SUPER_ADMIN_ROLE) && order.restaurantName) {
            orderToExport.Restaurant = order.restaurantName;
        }
        orderToExport.Creation_Date = parseDate(order.orderCreationDate);
        orderToExport.Type = Config.DELIVERY_STATUS.get(order.orderDeliveryStatus);
        orderToExport.Time = order.orderDeliverytime.slice(0, -3);
        orderToExport.Price = order.orderPrice.toFixed(2);
        if (typeof props.permissions !== 'undefined' && props.permissions !== null && props.permissions.includes(Config.SUPER_ADMIN_ROLE) && order.orderFee) {
            orderToExport.Fee = order.orderFee.toFixed(2);
        }
        orderToExport.Status = Config.ORDER_STATUS.get(order.orderStatus);
        return orderToExport;
    });
    var headers = [];
    if (typeof props.permissions !== 'undefined' && props.permissions !== null && props.permissions.includes(Config.SUPER_ADMIN_ROLE)) {
        headers = ['Order_number', 'Restaurant', 'Creation_Date', 'Type', 'Time', 'Price', 'Fee', 'Status'];
    } else {
        headers = ['Order_number', 'Creation_Date', 'Type', 'Time', 'Price', 'Status'];
    }
    jsonExport(ordersForExport, {
        headers: headers,
    }, (err, csv) => {
        downloadCSV(csv, 'Orders'); // download as 'Orders.csv` file
    });
};

/**
 * filer field
 * @param {*} props
 */
const OrderFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

/**
 * function used for create the one minute timeout for refresh.
 */
function useRecursiveTimeout(callback, delay) {
    const savedCallback = useRef(callback)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        let id
        function tick() {
            const ret = savedCallback.current()

            if (ret instanceof Promise) {
                ret.then(() => {
                    if (delay !== null) {
                        id = setTimeout(tick, delay)
                    }
                })
            } else {
                if (delay !== null) {
                    id = setTimeout(tick, delay)
                }
            }
        }
        if (delay !== null) {
            id = setTimeout(tick, delay)
            return () => id && clearTimeout(id)
        }
    }, [delay])
}

/**
 * Orders list class
 * @param {*} props Props that contain attributes related to Orders
 */
export const Orders = (props) => {
    const refresh = useRefresh();
    useRecursiveTimeout(() => refresh(), 60000);

    return (
        <span>
            <List {...props} bulkActionButtons={false} actions={<ListActions />} pagination={<OrdersPagination />} exporter={orders => exporter(orders, props)} filters={<OrderFilter />}>
                <Datagrid rowStyle={orderRowStyle}>
                    <OrderNumberField source="id" label="Order number" />
                    {typeof props.permissions === 'undefined' ? null : props.permissions.includes(Config.SUPER_ADMIN_ROLE) ? <TextField source="restaurantName" label="Restaurant" /> : null}
                    <MyDateField source="orderCreationDate" label="Creation Date" showTime />
                    <DeliveryStatusField source="orderDeliveryStatus" label="Type" />
                    <TimeField source="orderDeliverytime" label="Time" />
                    <CurrencyField source="totalOrderPrice" label="Price" />
                    {typeof props.permissions === 'undefined' ? null : props.permissions.includes(Config.SUPER_ADMIN_ROLE) ? <FeeField source="orderFee" label="Fee" /> : null}
                    {typeof props.permissions === 'undefined' ? null : props.permissions.includes(Config.ADMIN_ROLE) ? <OrderStatusField source="orderStatus" label="Status" /> : null}
                    {typeof props.permissions === 'undefined' ? null : props.permissions.includes(Config.SUPER_ADMIN_ROLE) ? <SimpleOrderStatusField source="orderStatus" label="Status" /> : null}
                    <ShowButton />
                </Datagrid>
            </List>
        </span>
    )
}
