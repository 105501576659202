import { Layout } from 'react-admin';
import * as React from 'react';

import Menu from './Menu';
import CustomAppBar from "./CustomAppBar";

/**
 * Menu layout.
 * @param props properties of menu
 * @returns {JSX.Element}
 * @constructor
 */
const MenuLayout = (props) => <Layout {...props} menu={Menu} appBar={CustomAppBar}/>;

export default MenuLayout;