import * as React from "react";
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';
import './../App.css';

/**
 * Discount status field with colored background
 * @param record the discount.
 * @returns {*} component.
 * @constructor
 */
const StatusField = ({ record = {} }) => {
    let bgColor = 'orange';
    if (record.status === 'EXPIRED') {
        bgColor = 'red';
    } else if (record.status === 'ACTIVE') {
        bgColor = 'green';
    }
    return (
        <span style={{ whiteSpace: 'nowrap', backgroundColor: bgColor, color: 'white', fontSize: 12, borderRadius: 5, padding: '5px 10px' }}>
            {record.status}
        </span>
    );
};

/**
 * Discount code field
 * @param record the discount.
 * @returns {*} component.
 */
const CodeField = ({ record = {} }) => {
    return <span>
        <div style={{ fontSize: '15px' }}>{record.code}</div>
        <div style={{ fontSize: '12px' }}>{record.value}{record.type === "PERCENTAGE" ? " %" : " €"}{" off entire order;"}</div>
    </span>
}

/**
 * Discounts list class
 * @param {*} props Props that contain attributes related to discounts
 */
export const Discounts = (props) => (
    <span>
        <List {...props} bulkActionButtons={false} exporter={false} title="Discounts">
            <Datagrid>
                <CodeField sortable={false} label="Code" />
                <StatusField source="status" sortable={false} />
                <TextField source="used" sortable={false} />
                <TextField label="Start" source="validFrom" sortable={false} />
                <TextField label="End" source="validTo" sortable={false} />
                <EditButton />
                <DeleteButton confirmTitle={'Delete discount'}
                              confirmContent={'Are you sure you want to delete this discount?'}
                              undoable={false} />
            </Datagrid>
        </List>
    </span>
);
