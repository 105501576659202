import * as React from "react";
import { useConfirm } from 'material-ui-confirm';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/HighlightOff';
import { useHistory } from "react-router-dom";


/**
 * Delete button with confirmation dialog object
 * @param {*} props Props that contain id of restaurant to delete
 */
export const CloseConfirmationButton = (props) => {

    const {destination, message, withIcon = false, classes = null, style = {} } = props
    const confirm = useConfirm();
    const history = useHistory();

    const handleClick = () => {
        confirm({ description: message })
          .then(() => { history.push(destination) })
    };

    return (
        <React.Fragment>
            { withIcon ?
                <IconButton aria-label="close" className={classes != null ? classes.closeButton : ''} style={style} onClick={handleClick}>
                    <CloseIcon />
                </IconButton>
                :
                <Button variant="outlined"
                        onClick={() =>  handleClick()}
                        style={{ textTransform: 'none', width: '100px' }}>
                    Close
                </Button>
            }
        </React.Fragment>
    )
}