import * as React from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography, TextField, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import DataManager from './../util/DataManager'
import { withRouter } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { TabbedForm, FormTab } from 'react-admin';
import {CloseConfirmationButton} from "../components/CloseConfirmationButton"
import { showNotification as showNotificationAction } from 'react-admin';
import { connect } from "react-redux";
import SaveIcon from "@material-ui/icons/Save";

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sameLine: {
        padding: '10px'
    },
    button: {
        marginBottom: '5px',
        marginTop: '1px'
    },
    container: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: theme.spacing(15),
        marginRight: theme.spacing(15),
        borderRadius: '15px 50px',
        border: '1px solid #1E90FF'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    selectedButton: {
        backgroundColor: '#5BB85D',
        borderRadius: 5,
        color: 'white',
        '&:hover': {
            backgroundColor: "#4f9950",
        },
        border: '1px solid #4FAE50',
    },
    notSelectedButton: {
        backgroundColor: 'white',
        borderRadius: 5,
        color: 'black',
        '&:hover': {
            backgroundColor: "#c6c6c6",
        },
        border: '1px solid #D9D9D9',
    }
});

/**
 * Custom Dialog Title Component
 * @param {*} props Props that contains styles and state variables
 */
export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6"> {children}</Typography>
            {onClose ? (
                <CloseConfirmationButton message="If you close your changes will be lost" destination='/menu' withIcon={true} classes={classes}/>
            ) : null}
        </MuiDialogTitle>
    );
});

/**
 * Create Restaurant menu modal
 */
class CreateRestaurantMenu extends React.Component {

    /**
     * Constructor
     * @param {*} props
     */
    constructor(props) {
        super(props);
        let decodedJwt = jwt_decode(localStorage.getItem('token'));
        let restaurantId = JSON.stringify(decodedJwt.restaurantId);
        this.state = {
            restaurantId: restaurantId,
            menuName: "",
            menuNameIt: "",
            openDialog: false,
            dishes: [],
            selectedDishes: [],
            missingNameError: false,
        }
    }

    /**
     * Component did mount method
     */
    componentDidMount = async () => {
        let decodedJwt = jwt_decode(localStorage.getItem('token'));
        let restaurantId = JSON.stringify(decodedJwt.restaurantId);
        var response = await DataManager.getWithoutCallback(`dish/restaurant/${restaurantId}`);
        this.setState({ dishes: response.data });
        this.setState({ openDialog: true })
    }

    /**
     * Create method
     */
    create = async () => {
        if (this.state.menuName.length > 45 || this.state.menuNameIt.length > 45) {
            this.props.showNotification('Menu name cannot be longer than 45 characters.', 'error');
            return;
        }
        if (this.state.menuName.trim().length === 0) {
            this.setState({missingNameError: true});
            return;
        }
        var creationUser = "creationUser"; //TODO CHANGE WITH PROPS SESSION CREATION USER
        let decodedJwt = jwt_decode(localStorage.getItem('token'));
        let restaurantId = JSON.stringify(decodedJwt.restaurantId);
        var langId = 'en';
        var localization = {};
        if (this.state.menuNameIt.trim().length === 0) {
            localization = {
                en: {
                    menuName: this.state.menuName
                }
            }
        } else {
            localization = {
                it: {
                    menuName: this.state.menuNameIt
                },
                en: {
                    menuName: this.state.menuName
                }
            }
        }
        let data = {
            menuName: this.state.menuName,
            menuNameIt: this.state.menuNameIt,
            localizations: localization,
            menuStatus: true,
            selectedDishes: this.state.selectedDishes,
            menuCreationUser: creationUser,
            restaurantId: restaurantId,
            langId: langId,
        };

        await DataManager.createPost(
            'menu',
            data,
            () => {
                this.setState({ openDialog: false });
                this.props.showNotification('Element created.');
                this.props.history.push("/menu");
            }
        );
    };

    /**
     * Renders the form.
     * @returns {*}
     */
    renderForm = (locale) => {
        const { classes } = this.props;
        return(
            <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ paddingBottom: 5 }}>
                        <Typography variant="subtitle1">
                            <b>Menu name</b>
                        </Typography>
                    </div>
                    <div>
                        {locale === 'en' ?
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={this.state.menuName}
                                onChange={(event) => {
                                    this.setState({ menuName: event.target.value})
                                    this.setState({missingNameError: event.target.value.length === 0});
                                }}
                                error={this.state.missingNameError ||this.state.menuName.length > 45}
                                helperText={this.state.missingNameError ? 'Fill in this field' : ''}
                            />
                         :
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={this.state.menuNameIt}
                                onChange={(event) => this.setState({ menuNameIt: event.target.value })}
                                error={this.state.menuNameIt.length > 45}
                            />
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
                    <Typography variant="subtitle1" style={{ paddingBottom: 28 }}>
                        <b>Dishes</b>
                    </Typography>
                    <Grid container item md={12}>
                        {
                            this.state.dishes.map((dish, i) => {

                                var width = 4;
                                if (i === this.state.dishes.length - 1 && i % 3 === 0) {
                                    width = 12;
                                }
                                if (i === this.state.dishes.length - 1 && i % 3 === 1) {
                                    width = 6
                                }
                                if (i === this.state.dishes.length - 2 && i % 3 === 0) {
                                    width = 6;
                                }
                                return (
                                    this.state.selectedDishes.includes(dish.id) ?
                                        <Grid key={dish} item md={width}>
                                            <Button className={classes.selectedButton} size="large" fullWidth={true}
                                                    onClick={
                                                        () => {
                                                            this.setState({
                                                                selectedDishes: this.state.selectedDishes.filter((id) => {
                                                                    return id !== dish.id;
                                                                })
                                                            });
                                                        }
                                                    }
                                            >
                                                {dish.dishName}
                                            </Button>
                                        </Grid>
                                        :
                                        <Grid key={dish} item md={width}>
                                            <Button className={classes.notSelectedButton} size="large" fullWidth={true}
                                                    onClick={
                                                        () => {
                                                            this.setState({ selectedDishes: [...this.state.selectedDishes, dish.id] });
                                                        }
                                                    }
                                            >
                                                {dish.dishName}
                                            </Button>
                                        </Grid>
                                )
                            })
                        }
                    </Grid>
                </div>
            </div>
        );
    };

    /**
     * Render method
     */
    render() {
        return (
            <span>
                <Dialog open={this.state.openDialog} maxWidth="md" fullWidth={true}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.history.push("/menu")}>
                        Create menu
                    </DialogTitle>
                    <DialogContent dividers>
                       <TabbedForm toolbar={null}>
                            <FormTab label={'English'}>
                                <div style={{width: '100%'}}>{this.renderForm('en')}</div>
                            </FormTab>
                           <FormTab label={'Italian'}>
                                <div style={{width: '100%'}}>{this.renderForm('it')}</div>
                            </FormTab>
                       </TabbedForm>
                    </DialogContent>
                    <DialogActions>
                        <CloseConfirmationButton message="If you close your changes will be lost" destination='/menu' />
                        <Button variant="contained"
                                color="secondary"
                                style={{backgroundColor: '#3f51b5'}}
                                startIcon={<SaveIcon />}
                                onClick={this.create} >
                            SAVE
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        );
    }

};

export default withRouter(connect(null, {showNotification: showNotificationAction})(withStyles(styles)(CreateRestaurantMenu)));

