import axios from 'axios';

/**
 * This is a class that allow to upload file and get url
 * 
 */
export default class FileManager {
    static UploadImage(resource, fileData, callbackFun) {
      var formData = new FormData();
      formData.append("file", fileData);
      axios.post(process.env.REACT_APP_API_URL+'/'+resource, formData, {
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        callbackFun(res.data);
      })
    }
}
