import { Create, SimpleForm, TextInput, SaveButton, Toolbar, PasswordInput, SelectInput, required, email, minLength } from 'react-admin'
import React, { useState } from 'react'
import { IconButton, Dialog, DialogContent, Button, Typography, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/HighlightOff';
import { CloseConfirmationButton } from "../components/CloseConfirmationButton"
import { useNotify } from 'react-admin';



/**
 * User create form toolbar component
 * @param {*} props Props for toolbar Save Button
 */
const UserCreateToolbar = props => {

  const notify = useNotify();

  return (
    <Toolbar {...props}  >
      <div style={{flex: '0 0 auto', display: 'flex', padding: '8px', width: '100%', justifyContent: 'flex-end'}}
           {...props}>
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/users' />
        <SaveButton
          {...props}
          label="SAVE"
          style={{ marginLeft: '8px' }}
          redirect={'/users'}
          onFailure={() => notify('Email is already taken', 'warning')}
        />
      </div>
    </Toolbar>
  )
};

/**
 * Custom style classes
 * @param {*} theme Theme used
 */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/**
* Custom Dialog Title Component
*/
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textAlign: 'left', fontWeight: "fontWeightBold" }}>{children}</Typography>
      {onClose ? (
        <CloseConfirmationButton message="If you close your changes will be lost" destination='/users' withIcon={true} classes={classes} />
      ) : null}
    </MuiDialogTitle>
  );
});

/**
 * User create component
 * @param {*} props Props to create user component
 */
export const UserCreate = props => {
  const [open] = useState(true);

  /**
   * password matching validation
   * @param {*} value
   * @param {*} allValues
   */
  const confirmPassValidation = (value, allValues) => {
    if (value !== allValues.password) {
      return 'Does not match'
    }
    return null;
  };

  /**
   * validations
   */
  const emailValidation = [required(), email('The email must be a valid email address')];
  const confirmPasswordValidation = [required(), minLength(6, 'The password must be at least 6 characters'), confirmPassValidation];
  const passwordValidation = [required(), minLength(6, 'The password must be at least 6 characters')];

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => props.history.push(`/users`)}>
          Create new user
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ width: '100%' }}>
            <Create {...props}>
              <SimpleForm toolbar={<UserCreateToolbar {...props} />}>
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '45%' }}>
                      <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                        <Box fontWeight="fontWeightBold" m={1}>
                          Name
                        </Box>
                      </Typography>
                      <TextInput source="name" style={{ width: '100%' }} validate={required()} />
                    </div>
                    <div style={{ width: '45%', paddingLeft: '10%' }}>
                      <Typography style={{ textAlign: 'left' }}>
                        <Box fontWeight="fontWeightBold" m={1}>
                          Last name
                        </Box>
                      </Typography>
                      <TextInput source="last_name" style={{ width: '100%' }} validate={required()} />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '100%' }}>
                      <Typography style={{ textAlign: 'left' }}>
                        <Box fontWeight="fontWeightBold" m={1}>
                          Email
                        </Box>
                      </Typography>
                      <TextInput source="email" style={{ width: '100%' }} validate={emailValidation} />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '100%' }}>
                      <Typography style={{ textAlign: 'left' }}>
                        <Box fontWeight="fontWeightBold" m={1}>
                          Role
                        </Box>
                      </Typography>
                      <SelectInput source="role" choices={[
                        { id: 1, name: 'Admin' },
                        { id: 2, name: 'Super Admin' }
                      ]} style={{ width: '100%' }} validate={required()} />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '45%' }}>
                      <Typography style={{ textAlign: 'left' }}>
                        <Box fontWeight="fontWeightBold" m={1}>
                          Password
                        </Box>
                      </Typography>
                      <PasswordInput source="password" style={{ width: '100%' }} validate={passwordValidation} />
                    </div>
                    <div style={{ width: '45%', paddingLeft: '10%' }}>
                      <Typography style={{ textAlign: 'left' }}>
                        <Box fontWeight="fontWeightBold" m={1}>
                          Confirm password
                        </Box>
                      </Typography>
                      <PasswordInput source="confirm_password" style={{ width: '100%' }} validate={confirmPasswordValidation} />
                    </div>
                  </div>
                </div>
              </SimpleForm>
            </Create>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

